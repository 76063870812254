import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from "moment";
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { Menu } from 'antd';
import { StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import { selectTransfarOrder, getAllTransfarOrder } from "./slice";

import { AddTransferOrder } from "./AddTransferOrder";
import { useSelector, useDispatch } from "react-redux";

const columns = [
  // {
  //   title: "S/N",
  //   dataIndex: "sn",
  //   key: "sn",
  // },
  {
    title: "Transfer Date",
    dataIndex: "transferDate",
    key: "transferDate",
    render: (data) => {
      return moment(data).format("dddd, MMMM Do, YYYY");
    },
  },
  {
    title: "transferred Quantity",
    dataIndex: "transferredQuantity",
    key: "transferredQuantity",
  },
  {
    title: "Ref No",
    dataIndex: "referenceNumber",
    key: "referenceNumber",
  },
  {
    title: "Source Warehouse",
    dataIndex: "sourceWarehouse",
    key: "sourceWarehouse",
  },
  {
    title: "Destination Warehouse",
    dataIndex: "destinationWarehouseUuid",
    key: "destinationWarehouseUuid",
  },
];

const data = [
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
];

export const TransferOrder = (props) => {
  const dispatcher = useDispatch();

  //useSelector
  const { transfarOrderList, paginationProps } = useSelector(selectTransfarOrder);
  console.log({transfarOrderList, paginationProps}, 'transfarorder')

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { url, path } = useRouteMatch();
  const history = useHistory();


   useEffect(() => {
     (async () => {
       await dispatcher(getAllTransfarOrder());
     })();
    //  dispatcher(getAllTransfarOrder({ page: 0, size: 10 }));
   }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const gotoAddOrder = () => {
    history.push(`${url}/add-order`);
  };

  const handleTableRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        history.push(`${url}/item-details`);
      },
    };
  };
  return (
    <Switch>
      <Route exact path={path}>
        <ToolBar
          title="All Transfer Orders"
          showtools
          onCreate={gotoAddOrder}
          createButtonText="Create Transfer Order"
        />
        <StyledCard mt={2}>
          <TableWithPagination
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            columns={columns}
            dataSource={transfarOrderList}
            onRow={handleTableRow}
          />
        </StyledCard>
      </Route>
      <Route path={`${path}/add-order`}>
        <AddTransferOrder />
      </Route>
    </Switch>
  );
};
