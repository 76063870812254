import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from 'styled-components';
import { selectWarehouse } from "../../Inventory/Warehouse/slice";
import { selectItems } from "../../Inventory/Items/slice";
import { getInput } from "../../Inventory/Items/AddItem";
import { notificationAlert } from "../../utils/notificationAlert";
import { createTransfarOrder, getAllTransfarOrder } from "./slice";
import { selectDashboard } from "../../Dashboard/slice";
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { Menu } from 'antd';
import { StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import {
  StyledBoldText,
  StyledInputContainer,
} from '../../globalComponents/styles';
import { Box,Grid } from '@material-ui/core';
import { Input, Select, Col, Row, Form } from "antd";
import {
  PrimaryButton,
  TransaparentButton,
} from '../../globalComponents/Buttons';
import { FiTrash2 } from 'react-icons/fi';




const { Option } = Select;

const transferOrderInput = [
  {
    name: "referenceNumber",
    label: "Reference Number",
    isRequired: false,
    type: "text",
  },
  {
    name: "sourceWarehouseId",
    label: "Source WarehouseId",
    isRequired: false,
    type: "search",
  },
  {
    name: "destinationWarehouseId",
    label: "Destination Warehouse",
    isRequired: false,
    type: "search",
  },
  {
    name: "reason",
    label: "Reason",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
];

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const data = [
  {
    item: [
      { name: "Rice", sku: 20 },
      { name: "Beans", sku: 15 },
    ],
    itemSku: "",
    transferQuantity: 0,
  },
];

export const AddTransferOrder = (props) => {
  //useselector
  const { warehouseList } = useSelector(selectWarehouse);
  const { itemList } = useSelector(selectItems);
  const  profile  = useSelector(selectDashboard);
  const userProfile2 = profile.userProfile.workShift;





  console.log(
    { warehouseList, itemList, profile, userProfile2 },
    "selectedItem"
  );

  //useState
  const [itemsState, setItemsState] = useState(data);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [selectValues, setSelectValues] = useState({});
  const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false);

  //useForm
  const [basicInfoForm] = Form.useForm();
  const history = useHistory();
  const dispatcher = useDispatch();


    useEffect(() => {
      dispatcher(getAllTransfarOrder({ page: 0, size: 10 }));
    }, []);

//get new Items
  useEffect(()=>{
    const newItemsState = [...itemsState].map(itm=>{
       itm.item = itemList;
      return itm;
    })
    setItemsState(newItemsState);
  },[itemList])

  const getSelectedItem = (currentItemState) => {
    return currentItemState.find((item, index) => index === activeRowIndex);
  };

  const replaceItem = (currentItemState, selecedItem) => {
    currentItemState.splice(activeRowIndex, 1, selecedItem);
    setItemsState(currentItemState);
  };

  //handle select change
 const handleSelectChange = (selectName) => (value) => {
   const prevSelectValues = { ...selectValues };
   prevSelectValues[selectName] = value;
   setSelectValues(prevSelectValues);
 };

  //handleItemSelect
   const handleItemSelect = (itemId) => {
     const currentItemState = [...itemsState];
     const selectedItemObj = getSelectedItem(currentItemState);
     const selecteditem = selectedItemObj.item.find((itm) => itm.id === itemId);
     selectedItemObj.itemId = itemId;
     selectedItemObj.itemName = selecteditem.itemName;
     selectedItemObj.itemSku = selecteditem.sku;

     replaceItem(currentItemState, selectedItemObj);
   };

   
   //*handleQuantitytransfar
   const handleQuantityTransfar = (e) => {
     const currentItemState = [...itemsState];
     const selectedItemObj = getSelectedItem(currentItemState);
     const qty = e.target.value;
     selectedItemObj.transferQuantity = qty;
    //  selectedItemObj.itemSku = qty
    //  selectedItemObj.amount =
    //    parseInt(qty || 0) * parseInt(selectedItemObj.price);
     replaceItem(currentItemState, selectedItemObj);
   };


  const handleDeleteRow = () => {
    const currentItemState = [...itemsState];
    currentItemState.splice(activeRowIndex, 1);
    setItemsState(currentItemState);
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      render: (data) => {
        const optionList = data.map((item, index) => {
          return (
            <Option key={index} value={item.id}>
              {item.itemName}
            </Option>
          );
        });
        return (
          <StyledInputContainer>
            <Select
              onChange={handleItemSelect} 
              placeholder="Select Item"
              style={{ width: '100%' }}
              bordered={false}>
              {optionList}
            </Select>
          </StyledInputContainer>
        );
      },
    },
    {
      title: 'Item Sku',
      dataIndex: 'itemSku',
      key: 'itemSku',
      render: (data) => {
        return (
          <StyledInputContainer>
            <Input
              // onChange={handleSelectChange}
              bordered={false}
              value={data}
              disabled
            />
          </StyledInputContainer>
        );
      },
    },

    {
      title: 'Transfer Quantity',
      dataIndex: 'transferQuantity',
      key: 'transferQuantity',
      render: (data) => {
        return (
          <StyledInputContainer>
            <Input
              type="number"
              onChange={handleQuantityTransfar}
              value={data}
              bordered={false}
              placeholder="Eg +10. -10"
            />
          </StyledInputContainer>
        );
      },
    },

    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (data) => {
        return (
          itemsState.length > 1 && (
            <FiTrash2
              onClick={handleDeleteRow}
              style={{ fontSize: '1.4vw', marginLeft: '20px', color: 'red' }}
            />
          )
        );
      },
    },
  ];

  const handleTableRow = (data, index) => {
    return {
      onClick: (e) => {
        setActiveRowIndex(index);
      },
    };
  };

  const AddAdjustableRow = () => {
    const row = {
      item: itemList,
      itemSKU: "",
      transferQuantity: 0,
    };

    setItemsState((prevState) => [...prevState, row]);
  };

  //get source warehouse list
  const warehouseOptionList = warehouseList.map((item, index) =>{
    return (
      <Option value={item.id} key={index}>
        {item.name}
      </Option>
    );
  })
  const warehouseOptionList2 = warehouseList.map((item, index) => {
    return (
      <Option value={item.id} key={index}>
        {item.name}
      </Option>
    );
  });
  

  const getTransfarOrderInput = [...transferOrderInput].map((item)=>{
    if (item.name === "sourceWarehouseId") {
      return (
        <Col sm={12}>
          {getInput(
            item,
            handleSelectChange,
            warehouseOptionList
          )}
        </Col>
      );
    }
     if (item.name === "destinationWarehouseId") {
       return (
         <Col sm={12}>
           {getInput(item, handleSelectChange, warehouseOptionList2)}
         </Col>
       );
     }
    
    return <Col sm={12}>{getInput(item, handleSelectChange)}</Col>;
  })



  // handle Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    basicInfoForm.submit();
  };

  // on finish
  const onFinish = async (values) => {
    try {
      setCreateInvoiceLoading(true);
      values.sourceWarehouseId = selectValues.sourceWarehouseId;
      values.destinationWarehouseId = selectValues.destinationWarehouseId;
      values.orgId = userProfile2.orgId;

      const items = itemsState.map((item) => {
        return {
          itemId: item.itemId,
          itemSku: item.itemSku,
          transferQuantity: item.transferQuantity,
        };
      });

      values.items = items;

      console.log(values, "values");
       await dispatcher(createTransfarOrder({data: values}));
        await dispatcher(getAllTransfarOrder({}));
        notificationAlert(
          "success",
          "transfar order was successfully created"
        );
        setCreateInvoiceLoading(false);
        // history.push("/invoice");
    } 
    catch (error) {
      setCreateInvoiceLoading(false);
      notificationAlert(
        "error",
        "Failed",
        error?.response?.data?.message || "Please try again later"
      );
    }
  };

  return (
    <>
      <ToolBar title="New Transfer Order" />
      <StyledCard mt={2} py={3} px={4} display="flex">
        <Grid item xs={12}>
          {/*  <Box px={3}>
            <StyledBoldText variant="subtitle1">Warehouse</StyledBoldText>
          </Box> */}
          <Box px={3} mt={2}>
            <Form form={basicInfoForm} onFinish={onFinish} {...layout}>
              <Row gutter={16}>{getTransfarOrderInput}</Row>
            </Form>
          </Box>
        </Grid>
      </StyledCard>
      <StyledCard mt={2}>
        <TableWithPagination
          dataSource={itemsState}
          onRow={handleTableRow}
          columns={columns}
          hidePagination
        />
        <Box p={4} display="flex" justifyContent="space-between">
          <PrimaryButton onClick={AddAdjustableRow} text="Add Row" />
          <Box mt={6} pb={5} display="flex" justifyContent="flex-start">
            <Box mr={2}>
              <TransaparentButton text="Cancel" />
            </Box>
            {/*  <Box mr={2}>
              <PrimaryButton text="Save to Draft" />
            </Box> */}
            <PrimaryButton
              loading={createInvoiceLoading}
              onClick={handleFormSubmit}
              text="Save &amp; Submit"
            />
          </Box>
        </Box>
      </StyledCard>
    </>
  );
};
