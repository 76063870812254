import { createSlice } from "@reduxjs/toolkit";
import Services from "../../../services";

const itemGroupSlice = createSlice({
  name: "itemGroupSlice",
  initialState: {
    itemGroupList: [],
    paginationProps: { page: 0, totalPages: 0, totalItemGroup: 0 },
    singleItemGroup: {},
  },
  reducers: {
    addItemGroup: (state, { payload }) => {
      state.itemGroupList = payload;
    },
    addPaginationProps: (state, { payload }) => {
      state.paginationProps = payload;
    },
    addSingleItemGroup: (state, { payload }) => {
      state.singleItemGroup = payload;
    },
  },
});

export const {
  addItemGroup,
  addPaginationProps,
  addSingleItemGroup,
} = itemGroupSlice.actions;

export const createItemGroup = (payload) => (dispatcher) => {
  return Services.createItemGroup(payload)
    .then((response) => {      
      return Promise.resolve(response.data.data);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const fetchAllItemGroups = (payload) => (dispatcher) => {
  return Services.fetchAllItemGroups(payload)
    .then((response) => {
       dispatcher(
         addItemGroup(response.data.data.filter((item) => !item.delFlag))
       );
      return Promise.resolve(response.data.data);
    })
    .catch((error) => {
      if (error.response) {
        
        return Promise.reject(error.response.data);
      }
    });
};

export const fetchItemGroupsByOrg = (payload) => (dispatcher) => {
  return Services.fetchItemGroupsByOrgId(payload)
    .then((response) => {
      const {currentPage: page, totalPages, totalItemGroup, item_group} = response.data;
      dispatcher(addPaginationProps({page, totalPages, totalItemGroup}));
      dispatcher(addItemGroup(item_group.filter((item) => !item.delFlag)));
      return Promise.resolve(item_group.filter(item => !item.delFlag));
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const fetchSingleItemGroup = (payload) => (dispatcher) => {
  return Services.fetchSingleItemGroup(payload)
    .then((response) => {
      console.log(response.data, 'singleItemGroup222')
      dispatcher(addSingleItemGroup(response.data));
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const selectItemGroups = (state) => state.itemGroupReducer;

export default itemGroupSlice.reducer;
