import styled from 'styled-components';
import { Box, Button, Typography } from '@material-ui/core';
import { Table, Form } from 'antd';
import { getCenter } from '../utils/getCenter';
import { desktop } from '../globalAssets/fontSize';

export const StyledImageDrop = styled(Box)`
  border: 2px ${({isImage, theme}) => 
    isImage? theme.subtleBlack :theme.primaryColor} ${props => props.isImage? 'solid': 'dashed'};
  width: 100%;
  height: 40vh;
  background-color: ${props => props.theme.faintBlue};
  ${getCenter({ flexDirection: 'column' })};
 
    overflow: hidden;
  & > :first-child {
    font-size: ${desktop.large};
    color: ${(props) => props.theme.subtleBlack};
  }
`;

export const StyledImagePreview = styled(Box)`
  width: 100%;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledMutedText = styled(Typography)`
  color: ${(props) => props.theme.subtleBlack};
`;

export const StyledBottomImage = styled(Box)`
  cursor: pointer;
  margin-left: 8px;
  width: 25%;
  border: ${(props) =>
    props.isActive ? `1px solid ${props.theme.primaryColor}` : 'none'};
  overflow: hidden;
  height: 10vmin;

  &:first-child {
    margin-left: 0px;
  }

  & img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
`;

export const StyledTable = styled(Table)`

    & .ant-table-row{
        color: #fff;
        background-color: ${props => props.theme.foreground}; 
        color: ${props => props.theme.boldText};
       
        &:nth-child(even){
            background-color: ${props => props.theme.faintBlue};
            
        } 
    }

    & .ant-table-tbody > tr > td {
        border-bottom: none; 
    }

    & .ant-table-tbody > tr.ant-table-row:hover > td{
        background-color: ${props => props.theme.highlight}; 
        color: ${props => props.theme.boldText};
    }

    & .ant-table-thead > tr > td {
        border-bottom: none; 

    }

    & .ant-table-thead > tr > th{
        background-color: ${props => props.theme.foreground}; 
        color: ${props => props.theme.boldText};
    }

`
