import React from 'react';
import { ModalComponent } from './Modal';
import { Paper, Box } from '@material-ui/core';
import { StyledNormalText, StyledBoldText } from './styles';
import { TransaparentButton, CancelButton} from './Buttons';
 
export function DeleteModal(props) {
    return (
        <ModalComponent
            open={props.open}
            onClose={props.onClose}
        >
            <Paper style={{ minWidth: '30vw', padding: '16px' }}>
                <Box>
                    <StyledBoldText align='center' variant='subtitle1'>{props.title}</StyledBoldText>
                </Box>
                <Box mt={3}>
                    <StyledNormalText variant='body2' align='center'>Do you want to delete <strong>{props.itemName}</strong>?</StyledNormalText>
                </Box>
                <Box p={3} display='flex' justifyContent='flex-end'>
                    <Box mr={2}>
                        <TransaparentButton onClick={props.onClose} text='Cancel' />
                    </Box>
                    <CancelButton loading={props.loading} onClick={props.onDelete} type='submit' text='Delete' />
                </Box>
            </Paper>
        </ModalComponent>
    )
}