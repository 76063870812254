import React from 'react'
import {
    Box,
    Avatar,
    Typography,
    Divider,
    Popper,
    Paper,
    MenuList,
    Grow,
    ClickAwayListener,
    MenuItem
  } from '@material-ui/core';

export const DropDown = React.forwardRef((props, ref) => {

    const itemList = props.items.map((item, index) => {
        return <MenuItem key={index} onClick={(event) => props.handleClose(event, item)}>{item}</MenuItem>
    })
    return (
      <Popper
        open={props.open}
        anchorEl={ref.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: '1000' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={props.handleClose}>
                <MenuList autoFocusItem={props.open} id="menu-list-grow" onKeyDown={props.handleListKeyDown}>
                    {itemList}
                  </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  });