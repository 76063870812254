import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { ToolBar } from "../../globalComponents/ToolBar";
import {
  StyledBoldText,
  StyledCard,
  StyledNormalText,
  StyledModal,
} from "../../globalComponents/styles";
import { useRouteMatch, Route, Switch, useHistory } from "react-router";
import { FiMoreVertical } from "react-icons/fi";
import { ModalComponent } from "../../globalComponents/Modal";
import { Box, Paper } from "@material-ui/core";
import { getInput } from "../Items/AddItem";
import { Form, Popover, Space } from "antd";
import { Divider } from "@material-ui/core";
import {
  PrimaryButton,
  TransaparentButton,
  CancelButton,
} from "../../globalComponents/Buttons";
import { useSelector, useDispatch } from "react-redux";
import { createItemGroup, fetchAllItemGroups, fetchSingleItemGroup,fetchItemGroupsByOrg, selectItemGroups } from "./slice";
import { Feedback } from "../../globalComponents/Feedback";
import Services from "../../../services";
import { loadProgressBar } from "axios-progress-bar";
import { themes } from "../../globalAssets/theme";
import { notificationAlert } from "../../utils/notificationAlert";
import { selectDashboard } from '../../Dashboard/slice';
import { ItemGroupDetails } from './ItemGroupDetails';


const addGroupInput = [
  {
    name: "groupName",
    label: "Group Name",
    isRequired: true,
    type: "text",
  },
  {
    name: "groupDescription",
    label: "Description",
    isRequired: true,
    type: "textarea",
  },
];

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const ItemGroups = (props) => {
  //useSelector
  const { userProfile } = useSelector(selectDashboard)
  const { itemGroupList, paginationProps } = useSelector(selectItemGroups);
  console.log(itemGroupList, "itemGroupList");
  

  //useForm & useState
  const [createGroupForm] = Form.useForm();
  const [editGroupForm] = Form.useForm();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSubmitDisabled, setSubmitButton] = useState(false);
  const [addGroupModalProps, setAddGroupModalProps] = useState({
    isVisible: false,
  });
  const [feedbackProps, setFeedbackProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [deleteModalProps, setDeleteModalProps] = useState({
    visible: false,
    name: "",
    id: null,
    loading: false,
  });
  const [editModalProps, setEditModalProps] = useState({
    isVisible: false,
    selectedItemInput: [],
    loading: false,
    initialValues:{}
  });
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatcher = useDispatch();

  useEffect(() => {
    editGroupForm.resetFields();
  }, [editModalProps.isVisible]);


  const handleViewItemGroup = (id) => async () => {
    history.push(`${url}/${id}`)
    //  try {
    //   const response = await dispatcher(fetchSingleItemGroup({id}));
    //   console.log(response);
    // } catch (error) {
    //   console.log(error);
    // } 
  }

  const columns = [
    {
      title: "ID ",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "groupName",
      key: "groupName",
    },
    {
      title: "Description",
      dataIndex: "groupDescription",
      key: "groupDescription",
      width: "40%",
    },
    /* {
      title: "Item Count",
      dataIndex: "items",
      key: "items",
      render: (data) => data.length,
    }, */
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (data, allData) => {
        const content = (
          <Space direction="vertical" size="small">
            <p 
             onClick={() => {
              const newData = addGroupInput.map((item) => {
                item.defaultValue = allData[item.name];
                return getInput(
                  item
                );
              });
              setEditModalProps((prevState) => ({
                ...prevState,
                selectedItemInput: newData,
                isVisible: true,
                initialValues: allData,
              }));
            }}
            style={{ cursor: "pointer" }}>Edit</p>
            <p
              onClick={handleViewItemGroup(allData.id)}
              style={{ cursor: "pointer" }}
            >
              View Detail
            </p>
            <p
              onClick={() =>
                setDeleteModalProps({
                  name: allData.groupName,
                  id: allData.id,
                  visible: true,
                })
              }
              style={{ color: "red", cursor: "pointer" }}
            >
              Delete
            </p>
          </Space>
        );
        return (
          <Popover content={content} trigger="click">
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

 

  const handleChangePage = (event, newPage) => {
    (async () => {
      setPage(newPage);
      await dispatcher(fetchItemGroupsByOrg({orgId: userProfile.organisation.orgId,  page: newPage, size: rowsPerPage }));
    })();
  };

  const handleChangeRowsPerPage = (event) => {
    (async () => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      await dispatcher(
        fetchItemGroupsByOrg({ orgId: userProfile.organisation.orgId, page: 0, size: parseInt(event.target.value, 10) })
      );
    })();
  };

  const groupInputs = addGroupInput.map((item) => getInput(item));
  

  const onFinish = async (values) => {
    try {
      setSubmitButton(true);
      await dispatcher(createItemGroup({ data: values }));
      await dispatcher(fetchItemGroupsByOrg({orgId: userProfile.organisation.orgId, page, size: rowsPerPage}));
      
      await dispatcher(fetchAllItemGroups({}))
      setSubmitButton(false);
      setAddGroupModalProps((prevState) => ({
        ...prevState,
        isVisible: false,
      }));
      setFeedbackProps({
        open: true,
        type: "success",
        message: `${values.groupName} was created`,
      });
      // window.location.href(`${url}/item-groups`);
      window.location.reload();
      // history.push(`${url}/item-groups`);
    } catch (error) {
      setSubmitButton(false);
      setFeedbackProps({
        open: true,
        type: "error",
        message: error.message || "Error occurred",
      });
    }
  };

 

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackProps((prevState) => ({ ...prevState, open: false }));
  };

  const handleItemGroupDelete = async () => {
    try {
      setDeleteModalProps((prevState) => ({ ...prevState, loading: true }));
      const response = await Services.deleteItemGroup({
        id: deleteModalProps.id,
      });
      await dispatcher(fetchItemGroupsByOrg({orgId: userProfile.organisation.orgId, page, size: rowsPerPage}));
      setFeedbackProps({
        type: "success",
        message: `${deleteModalProps.name} has been deleted`,
        open: true,
      });
      setDeleteModalProps((prevState) => ({
        ...prevState,
        loading: false,
        visible: false,
      }));
      window.location.reload();

    } catch (error) {
      console.log(error);
      setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
      setFeedbackProps({
        type: "error",
        message: `Please try again`,
        open: true,
      });
    }
  };


  const onEditFinish = async (values) => {
    console.log(values, 'editgruop');
    try {
      setEditModalProps((prevState) => ({ ...prevState, loading: true }));
      await Services.updateItemGroups({data: values, id: editModalProps.initialValues.id});
      await dispatcher(fetchItemGroupsByOrg({orgId: userProfile.organisation.orgId, page, size: rowsPerPage}));
      setEditModalProps((prevState) => ({
        ...prevState,
        loading: false,
        isVisible: false,
      }));
      notificationAlert(
        "success",
        "Item Group updated",
        `${editModalProps.initialValues.groupName} has been updated`
      );
    } catch (error) {
      setEditModalProps((prevState) => ({ ...prevState, loading: false }));
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }

  return (
    <Switch>
      <Route exact path={path}>
      <ToolBar
        title="All Item Groups"
        showtools
        onCreate={() =>
          setAddGroupModalProps((prevState) => ({
            ...prevState,
            isVisible: true,
          }))
        }
        createButtonText="Create Item Group"
      />
      <StyledCard mt={2}>
        <TableWithPagination
          rowsPerPage={rowsPerPage}
          page={page}
          total={paginationProps.totalPages * rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          columns={columns}
          dataSource={itemGroupList}
        />
      </StyledCard>
      <ModalScaffold
        form={createGroupForm}
        onCancel={() =>
          setAddGroupModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        title="New Item Group"
        visible={addGroupModalProps.isVisible}
        loading={isSubmitDisabled}
        onFinish={onFinish}
      >
        {groupInputs}
      </ModalScaffold>
      <ModalScaffold
        form={editGroupForm}
        onCancel={() =>
          setEditModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        title="Edit Item Group"
        visible={editModalProps.isVisible}
        loading={editModalProps.loading}
        onFinish={onEditFinish}
        initialValues={editModalProps.initialValues}
      >
        {editModalProps.selectedItemInput}
      </ModalScaffold>

      <ModalComponent
        open={deleteModalProps.visible}
        onClose={() =>
          setDeleteModalProps((prevState) => ({ ...prevState, visible: false }))
        }
      >
        <Paper style={{ minWidth: "30vw", padding: "16px" }}>
          <Box>
            <StyledBoldText align="center" variant="subtitle1">
              Delete Item Group
            </StyledBoldText>
          </Box>
          <Box mt={3}>
            <StyledNormalText variant="body2" align="center">
              Do you want to delete <strong>{deleteModalProps.name}</strong>?
            </StyledNormalText>
          </Box>
          <Box p={3} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <TransaparentButton
                onClick={() =>
                  setDeleteModalProps((prevState) => ({
                    ...prevState,
                    visible: false,
                  }))
                }
                text="Cancel"
              />
            </Box>
            <CancelButton
              loading={deleteModalProps.loading}
              onClick={handleItemGroupDelete}
              type="submit"
              text="Delete"
            />
          </Box>
        </Paper>
      </ModalComponent>
      <Feedback
        open={feedbackProps.open}
        message={feedbackProps.message}
        type={feedbackProps.type}
        onClose={handleFeedbackClose}
      />
      </Route>
      <Route path={`${path}/:id`}>
        <ItemGroupDetails/>
      </Route>
    </Switch>
  );
};

const ModalScaffold = (props) => {
    
  const onOk = () => {
    props.form.submit();
  };

  return (
    <StyledModal
      title={props.title}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={onOk}
      okText="Create"
      onCancel={props.onCancel}
    >
      <Form
        initialValues={props.initialValues}
        form={props.form}
        {...layout}
        onFinish={props.onFinish}
      >
        {props.children}
      </Form>
    </StyledModal>
  );
};
