import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { Menu } from 'antd';
import { StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import { AdjustmentDetails } from './AdjustmentDetails';
import { AdjustItem } from './AdjustItem';
import { selectAdjustments, adjustmentsList, paginationProps, fetchAllAdjustments,  } from "./slice";
import { useDispatch, useSelector } from 'react-redux';

const columns = [
  {
    title: "Date",
    dataIndex: "inventoryAdjustedDate",
    key: "inventoryAdjustedDate",
  },
  {
    title: "Warehouse Name",
    dataIndex: "warehouseName",
    key: "warehouseName",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Reference Number",
    dataIndex: "referenceNumber",
    key: "referenceNumber",
  },
  {
    title: "Adjusted by",
    dataIndex: "addedBy",
    key: "addedBy",
  },
];

const data = [
  {
    date: 'July, 27 2021',
    warehouse: 'Ojota Zone',
    status: 'Pending',
    reference: '0900388YU',
    adjustedBy: 'Grace Jacobs',
  },
  {
    date: 'July, 27 2021',
    warehouse: 'Ojota Zone',
    status: 'Pending',
    reference: '0900388YU',
    adjustedBy: 'Grace Jacobs',
  },
  {
    date: 'July, 27 2021',
    warehouse: 'Ojota Zone',
    status: 'Pending',
    reference: '0900388YU',
    adjustedBy: 'Grace Jacobs',
  },
  {
    date: 'July, 27 2021',
    warehouse: 'Ojota Zone',
    status: 'Pending',
    reference: '0900388YU',
    adjustedBy: 'Grace Jacobs',
  },
  {
    date: 'July, 27 2021',
    warehouse: 'Ojota Zone',
    status: 'Pending',
    reference: '0900388YU',
    adjustedBy: 'Grace Jacobs',
  },
]

export const InventoryAdjustment = (props) => {
  const { paginationProps, adjustmentsList } = useSelector(selectAdjustments);
  console.log(adjustmentsList, 'adjustmentsLists')
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatcher = useDispatch();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (e) => {
  };

  const gotoAdjustItem = () => {
    history.push(`${url}/adjust-item`);
  };

  const handleTableRow = (record, rowIndex) => {
    return {
      onClick: event => {
        history.push(`${url}/adjustmentDetails`);
      }, 
    }
  }

   useEffect(() => {
     (async () => {
       await dispatcher(fetchAllAdjustments({ page: 0, size: 10 }));
     })();
     //  dispatcher(getAllTransfarOrder({ page: 0, size: 10 }));
   }, []);
  
  return (
    <Switch>
      <Route exact path={path}>
        <ToolBar
          title="Inventory Adjustments"
          showtools
          onCreate={gotoAdjustItem}
          createButtonText="New Adjustment"
        />
        <StyledCard mt={2}>
          <TableWithPagination
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            columns={columns}
            dataSource={adjustmentsList}
            onRow={handleTableRow}
          />
        </StyledCard>
      </Route>
      <Route path={`${path}/adjust-item`}>
        <AdjustItem />
      </Route>
      <Route path={`${path}/adjustmentDetails`}>
        <AdjustmentDetails />
      </Route>
    </Switch>
  );
};
