import React, { useState } from "react";
import styled from "styled-components";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { ToolBar } from "../../globalComponents/ToolBar";
import { Menu } from "antd";
import { StyledCard, StyledModal } from "../../globalComponents/styles";
import { useRouteMatch, Route, Switch, useHistory } from "react-router";
import {
  StyledBoldText,
  StyledInputContainer,
} from "../../globalComponents/styles";
import { Box, Grid } from "@material-ui/core";
import { Input, Select, Col, Row, Form } from "antd";
import {
  PrimaryButton,
  TransaparentButton,
} from "../../globalComponents/Buttons";
import { FiTrash2 } from "react-icons/fi";
import { getInput } from "../../Inventory/Items/AddItem";
import { themes } from "../../globalAssets/theme";
import { AntFormModal } from "../../globalComponents/Modal";

const { Option } = Select;

const data = [
  {
    item: [
      { name: "Rice", sku: 20 },
      { name: "Beans", sku: 15 },
    ],
    description: "",
    quantity: "",
    price: "",
    tax: [
      { name: "Freet(10%)", sku: 20 },
      { name: "Freet(20%)", sku: 15 },
    ],
    amount: ""
  },
];

const salesOrder = [
  {
    name: "customer",
    label: "Customer",
    isRequired: false,
    type: "select",
  },
  {
    name: "accountToDeposit",
    label: "Account to Deposit",
    isRequired: false,
    type: "select",
  },
  {
    name: "paymentMethod",
    label: "paymentMethod",
    isRequired: false,
    type: "select",
  },
  {
    name: "notes",
    label: "Notes",
    isRequired: false,
    type: "textarea",
  },
];

const chargesFields = [
    {
      name: "title",
      label: "Title",
      isRequired: true,
      type: "text",
    },
    {
      name: "amount",
      label: "Amount",
      isRequired: true,
      type: "text",
    }
  ];
  

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const AddReceipt = (props) => {
  const [chargesForm] = Form.useForm();
  const [itemsState, setItemsState] = useState(data);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [addChargesModal, setAddChargesModal] = useState({
    isVisible: false,
    loading: false,
  });
  const handleDeleteRow = () => {
    const currentItemState = [...itemsState];
    currentItemState.splice(activeRowIndex, 1);
    setItemsState(currentItemState);
  };

  const columns = [
    {
      title: "Invoice",
      dataIndex: "invoice",
      key: "invoice",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
    },
    {
      title: "Amount Due",
      dataIndex: "amountDue",
      key: "amountDue",
    },
    {
        title: "Payment",
        dataIndex: "payment",
        key: "payment",
        render: (data) => {
          return (
            <StyledInputContainer>
              <Input
                type="number"
                bordered={false}
                /*  onChange={handleAdjustedBy} */
                placeholder=""
              />
            </StyledInputContainer>
          );
        },
      },
  ];

  const handleTableRow = (data, index) => {
    return {
      onClick: (e) => {
        setActiveRowIndex(index);
      },
    };
  };

  const AddAdjustableRow = () => {
    const row = {
      item: [
        { name: "Rice", sku: 20 },
        { name: "Beans", sku: 15 },
      ],
      description: "",
      quantity: "",
      price: "",
      tax: [
        { name: "Freet(10%)", sku: 20 },
        { name: "Freet(20%)", sku: 15 },
      ],
    };

    setItemsState((prevState) => [...prevState, row]);
  };

  const handleSelectChange = (selectName) => (values) => {};

  const salesOrderList = [...salesOrder].map((item) => {
    return <Col sm={12}>{getInput(item, handleSelectChange)}</Col>;
  });

  const chargesInput = [...chargesFields].map((item) => {
      return getInput(item)
  })
  const onFinish = (values) => {};

  return (
    <>
      <ToolBar title="New Reciept" />
      <StyledCard mt={2} py={3} px={4} display="flex">
        <Grid item xs={12}>
          <Box px={3}>
            <StyledBoldText variant="subtitle1">Warehouse</StyledBoldText>
          </Box>
          <Box px={3} mt={2}>
            <Form onFinish={onFinish} {...layout}>
              <Row gutter={16}>{salesOrderList}</Row>
            </Form>
          </Box>
        </Grid>
      </StyledCard>
      <StyledCard mt={2}>
          <Box pl={4} py={3}>
          <StyledBoldText variant='subtitle1'>All Unpaid Invoices </StyledBoldText> 
          </Box>

        <TableWithPagination
          dataSource={itemsState}
          onRow={handleTableRow}
          columns={columns}
          hidePagination
        />
        <Box p={4} display="flex" justifyContent="space-between">
          <PrimaryButton onClick={AddAdjustableRow} text="Add Row" />
          <TransaparentButton
            onClick={() =>
              setAddChargesModal((prevState) => ({
                ...prevState,
                isVisible: true,
              }))
            }
            text="Add Charges"
          />
          
        </Box>
      </StyledCard>
      <AntFormModal
        title="Add Charges"
        visible={addChargesModal.isVisible}
        onCancel={() =>
          setAddChargesModal((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        form={chargesForm}
        loading={addChargesModal.loading}
      >
          { chargesInput }
      </AntFormModal>
    </>
  );
};
