import { createSlice } from "@reduxjs/toolkit";
import Services from "../../../services";

const customerSlice = createSlice({
  name: "customerSlice",
  initialState: {
    customerList: [],
    paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
  },

  reducers: {
    addCustomerList: (state, { payload }) => {
      state.customerList = payload;
    },

    addPaginationProps: (state, { payload }) => {
      state.paginationProps = payload;
    },
  },
});

export const { addCustomerList, addPaginationProps } =
customerSlice.actions;

export const getAllCustomer = (payload) => (dispatcher) => {
  return Services.getCustomers(payload)
    .then((response) => {
      const {currentPage: page, totalPages,count, customers} = response.data.data;
      dispatcher(addPaginationProps({page, totalPages, totalItems: count}));
      dispatcher(addCustomerList(customers));
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.status);
        return Promise.reject(error.response.data);
      }
    });
};

export const createCustomer = (payload) => (dispatcher) => {
  return Services.createCustomers(payload)
    .then((response) => {
        return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const selectCustomer = (state) => state.customerReducer;

export default customerSlice.reducer;
