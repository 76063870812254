// @flow
import * as React from 'react';
import { Select } from 'antd';
import { StyledInputContainer } from './styles';

const { Option } = Select;

export const SelectInput = (props) => {
  function onChange(value) {
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
  }

  return (
    <StyledInputContainer>
      <Select
      showSearch
        bordered={false}
        style={{ width: '100%' }}
        placeholder="Select a person"
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        filterOption={(input, option) => {
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}>
        <Option value="jack">Jack</Option>
        <Option value="lucy">Lucy</Option>
        <Option value="tom">Tom</Option>
      </Select>
    </StyledInputContainer>
  );
};
