import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { selectWarehouse } from "../../Inventory/Warehouse/slice";
import { selectItems } from "../../Inventory/Items/slice";
// import { getInput } from "../../Inventory/Items/AddItem";
import { StyledBoldText, StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import { FiMoreVertical, FiTrash2 } from 'react-icons/fi';
import { ModalComponent } from '../../globalComponents/Modal';
import { Box, Paper } from '@material-ui/core';
import { getInput } from '../Items/AddItem';
import { Form, Row, Col, Input, Select } from 'antd';
import { notificationAlert } from "../../utils/notificationAlert";
import { Divider } from '@material-ui/core';
import { selectDashboard } from "../../Dashboard/slice";
import {
  PrimaryButton,
  TransaparentButton,
} from '../../globalComponents/Buttons';
import {
  StyledInputContainer,
  StyleLabel,
} from '../../globalComponents/styles';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomer } from '../../Clients/Customers/slice';
import { createAddjustments, fetchAllAdjustments } from './slice';

const { Option } = Select;

const addAdjustmentsInput = [
  {
    name: "warehouseName",
    label: "Warehouse Name",
    isRequired: false,
    type: "select",
  },
  // {
  //   name: "addedBy",
  //   label: "Adjusted By",
  //   isRequired: false,
  //   type: "select",
  // },
  {
    name: "referenceNumber",
    label: "Reference Number",
    isRequired: false,
    type: "text",
  },
  {
    name: "inventoryAdjustedDate",
    label: " Adjusted Date",
    isRequired: false,
    type: "date",
  },
  {
    name: "reason",
    label: "Reason",
    isRequired: false,
    type: "text",
  },
  {
    name: "reasonDescription",
    label: "Reason Description",
    isRequired: false,
    type: "textarea",
  },
];

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const data = [
  {
    item: [
      { itemName: "Rice", count: 20 },
      { itemName: "Beans", count: 15 },
    ],
    previousQuantity: 0,
    adjustedQuantity: "",
    itemDescription: "",
    itemId: "",
    itemName: "",
    orgId: "ORG-1593451692921",
  },
];

export const AdjustItem = (props) => {
  const [itemsState, setItemsState] = useState(data);
  const [selectValues, setSelectValues] = useState({});
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false);
  const [basicInfoForm] = Form.useForm();
  const dispatcher = useDispatch();




  const { warehouseList } = useSelector(selectWarehouse);
  const { itemList } = useSelector(selectItems);
  const { customerList } = useSelector(selectCustomer);
  const profile = useSelector(selectDashboard);
  const userProfile2 = profile.userProfile.workShift;

  console.log({ warehouseList, itemList, customerList }, "selectedItem");

  const handleSelectChange = (selectName) => (value, dateString) => {
    const prevSelectValues = { ...selectValues };
    if (selectName === "inventoryAdjustedDate") {
      prevSelectValues[selectName] = moment(dateString).toISOString();
    } else {
      prevSelectValues[selectName] = value;
    }
    setSelectValues(prevSelectValues);
  };

  const AdjustmentsInput = addAdjustmentsInput.map((item) => {
    return <Col xs={11}>{getInput(item, handleSelectChange)}</Col>;
  });

  const getSelectedItem = (currentItemState) => {
    return currentItemState.find((item, index) => index === activeRowIndex);
  };

  const reduceQuantity = (quantity, reduceBy) => {
    return quantity < reduceBy ? quantity : quantity - reduceBy;
  };

  const increaseQuantity = (quantity, increaseBy) => {
    return quantity + increaseBy;
  };

  useEffect(() => {
    const newItemsState = [...itemsState].map((itm) => {
      itm.item = itemList;
      return itm;
    });
    setItemsState(newItemsState);
  }, [itemList]);

  const replaceItem = (currentItemState, selecedItem) => {
    currentItemState.splice(activeRowIndex, 1, selecedItem);
    setItemsState(currentItemState);
  };
  const handleAdjustedBy = (e) => {
    const adjustedBy = parseInt(e.target.value);
    const currentItemState = [...itemsState];
    const selecedItem = getSelectedItem(currentItemState);

    if (e.target.value === "") {
      selecedItem.adjustedQuantity = "";
      replaceItem(currentItemState, selecedItem);
      return;
    }

    if (!isNaN(adjustedBy)) {
      const selectedItemQuantityOnHand = parseInt(selecedItem.quantityOnHand);
      selecedItem.adjustedQuantity =
        Math.sign(adjustedBy) === -1
          ? reduceQuantity(selectedItemQuantityOnHand, Math.abs(adjustedBy))
          : increaseQuantity(selectedItemQuantityOnHand, Math.abs(adjustedBy));
      replaceItem(currentItemState, selecedItem);
      return;
    }
  };

  const handleDeleteRow = () => {
    const currentItemState = [...itemsState];
    currentItemState.splice(activeRowIndex, 1);
    setItemsState(currentItemState);
  };

  // const handleItemSelect = (itemId) => {
  //   const currentItemState = [...itemsState];
  //   const selectedItemObj = getSelectedItem(currentItemState);
  //   const selectedItem = selectedItemObj.item.find((itm) => itm.id === itemId);
  //   console.log(selectedItem, 'hello')
  //   selectedItemObj.itemId = itemId;
  //   // selectedItemObj.itemName = selectedItem.itemName;
  //   // selectedItemObj.previousQuantity = selectedItem.quantity;

  //   // selectedItem.quantityOnHand = value;
  //   replaceItem(currentItemState, selectedItem);
  // };

  const handleItemSelect = (itemId) => {
    const currentItemState = [...itemsState];
    const selectedItemObj = getSelectedItem(currentItemState);
    const selecteditem = selectedItemObj.item.find((itm) => itm.id === itemId);
    selectedItemObj.itemId = itemId;
    selectedItemObj.itemName = selecteditem.itemName;
    selectedItemObj.itemDescription = selecteditem.description;
    selectedItemObj.previousQuantity = selecteditem.quantity;

    replaceItem(currentItemState, selectedItemObj);
  };

  //*handleQuantitytransfar
  const handleQuantityTransfar = (e) => {
    const currentItemState = [...itemsState];
    const selectedItemObj = getSelectedItem(currentItemState);
    const qty = e.target.value;
    selectedItemObj.adjustedQuantity = qty;
    //  selectedItemObj.itemSku = qty
    //  selectedItemObj.amount =
    //    parseInt(qty || 0) * parseInt(selectedItemObj.price);
    replaceItem(currentItemState, selectedItemObj);
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      render: (data) => {
        const optionList = data.map((item, index) => {
          return (
            <Option key={index} value={item.id}>
              {item.itemName}
            </Option>
          );
        });
        return (
          <StyledInputContainer>
            <Select
              onChange={handleItemSelect}
              placeholder="Select Item"
              style={{ width: "100%" }}
              bordered={false}
            >
              {optionList}
              {/* { console.log(optionList, 'optionList')} */}
            </Select>
          </StyledInputContainer>
        );
      },
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (data) => {
        return (
          <StyledInputContainer>
            <Input bordered={false} value={data} disabled />

            {/* <Input
              type="text"
              onChange={handleSelectChange}
              value={data}
              bordered={false}
              placeholder="type here"
            /> */}
          </StyledInputContainer>
        );
      },
    },
    {
      title: "Quantity on Hand",
      dataIndex: "previousQuantity",
      key: "previousQuantity",
      render: (data) => {
        return (
          <StyledInputContainer>
            <Input bordered={false} value={data} disabled />
          </StyledInputContainer>
        );
      },
    },

    // {
    //   title: "Adjust By",
    //   dataIndex: "adjustBy",
    //   key: "adjustBy",
    //   render: (data) => {
    //     return (
    //       <StyledInputContainer>
    //         <Input
    //           bordered={false}
    //           onChange={handleAdjustedBy}
    //           placeholder="Eg +10. -10"
    //         />
    //       </StyledInputContainer>
    //     );
    //   },
    // },
    {
      title: "Adjusted Quantity",
      dataIndex: "adjustedQuantity",
      key: "adjustedQuantity",
      render: (data) => {
        return (
          <Box display="flex" alignItems="center">
            <StyledInputContainer>
              {/* <Input bordered={false} value={data} disabled /> */}
              <Input
                type="number"
                onChange={handleQuantityTransfar}
                value={data}
                bordered={false}
                placeholder="Eg +10. -10"
              />
            </StyledInputContainer>
            {itemsState.length > 1 && (
              <FiTrash2
                onClick={handleDeleteRow}
                style={{ fontSize: "1.4vw", marginLeft: "20px" }}
              />
            )}
          </Box>
        );
      },
    },
  ];

  const handleTableRow = (data, index) => {
    return {
      onClick: (e) => {
        setActiveRowIndex(index);
      },
    };
  };

  // const AddAdjustableRow = () => {
  //   const row = {
  //     item: [
  //       { name: "Rice", count: 20 },
  //       { name: "Beans", count: 15 },
  //     ],
  //     quantityOnHand: 0,
  //     adjustedQuantity: "",
  //   };

  //   setItemsState((prevState) => [...prevState, row]);
  // };
  const AddAdjustableRow = () => {
    const row = {
      item: itemList,
      previousQuantity: 0,
      adjustedQuantity: "",
      itemDescription: "",
      itemId: "",
      itemName: "",
    };

    setItemsState((prevState) => [...prevState, row]);
  };

  // const customerOptionList = customerList.map((item, index) => {
  //   return (
  //     <Option value={item.id} key={index}>
  //       {`${item.firstName} ${item.lastName}`}
  //     </Option>
  //   );
  // });

  const warehouseOptionList = warehouseList.map((item, index) => {
    return (
      <Option value={item.id} key={index}>
        {item.name}
      </Option>
    );
  });

  const getAdjustmentsInput = [...addAdjustmentsInput].map((item) => {
    if (item.name === "warehouseName") {
      return (
        <Col sm={12}>
          {getInput(item, handleSelectChange, warehouseOptionList)}
        </Col>
      );
    }
    return <Col sm={10}>{getInput(item, handleSelectChange)}</Col>;
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    basicInfoForm.submit();
  };

  // on finish
  const onFinish = async (values) => {
    try {
      setCreateInvoiceLoading(true);
      values.warehouseName = selectValues.warehouseName;
      values.inventoryAdjustedDate = selectValues.inventoryAdjustedDate;
      values.orgId = userProfile2.orgId;

      // values.referenceNumber = selectValues.referenceNumber;
      // values.reason = selectValues.reason;
      // values.reasonDescription = selectValues.reasonDescription;
      // values.orgId = ORG - 1593451692921;

      const items = itemsState.map((item) => {
        return {
          itemId: item.itemId,
          itemName: item.itemName,
          previousQuantity: item.previousQuantity,
          adjustedQuantity: item.adjustedQuantity,
          itemDescription: item.itemDescription,
          orgId: "ORG-1593451692921",
        };
      });

      values.items = items;
      console.log(values, "values");
       await dispatcher(createAddjustments({ data: values }));
        await dispatcher(fetchAllAdjustments({}));
        notificationAlert(
          "success",
          "Adjustments was successfully created"
        );
        setCreateInvoiceLoading(false);
      //   // history.push("/invoice");
    } catch (error) {
      setCreateInvoiceLoading(false);
      notificationAlert(
        "error",
        "Failed",
        error?.response?.data?.message || "Please try again later"
      );
    }
  };

  return (
    <>
      <ToolBar title="Add Inventory Adjustments" />
      <StyledCard p={4} mt={2}>
        <Form form={basicInfoForm} onFinish={onFinish} {...layout}>
          <Row justify="space-between">{getAdjustmentsInput}</Row>
        </Form>
      </StyledCard>
      <StyledCard mt={2}>
        <TableWithPagination
          columns={columns}
          dataSource={itemsState}
          onRow={handleTableRow}
          hidePagination={true}
        />
      </StyledCard>
      <Box p={4}>
        <PrimaryButton onClick={AddAdjustableRow} text="Add Row" />
        <Box mt={6} pb={5} display="flex" justifyContent="flex-start">
          <PrimaryButton
            loading={createInvoiceLoading}
            onClick={handleFormSubmit}
            text="Save &amp; Submit"
          />
        </Box>
      </Box>
    </>
  );
};
