import { createSlice } from '@reduxjs/toolkit';
import Services from '../../../services';

const itemSlice = createSlice({
    name: 'itemSlice',
    initialState: {
        itemList: [],
        singleItem: {},
        paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
    },
    reducers: {
        addItemList: (state, {payload}) => {
            state.itemList = payload;
        },
        addSingleItem: (state, {payload}) => {
            state.singleItem = payload;
        },
        addPaginationProps: (state, {payload}) => {
            state.paginationProps = payload;
        }
    }
})

export const { addItemList, addPaginationProps, addSingleItem } = itemSlice.actions;

export const fetchAllItems = payload => dispatcher => {
    return Services.getAllItems(payload).then(
        response => {
            console.log(response, 'item234')
            const {currentPage: page, totalPages, totalItems, items} = response.data.data;
            dispatcher(addPaginationProps({page, totalPages, totalItems}));
            dispatcher(addItemList(items.filter(item => !item.delFlag)))
            return Promise.resolve(response.data.data);
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}


export const createItem = payload => dispatcher => {
    return Services.createItem(payload)
    .then((response) => {
            return Promise.resolve(response.data.data);
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}

export const selectItems = state => state.itemReducer;

export default itemSlice.reducer;