import React from "react";
import { StyledBoldText, StyledLogo, StyledTopBar } from "./styles";
import Logo from "../images/logo.png";
import { IconButton, Box, Button } from "@material-ui/core";
import { DropDown } from "./DropDown";
import { useHistory } from "react-router-dom";
import { IoMdArrowDropdown } from 'react-icons/io';

export const TopBar = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (event, itemName) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      console.log(itemName);
      switch (itemName) {
        case "Home":
          window.location.assign("http://apps.ezoneerp.com/home");
          break;
        case "Chat":
          window.location.assign("http://apps.ezoneerp.com/chats");
          break;
        case "File Management":
          window.location.assign(
            "http://apps.ezoneerp.com/file-manager/folders"
          );
          break;
        case "Human Resources":
          window.location.assign("http://apps.ezoneerp.com/hr/dashboard");
          break;
        case "Payroll":
          window.location.assign("http://apps.ezoneerp.com/payroll");
          break;
        case "Task Management":
          window.location.assign("http://apps.ezoneerp.com/task-manager/tasks");
          break;
        default:
          break;
      }
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <StyledTopBar
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{
        visibility: props.hidden ? "hidden" : "visible",
        position: props.hidden ? "relative" : "fixed",
      }}
      px={3}
      py={1}
    >
      <StyledLogo onClick={() =>  window.location.assign("http://apps.ezoneerp.com/home")} src={Logo} alt="logo" />
      <div ref={anchorRef}>
        <Button onClick={handleToggle}  endIcon={<IoMdArrowDropdown />}>
          <StyledBoldText variant="subtitle2">Applications</StyledBoldText>
        </Button>
        <DropDown
          items={[
            "Home",
            "Chat",
            "File Management",
            "Human Resources",
            "Payroll",
            "Task Management",
          ]}
          handleListKeyDown={handleListKeyDown}
          ref={anchorRef}
          open={open}
          handleClose={handleClose}
        />
      </div>
    </StyledTopBar>
  );
};
