 const routes = {
   createItemGroupRoute: "/add_item_group",
   getAllItemGroupRoute: "/get_all_item_groups",
   getItemGroupsByOrgId: (orgId) => `/get_item_group_by_orgid/${orgId}`,
   updateItemGroupRoute: (id) => `/update_item_group/${id}`,
   getItemGroupNumber: "/get_number_of_item_groups",
   getSingleItemGroup: (id) => `/get_item_group_by_id/${id}`,
   deleteItemGroup: (id) => `/delete_contact_group/${id}`,
   addWarehouse: "/add_warehouse",
   getAllWarehouse: "/get_all_warehouses",
   deleteWarehouse: (id) => `/delete_warehouse/${id}`,
   updateWarehouse: (id) => `/update_warehouse/${id}`,
   getSingleWarehouse: (id) => `/get_single_warehouse/${id}`,
   itemsRoute: "/items",
   userProfileRoute: "/users/profile",
   userByOrgRoute: (orgid) => `/users/get_by_orgid/${orgid}`,
   invoiceRoute: "/invoices",
   customersRoute: "/customers",
   paymentRoute: "/payments/invoice_id",
   logoutRoute: "/logout",
   getSingleInvoice: (id) => `invoices/${id}/single`,
   vendorRoute: "/vendor",
   createReceiptRoute: "/receipts",
   getAllReceiptsRoute: "/receipts/getAllReceipts",
   getSingleReceiptRoute: (id) => `/receipts/getReceipts/${id}`,
   issueItemRoute: `/issue-items`,
   getAllTransfarOrderRoute: `/get_all_transfer_orders`,
   addTransfarOrderRoute: `/add_transfer_orders`,
   adjustmentsListRoute: `/get_all_adjustments`,
   addAdjustmentsRoute: `/add_adjustments`
 };

export default routes;