import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { ToolBar } from "../../globalComponents/ToolBar";
import { Menu, notification, Statistic } from "antd";
import { StyledCard, StyledModal } from "../../globalComponents/styles";
import { useRouteMatch, Route, Switch, useHistory } from "react-router";
import {
  StyledBoldText,
  StyledInputContainer,
  StyledNormalText,
} from "../../globalComponents/styles";
import { Box, Grid, makeStyles, Divider } from "@material-ui/core";
import { Input, Select, Col, Row, Form } from "antd";
import {
  PrimaryButton,
  TransaparentButton,
} from "../../globalComponents/Buttons";
import { FiTrash2 } from "react-icons/fi";
import { getInput } from "../../Inventory/Items/AddItem";
import { themes } from "../../globalAssets/theme";
import { AntFormModal } from "../../globalComponents/Modal";
import {
  CreateCustomerModal,
  addCustomerInput,
} from "../../Clients/Customers/index";
import { useSelector } from 'react-redux';
import { selectItems } from '../../Inventory/Items/slice';
import { selectCustomer } from '../../Clients/Customers/slice';
import { selectDashboard } from '../../Dashboard/slice';
import moment from 'moment';
import Services from "../../../services";
import { notificationAlert } from "../../utils/notificationAlert";
import { createCustomer, getAllCustomer } from '../../Clients/Customers/slice';
import {useDispatch} from 'react-redux';
import { Feedback } from "../../globalComponents/Feedback";
import { getAllInvoice } from './slice';



const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  icons: {
    fontSize: "1.4vw",
    color: theme.grey,
    marginRight: "15px",
    "&:hover": {
      color: theme.primaryColor,
      cursor: "pointer",
    },
  },

  historyIcon: {
    color: theme.primaryColor,
    fontSize: "1.4vw",
    marginRight: "10px",
  },
  arroweft: {
    width: 0,
    height: 0,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",

    borderRight: `20px solid ${theme.foreground}`,
  },
  logo: {
    width: "15%",
    height: "auto",
  },
  totalBackground: {
    backgroundColor: theme.background,
  },
}));

const data = [
  {
    item: [
      { itemName: "Rice", price: 20, id: 1 },
      { itemName: "Beans", price: 15 , id: 2},
    ],    
    quantity: 0,
    price: 0,
    amount: 0,
    itemId: '',
    itemName: ''
  },
];

const salesOrder = [
  {
    name: "customer_id",
    label: "Customer",
    isRequired: false,
    type: "search",
  },
  /* {
    name: "invoice",
    label: "Invoice",
    isRequired: false,
    type: "text",
    defaultValue: "INV/000001",
    disabled: true,
  }, */
  /* {
    name: "invoiceDate",
    label: "Invoice Date",
    isRequired: false,
    type: "date",
  }, */
  {
    name: "due_date",
    label: "Due date",
    isRequired: false,
    type: "date",
  },
 /*  {
    name: "sopo_number",
    label: "SO/PO Number",
    isRequired: false,
    type: "number",
  }, */
  {
    name: "sales_person",
    label: "Sales person",
    isRequired: false,
    type: "select",
  },
  {
    name: "notes",
    label: "Notes",
    isRequired: false,
    type: "textarea",
  },
];

const chargesFields = [
  {
    name: "name",
    label: "Name",
    isRequired: true,
    type: "text",
  },
  {
    name: "amount",
    label: "Amount",
    isRequired: true,
    type: "number",
  },
];

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const customerArr = ["ade", "toyin"];

export const AddInvoice = (props) => {
  const { usersByOrg, userProfile } = useSelector(selectDashboard);
  const { customerList } = useSelector(selectCustomer);
  const { itemList } = useSelector(selectItems);

  // console.log({ usersByOrg, customerList, itemList }, "addInvoice");


  const classes = useStyles();
  const [chargesForm] = Form.useForm();
  const [basicInfoForm] = Form.useForm();
  const [itemsState, setItemsState] = useState(data);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [otherCharges, setOtherCharges] = useState([]);
  const [selectValues, setSelectValues] = useState({});
  const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false);
  const [addChargesModal, setAddChargesModal] = useState({
    isVisible: false,
    loading: false,
  });
  const [addCustomerModalProps, setAddCustomerModalProps] = useState({
    isVisible: false,
    loading: false,
  });
  const [feedbackProps, setFeedbackProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const history = useHistory();
  const dispatcher = useDispatch();


  useEffect(() => {
    const formattedItemsState = [...itemsState].map(itm => {
       itm.item = itemList;
      return itm;
    })
    setItemsState(formattedItemsState);
  }, [itemList])


  const customerInputs = addCustomerInput.map((item) => getInput(item));

  const handleDeleteRow = () => {
    const currentItemState = [...itemsState];
    currentItemState.splice(activeRowIndex, 1);
    setItemsState(currentItemState);
  };

  const getSelectedItem = (currentItemState) => {
    return currentItemState.find((item, index) => index === activeRowIndex);
   
  };

  const replaceItem = (currentItemState, selecedItem) => {
    currentItemState.splice(activeRowIndex, 1, selecedItem);
    setItemsState(currentItemState);
  };

  const handleItemSelect = (itemId) => {
    const currentItemState = [...itemsState];
    const selectedItemObj = getSelectedItem(currentItemState);
    const selecteditem = selectedItemObj.item.find(itm => itm.id === itemId);
    selectedItemObj.itemId = itemId;
    selectedItemObj.itemName =  selecteditem.itemName
    selectedItemObj.price = parseInt(selecteditem.sellingPrice);
    replaceItem(currentItemState, selectedItemObj);
  }
 

  const handleQuantityChange = (e) => {
    const currentItemState = [...itemsState];
    const selectedItemObj = getSelectedItem(currentItemState);
    const qty = e.target.value;
    selectedItemObj.quantity = qty;
    selectedItemObj.amount = parseInt(qty || 0) * parseInt(selectedItemObj.price);
    replaceItem(currentItemState, selectedItemObj);
  }

  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      render: (data) => {
        const optionList = data.map((item, index) => {
          return (
            <Option key={index} value={item.id}>
              {item.itemName }
            </Option>
          );
        });
        return (
          <StyledInputContainer>
            <Select
               onChange={handleItemSelect}
              placeholder="Select Item"
              style={{ width: "100%" }}
              bordered={false}
            >
              {optionList}
             {/* { console.log(optionList, 'optionList')} */}
              
            </Select>
          </StyledInputContainer>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (data, allData) => {
        return (
          <StyledInputContainer>
            <Input
              type="number"
              disabled={!Boolean(allData.price)}
              bordered={false}
               onChange={handleQuantityChange}
              placeholder=""
            />
          </StyledInputContainer>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (data) => {
      
        return (
          <StyledInputContainer>
            <Input
              type="number"
              bordered={false}
              disabled={true}
              value={data}
              /*  onChange={handleAdjustedBy} */
              placeholder=""
            />
          </StyledInputContainer>
        );
      },
    },
    /* {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (data) => {
        const optionList = data.map((item, index) => {
          return (
            <Option key={index} value={item.sku}>
              {item.name}
            </Option>
          );
        });
        return (
          <StyledInputContainer>
            <Select
               onChange={handleItemSelect}
              placeholder="Select Item"
              style={{ width: "100%" }}
              bordered={false}
            >
              {optionList}
            </Select>
          </StyledInputContainer>
        );
      },
    }, */
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data, allData) => {
        return (
          <StyledInputContainer>
            <Input
              type="number"
              bordered={false}
              value={data}
              placeholder=""
              disabled={true}
            />
          </StyledInputContainer>
        );
      },
    },
    {
      title: "Del",
      dataIndex: "delete",
      key: "delete",
      width: "1%",
      render: (data) => {
        return (
          itemsState.length > 1 && (
            <FiTrash2
              onClick={handleDeleteRow}
              style={{ fontSize: "1.4vw", marginLeft: "20px", color: "red" }}
            />
          )
        );
      },
    },
  ];

  const handleTableRow = (data, index) => {
    return {
      onClick: (e) => {
        setActiveRowIndex(index);
      },
    };
  };

  const AddAdjustableRow = () => {
    const row = {
      item: itemList,    
      quantity: 0,
      price: 0,
      amount: 0,
      itemId: '',
      itemName: ''
    };

    setItemsState((prevState) => [...prevState, row]);
  };

  const handleSelectChange = (selectName) => (value, dateString) => {
    const prevSelectValues = { ...selectValues };
    if(selectName === 'due_date'){
      prevSelectValues[selectName] = moment(dateString).toISOString();
    }else {
      prevSelectValues[selectName]  = value;
    }
    setSelectValues(prevSelectValues);
  };
  const onFooterClick = (name) => {
    if (name === "customer_id") {
      setAddCustomerModalProps((prevState) => ({
        ...prevState,
        isVisible: true,
      }));
    }
  };

  const customerOptionList = customerList.map((item, index) => {
    return (
      <Option value={item.id} key={index}>
        {`${item.firstName} ${item.lastName}`}
      </Option>
    );
  });

  const userList = [...usersByOrg].map((user, index) => {
    return (
      <Option key={index} value={user.id}>
        {user.fullName !== " " ? user.fullName : "Adefisayo Adeniji"}
      </Option>
    );
  });

  const salesOrderList = [...salesOrder].map((item) => {
    if (item.name === "customer_id") {
      return (
        <Col sm={12}>
          {getInput(
            item,
            handleSelectChange,
            customerOptionList,
            null,
            onFooterClick
          )}
        </Col>
      );
    }

    if(item.name === 'sales_person'){
      return (
        <Col sm={12}>
          {getInput(
            item,
            handleSelectChange,
            userList,
          )}
        </Col>
      );
    }
    return <Col sm={12}>{getInput(item, handleSelectChange)}</Col>;
  });

  const chargesInput = [...chargesFields].map((item) => {
    return getInput(item);
  });

  const onAddCharges = (values) => {
    setOtherCharges((prevState) => [...prevState, values]);
    setAddChargesModal((prevState) => ({ ...prevState, isVisible: false }));
  };

  const otherChargesList = [...otherCharges].map((item, index) => {
    return <OtherChargesComponent data={item} key={index} />;
  });

 

  const getOtherCharges = () => {
    return otherCharges.reduce((prev, curr) => {
      return  prev + parseInt(curr.amount);
    }, 0);
  }


  const getSubTotal = () => {
    return itemsState.reduce((prev, curr) => {
        return prev + parseInt(curr.amount);
    }, 0);
  }

  const getTotal = () => {
    return  getSubTotal() + getOtherCharges();
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    basicInfoForm.submit()
    
  }

  const onFinish = async (values) => {
    try {
      setCreateInvoiceLoading(true)
      values.sales_person = selectValues.sales_person;
      values.customer_id = selectValues.customer_id;
      values.due_date = selectValues.due_date;
      const items =  itemsState.map(item => {
        return ({id: item.itemId, quantity: item.quantity, price: item.price, itemName: item.itemName});
      })
     
      values.items = items;
      values.total_amount = getSubTotal() + getOtherCharges();
      values.charges = otherCharges;

      console.log(values, 'values')
      await Services.createInvoice({data: values, id: values.customer_id});
      await  dispatcher(getAllInvoice({page: 0, size: 10}));
      notificationAlert('success', 'Invoice Created', 'Invoice was successfully created');
      setCreateInvoiceLoading(false);
      history.push('/invoice');
    } catch (error) {
      setCreateInvoiceLoading(false);
      notificationAlert('error', 'Failed', error?.response?.data?.message || 'Please try again later');
    }
  };

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackProps((prevState) => ({ ...prevState, open: false }));
  };


  const onAddCustomer = async (values) => {
    try {
      setAddCustomerModalProps((prevState) => ({ ...prevState, loading: true }));
      values.headOfWareHouseId = selectValues.headOfWareHouseId;
      values.orgId = userProfile.orgId;
      await dispatcher(createCustomer({ data: values }));
      await dispatcher(getAllCustomer({}));
      setAddCustomerModalProps((prevState) => ({
        ...prevState,
        isVisible: false,
        loading: false,
      }));
      setFeedbackProps({
        type: "success",
        open: true,
        message: "customer has been added",
      });
    } catch (error) {
      setAddCustomerModalProps((prevState) => ({ ...prevState, loading: false }));
      setFeedbackProps({
        type: "error",
        open: true,
        message: "An error occurred, please try again later",
      });
    }
  }
  return (
    <>
      <ToolBar title="New Invoice" />
      <StyledCard mt={2} py={3} px={4} display="flex">
        <Grid item xs={12}>
         {/*  <Box px={3}>
            <StyledBoldText variant="subtitle1">Warehouse</StyledBoldText>
          </Box> */}
          <Box px={3} mt={2}>
            <Form form={basicInfoForm} onFinish={onFinish} {...layout}>
              <Row gutter={16}>{salesOrderList}</Row>
            </Form>
          </Box>
        </Grid>
      </StyledCard>
      <StyledCard mt={2}>
        <TableWithPagination
          dataSource={itemsState}
          onRow={handleTableRow}
          columns={columns}
          hidePagination
          // onRow={handleTableRow}
        />
      </StyledCard>
      <Box p={4} display="flex" justifyContent="space-between">
        <PrimaryButton onClick={AddAdjustableRow} text="Add Row" />
        <Box flexBasis="40%">
          <Box px={1}>{otherChargesList}</Box>

          <Divider />
          <TotalComponent title="Sub-total" amount={getSubTotal()} />
          <TotalComponent title="Total" amount={getTotal()} />
          <TransaparentButton
            onClick={() =>
              setAddChargesModal((prevState) => ({
                ...prevState,
                isVisible: true,
              }))
            }
            text="Add Charges"
          />
          <Box mt={6} pb={5} display="flex" justifyContent="flex-start">
            <Box mr={2}>
              <TransaparentButton text="Cancel" />
            </Box>
           {/*  <Box mr={2}>
              <PrimaryButton text="Save to Draft" />
            </Box> */}
            <PrimaryButton loading={createInvoiceLoading} onClick={handleFormSubmit} text="Save &amp; Submit" />
          </Box>
        </Box>
      </Box>

      <AntFormModal
        title="Add Charges"
        visible={addChargesModal.isVisible}
        onCancel={() =>
          setAddChargesModal((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        form={chargesForm}
        loading={addChargesModal.loading}
        onFinish={onAddCharges}
      >
        {chargesInput}
      </AntFormModal>
      <CreateCustomerModal
        visible={addCustomerModalProps.isVisible}
        onCancel={() =>
          setAddCustomerModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
         onFinish={onAddCustomer}
        loading={addCustomerModalProps.loading}
        warehouseInputs={customerInputs}
      />
      <Feedback
        open={feedbackProps.open}
        message={feedbackProps.message}
        type={feedbackProps.type}
        onClose={handleFeedbackClose}
      />
    </>
  );
};

export const TotalComponent = (props) => {
  const classes = useStyles();
  return (
    <StyledCard
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
      mt={2}
      p={2}
    >
      <StyledBoldText>{props.title}</StyledBoldText>
      <Box p={1} flexBasis="80%" className={classes.totalBackground}>
        <Statistic valueStyle={{fontSize: '1.2vw', color: themes.boldText}}  value={props.amount} prefix='₦' />
      </Box>
    </StyledCard>
  );
};

export const OtherChargesComponent = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <StyledNormalText>{props.data.name}</StyledNormalText>
      <Statistic valueStyle={{fontSize: '1.2vw', color: themes.boldText}}  value={props.data.amount} prefix='₦' />
    </Box>
  );
};
