//Transfar order slice
import { createSlice } from "@reduxjs/toolkit";
import Services from "../../../services";

const transfarOrderSlice = createSlice({
    //Slice Name
  name: "transfarOrderSlice",
  // InitialState
  initialState: {
    transfarOrderList: [],
    paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
    singleTransfarOrder: {},
  },

  reducers: {
    addtransfarOrderList: (state, { payload }) => {
      state.transfarOrderList = payload;
    },

    addPaginationProps: (state, { payload }) => {
      state.paginationProps = payload;
    },

    singleTransfarOrder: (state, { payload }) => {
      state.singleTransfarOrder = payload;
    },
  },
});

export const { addtransfarOrderList, addPaginationProps, singleTransfarOrder } =
  transfarOrderSlice.actions;


  // get all transfar orders
  export const getAllTransfarOrder = (payload) => (dispatcher) => {
  return Services.getAllTransfarOrder(payload)
    .then((response) => {
      // console.log(response, 'transfaroder234')
      const { data } = response.data;
      dispatcher(addtransfarOrderList(data.filter((item) => !item.delFlag)));
      return Promise.resolve(data.filter((item) => !item.delFlag));
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

//create Transfar order
export const createTransfarOrder = (payload) => (dispatcher) => {
  return Services.createTransfarOrder(payload)
    .then((response) => {
      console.log(response, "createTransfar");
      return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};


export const selectTransfarOrder = (state) => state.transfarOrderReducer;

export default transfarOrderSlice.reducer;
