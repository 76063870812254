import { createSlice } from '@reduxjs/toolkit';
import Services from '../../../services';


const receiptSlice = createSlice({
    name: 'receiptSlice',
    initialState: {
        allReceipts: [],
        singleReceipt: {},
        paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
    },

    reducers: {
        addAllReceipts: (state, {payload}) => {
            state.allReceipts = payload;
        },
        addSingleReceipt: (state, {payload}) => {
            state.singleReceipt = payload;
        },
        addPaginationProps: (state, { payload }) => {
            state.paginationProps = payload;
        },
    }
});


export const { addAllReceipts, addSingleReceipt, addPaginationProps } = receiptSlice.actions;

export const fetchAllReceipt = (payload) => (dispatcher) => {
    return Services.getAllReceipt(payload).then(
        response => {
            console.log(response.data, 'allReceipt22')
            const {currentPage: page, totalPages, totalItems, receipts} = response.data;
            dispatcher(addAllReceipts(receipts));
            dispatcher(addPaginationProps({page, totalPages, totalItems}));

            return Promise.resolve(receipts);
        },
        error => {
            if(error.response){
                return Promise.reject(error.response.data);
            }
        }
    )
}

export const fetchSingleReceipt = payload => dispatcher => {
    return Services.getSingleReceipt(payload).then(
        response => {
            console.log(response, 'singleReceipt')
            dispatcher(addSingleReceipt(response.data));
            return Promise.resolve(response.data);
        },
        error => {
            if(error.response){
                return Promise.reject(error.response.data);
            }
        }
    )
}

//create Receipt
export const createReceipt = (payload) => (dispatcher) => {
  return Services.createReceipt(payload)
    .then((response) => {
      console.log(response, "createReceipt");
      return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};


export const selectReceipts = state =>  state.receiptReducer;

export default receiptSlice.reducer;

