import { createSlice } from '@reduxjs/toolkit';
import Services from '../../services';
import { switchURL } from '../utils/switchURL';

const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        usersByOrg: [],
        userProfile:{},
        itemGroupCount: 0,
    },
    reducers: {
        addUserProfile: (state, {payload}) => {
            state.userProfile = payload;
        },
        addUsersByOrg: (state, { payload }) => {
            state.usersByOrg = payload;
        }
    }
});

export const { addUserProfile,  addUsersByOrg } = dashboardSlice.actions;

export const getUsersByOrg = payload => dispatcher => {
    return Services.getUsersByOrg(payload).then(
        response => {
            dispatcher(addUsersByOrg(response.data));
        }
    ).catch(error => {
        if(error.response){
            Promise.reject(error.response.data)
        }
    })
}


export const getUserProfile = payload => dispatcher => {
    return Services.getUserProfile(payload).then(
        response => {
            dispatcher(addUserProfile(response.data));
            dispatcher(getUsersByOrg({orgId: response.data.organisation.orgId}));
            return Promise.resolve(response.data);
        }
    ).catch(error => {
        if(error.response){
            console.log(error.response.status);
            if(error.response.status === 401){
                window.location.assign(switchURL("http://apps.ezoneerp.com/login"));
            }
           return Promise.reject(error.response.data)
        }
    })
}

export const getItemGroupNumber = payload => dispatcher => {
    return Services.fetchItemGroupNumber().then(
        response => {
            console.log('STAT',response);
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data)
         }
    })
}



export const selectDashboard = state => state.dashboardReducer;

export default dashboardSlice.reducer;

