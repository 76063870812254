import { createSlice } from "@reduxjs/toolkit";
import Services from "../../../services";

const warehouseSlice = createSlice({
  name: "warehouseSlice",
  initialState: {
    warehouseList: [],
    paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
    singleWarehouse: {},
  },

  reducers: {
    addWarehouseList: (state, { payload }) => {
      state.warehouseList = payload;
    },

    addPaginationProps: (state, { payload }) => {
      state.paginationProps = payload;
    },

    addSingleWarehouse: (state, { payload }) => {
      state.singleWarehouse = payload;
    },
  },
});

export const { addWarehouseList, addPaginationProps, addSingleWarehouse } =
  warehouseSlice.actions;

export const getAllWarehouse = (payload) => (dispatcher) => {
  return Services.getAllWarehouse(payload)
    .then((response) => {
      const {currentPage: page, totalPages, warehouses} = response.data.data;
      dispatcher(addPaginationProps({page, totalPages, totalItems: warehouses.filter(item => !item.delFlag).length}) 
      );
      dispatcher(addWarehouseList(warehouses.filter(item => !item.delFlag)));
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const createWarehouse = (payload) => (dispatcher) => {
  return Services.addWarehouse(payload)
    .then((response) => {
        return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const getSingleWarehouse = (payload) => (dispatcher) => {
  return Services.fetchSingleWarehouse(payload)
    .then((response) => {
          // console.log(response, 'getSingleWarahouse')
        dispatcher(addSingleWarehouse(response.data.data));
        return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const selectWarehouse = (state) => state.warehouseReducer;

export default warehouseSlice.reducer;
