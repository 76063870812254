import React from 'react';
import {
  InputAdornment,
  Typography,
  Box,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Slide
} from '@material-ui/core';
import { StyledModal } from './styles';
import { themes } from '../globalAssets/theme';
import { Form } from 'antd';


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
}))

export const ModalComponent = (props) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
     {/*  <Fade in={props.open}>{props.children}</Fade> */}
     <Slide direction="up" in={props.open} mountOnEnter unmountOnExit>
     {props.children}
    </Slide>
    </Modal>
  );
};


export const AntFormModal = (props) => {
  const onOk = () => {
    props.form.submit();
  };

  return (
    <StyledModal
      title={props.title}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={onOk}
      okText={props.okText || "Create"}
      onCancel={props.onCancel}
    >
      <Form
        initialValues={props.initialValues}
        form={props.form}
        {...layout}
        onFinish={props.onFinish}
      >
        {props.children}
      </Form>
    </StyledModal>
  );
};


export const AntModal = (props) => {
 

  return (
    <StyledModal
      title={props.title}
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText={props.okText ?? "Create"}
      onCancel={props.onCancel}
    >
      {props.children}
    </StyledModal>
  );
};
