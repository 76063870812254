import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { ToolBar } from "../../globalComponents/ToolBar";
import {
  StyledBoldText,
  StyledCard,
  StyledModal,
} from "../../globalComponents/styles";
import { useRouteMatch, Route, Switch, useHistory } from "react-router";
import { FiMoreVertical } from "react-icons/fi";
import { ModalComponent } from "../../globalComponents/Modal";
import { Box, Paper } from "@material-ui/core";
import { getInput } from "../Items/AddItem";
import { Form, Space, Popover, Select, notification } from "antd";
import { Divider } from "@material-ui/core";
import {
  PrimaryButton,
  TransaparentButton,
} from "../../globalComponents/Buttons";
import Services from "../../../services";
import { Feedback } from "../../globalComponents/Feedback";
import { DeleteModal } from "../../globalComponents/deleteModal";
import { createWarehouse, getAllWarehouse, selectWarehouse } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboard } from "../../Dashboard/slice";
import { themes } from "../../globalAssets/theme";
import { notificationAlert } from "../../utils/notificationAlert";
import { getSingleWarehouse } from "./slice";
import { WarehouseDetails } from './WarehouseDetails'

const { Option } = Select;

const addWarehouseInput = [
  {
    name: "name",
    label: "Warehouse Name",
    isRequired: true,
    type: "text",
    defaultValue: "",
  },
  {
    name: "warehousePhoneNumber",
    label: "Phone",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  {
    name: "firstStreet",
    label: "First Street",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  {
    name: "secondStreet",
    label: "Second Street",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  {
    name: "city",
    label: "City",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  {
    name: "state",
    label: "State",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  {
    name: "zipCode",
    label: "Zip Code",
    isRequired: false,
    type: "number",
    defaultValue: "",
  },
  {
    name: "wareHouseContactEmail",
    label: "Warehouse Email",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  {
    name: "headOfWareHouseId",
    label: "Head of Warehouse Name",
    isRequired: false,
    type: "search",
  },
  /*  {
    name: 'headOfWareHouseEmailAddress',
    label: 'Head of Warehouse Email',
    isRequired: false,
    type: 'text',
    defaultValue:''
  },
  {
    name: 'headOfWareHouseId',
    label: 'Head of Warehouse ID',
    isRequired: false,
    type: 'number',
    defaultValue:''
  }, */
];



const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const Warehouse = (props) => {
  const { warehouseList, paginationProps } = useSelector(selectWarehouse);
  const { usersByOrg, userProfile } = useSelector(selectDashboard);

  // console.log({warehouseList, paginationProps}, 'warehouse')
  const [editWarehouseForm] = Form.useForm();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const {path, url} = useRouteMatch();
  const history = useHistory();
  const [selectValues, setSelectValues] = useState({});
  const [addGroupModalProps, setAddGroupModalProps] = useState({
    isVisible: false,
    loading: false,
  });
  const [feedbackProps, setFeedbackProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [deleteModalProps, setDeleteModalProps] = useState({
    visible: false,
    name: "",
    id: null,
    loading: false,
  });
  const [editModalProps, setEditModalProps] = useState({
    isVisible: false,
    selectedItemInput: [],
    loading: false,
    initialValues: {},
  });
  const dispatcher = useDispatch();

  useEffect(() => {
    editWarehouseForm.resetFields();
  }, [editModalProps.isVisible]);

  const handleSelectChange = (selectName) => (value) => {
    const prevSelectValues = { ...selectValues };
    prevSelectValues[selectName] = value;
    setSelectValues(prevSelectValues);
  };

  const userList = [...usersByOrg].map((user, index) => {
    return (
      <Option key={index} value={user.id}>
        {user.fullName !== " " ? user.fullName : "Adefisayo Adeniji"}
      </Option>
    );
  });

  const searchObject = {
    onSearch: (val) => {},
  };

  const handleViewDetail = (id) => async () => {
   /*  try {
      const response = await dispatcher(getSingleWarehouse({id}));
      console.log(response);
    } catch (error) {
      console.log(error);
    } */

    history.push(`${url}/${id}`)
  }

  const columns = [
    // {
    //   title: "S/N",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "wareHouseContactEmail",
      key: "wareHouseContactEmail",
    },
    {
      title: "Phone Number",
      dataIndex: "warehousePhoneNumber",
      key: "warehousePhoneNumber",
    },
    {
      title: "Head of Warehouse",
      dataIndex: "headOfWarehouseName",
      key: "headOfWarehouseName",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (data, allData) => {
        // console.log(allData, 'allDate')
        const content = (
          <Space direction="vertical" size="small">
            <p onClick={handleViewDetail(allData.id)}  style={{ cursor: "pointer" }}>View Detail</p>
            <p
              onClick={() => {
                const newData = addWarehouseInput.map((item) => {
                  item.defaultValue = allData[item.name];
                  return getInput(
                    item,
                    handleSelectChange,
                    userList,
                    searchObject
                  );
                });
                setEditModalProps((prevState) => ({
                  ...prevState,
                  selectedItemInput: newData,
                  isVisible: true,
                  initialValues: allData,
                }));
              }}
              style={{ cursor: "pointer" }}
            >
              Edit
            </p>
            <p
              onClick={() =>
                setDeleteModalProps((prevState) => ({
                  ...prevState,
                  visible: true,
                  name: allData.name,
                  id: allData.id,
                }))
              }
              style={{ color: "red", cursor: "pointer" }}
            >
              Delete
            </p>
          </Space>
        );

        const handlePopoverClick = () => {};
        return (
          <Popover
            onClick={handlePopoverClick}
            content={content}
            trigger="click"
          >
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

  const handleChangePage = (event, newPage) => {
    (async () => {
      setPage(newPage);
      await dispatcher(getAllWarehouse({ page: newPage, size: rowsPerPage }));
    })();
  };

  const handleChangeRowsPerPage = (event) => {
    (async () => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      await dispatcher(
        getAllWarehouse({ page: 0, size: parseInt(event.target.value, 10) })
      );
    })();
  };

  const warehouseInputs = addWarehouseInput.map((item) =>
    getInput(item, handleSelectChange, userList, searchObject)
    // console.log(item)
  );

  const onFinish = async (values) => {
    try {
      setAddGroupModalProps((prevState) => ({ ...prevState, loading: true }));
      values.headOfWareHouseId = selectValues.headOfWareHouseId;
      values.orgId = userProfile.orgId;
      values.uuid = userProfile.uuId;
      await dispatcher(createWarehouse({ data: values }));
      await dispatcher(getAllWarehouse({}));
      setAddGroupModalProps((prevState) => ({
        ...prevState,
        isVisible: false,
        loading: false,
      }));
      setFeedbackProps({
        type: "success",
        open: true,
        message: "Warehouse has been added",
      });
    } catch (error) {
      setAddGroupModalProps((prevState) => ({ ...prevState, loading: false }));
      setFeedbackProps({
        type: "error",
        open: true,
        message: "An error occurred, please try again later",
      });
    }
  };

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackProps((prevState) => ({ ...prevState, open: false }));
  };

  const handleWarehouseDelete = async () => {
    try {
      setDeleteModalProps((prevState) => ({ ...prevState, loading: true }));
      await Services.deleteWarehouse({ id: deleteModalProps.id });
      await dispatcher(getAllWarehouse({}));
      setFeedbackProps({
        type: "success",
        open: true,
        message: "Warehouse has been Deleted",
      });
      setDeleteModalProps((prevState) => ({
        ...prevState,
        loading: false,
        visible: false,
      }));
    } catch (error) {
      setFeedbackProps({
        type: "error",
        open: true,
        message: "An error occurred, please try again",
      });
    }
  };

  const onEditFinish = async (values) => {
    try {
      setEditModalProps((prevState) => ({ ...prevState, loading: true }));
      await Services.updateWarehouse({
        data: values,
        id: editModalProps.initialValues.id,
      });
      await dispatcher(getAllWarehouse({ page, size: rowsPerPage }));
      setEditModalProps((prevState) => ({
        ...prevState,
        loading: false,
        isVisible: false,
      }));
      notificationAlert(
        "success",
        "Warehouse updated",
        `${editModalProps.initialValues.name} has been updated`
      );
    } catch (error) {
      setEditModalProps((prevState) => ({ ...prevState, loading: false }));
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  };

  return (
    <Switch>
      <Route exact path={path}>
      <ToolBar
        title="All Warehouses"
        showtools
        onCreate={() =>
          setAddGroupModalProps((prevState) => ({
            ...prevState,
            isVisible: true,
          }))
        }
        createButtonText="Add new warehouse"
      />
      <StyledCard mt={2}>
        <TableWithPagination
          rowsPerPage={rowsPerPage}
          page={page}
          total={paginationProps.totalItems}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          columns={columns}
          dataSource={warehouseList}
        />
      </StyledCard>
      <CreateWarehouseModal
        visible={addGroupModalProps.isVisible}
        onCancel={() =>
          setAddGroupModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        onFinish={onFinish}
        loading={addGroupModalProps.loading}
        warehouseInputs={warehouseInputs}
      />
      <EditWarehouseModal
        visible={editModalProps.isVisible}
        onCancel={() =>
          setEditModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        onFinish={onEditFinish}
        loading={editModalProps.loading}
        warehouseInputs={editModalProps.selectedItemInput}
        form={editWarehouseForm}
        initialValues={editModalProps.initialValues}
      />
      <Feedback
        open={feedbackProps.open}
        message={feedbackProps.message}
        type={feedbackProps.type}
        onClose={handleFeedbackClose}
      />
      <DeleteModal
        open={deleteModalProps.visible}
        loading={deleteModalProps.loading}
        itemName={deleteModalProps.name}
        onClose={() =>
          setDeleteModalProps((prevState) => ({ ...prevState, visible: false }))
        }
        onDelete={handleWarehouseDelete}
        title="Delete Warehouse"
      />
      </Route>
      <Route path={`${path}/:id`}>
        <WarehouseDetails/>
      </Route>
    </Switch>
  );
};

const CreateWarehouseModal = (props) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.submit();
  };

  return (
    <StyledModal
      title=" New Warehouse"
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={onOk}
      okText="Create"
      onCancel={props.onCancel}
    >
      <Form form={form} {...layout} onFinish={props.onFinish}>
        {props.warehouseInputs}
      </Form>
    </StyledModal>
  );
};

const EditWarehouseModal = (props) => {
  const onOk = () => {
    props.form.submit();
  };

  const cancelAndReset = () => {
    props.onCancel();
  };

  return (
    <StyledModal
      title="Edit Warehouse"
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={onOk}
      okText="Create"
      onCancel={cancelAndReset}
    >
      <Form
        initialValues={props.initialValues}
        form={props.form}
        {...layout}
        onFinish={props.onFinish}
      >
        {props.warehouseInputs}
      </Form>
    </StyledModal>
  );
};
