import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { Space, Popover, Statistic, Table, notification } from 'antd';
import { StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import { FiMoreVertical } from 'react-icons/fi';
import { AddInvoice } from './AddInvoice';
import { SingleInvoice } from './SingleInvoice';
import { useDispatch, useSelector} from 'react-redux';
import { getAllInvoice, selectInvoice } from './slice';
import { themes } from '../../globalAssets/theme';
import { AntModal } from "../../globalComponents/Modal";
import {
  StyledBoldText,
  StyledInputContainer,
} from "../../globalComponents/styles";
import { IconButton } from '@material-ui/core';
import Services from '../../../services';
import { notificationAlert } from '../../utils/notificationAlert';


export const Invoice = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {invoiceList, paginationProps} = useSelector(selectInvoice);
  console.log(invoiceList, "invoiceLis");
  
  const [itemShipmentProps, setItemShipmentProps] = useState({
    visible: false,
    items: [],
    invoiceId: null,
    shipmentStatus: '',
    loading: false
  });
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatcher = useDispatch();



  useEffect(() => {
    (async () => {
      await  dispatcher(getAllInvoice({page: 0, size: 10}));
    })()
  }, [])

const itemShipmentColumn = [
  {
    title: 'Item Name',
    dataIndex: 'itemName',
    key: 'itemName',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
]

  
const columns = [
  {
    title: 'Invoice Number',
    dataIndex: 'invoice_number',
    key: 'invoice_number',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (data) => `${data.firstName} ${data.lastName}`
    
  },
  {
    title: 'Date',
    dataIndex: 'invoice_date',
    key: 'invoice_date',
  },
  {
    title: 'Amount',
    dataIndex: 'total_amount',
    key: 'total_amount',
    render: (data) => <Statistic valueStyle={{fontSize: '1.1vw', color: themes.boldText}}  value={data} prefix='₦' />
  },
  {
    title: 'Outstanding Balance',
    dataIndex: 'amount_due',
    key: 'amount_due',
    render: (data, allData) => <Statistic valueStyle={{fontSize: '1.1vw', color: themes.boldText}}  value={data} prefix='₦' />
  },
  {
    title: 'Payment Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Shipment Status',
    dataIndex: 'shipmentStatus',
    key: 'shipmentStatus',
  },

  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '5%',
    render: (data, allData) => {
      const content = (
        <Space direction='vertical' size='small'>
          <p style={{cursor: 'pointer'}} onClick={() =>  history.push(`${url}/${allData.id}`)}>View Details</p>
          <p style={{cursor: 'pointer'}} onClick={() => {
            setItemShipmentProps(prevState => ({...prevState, items: allData.items, invoiceId: allData.id, visible: true, shipmentStatus: allData.shipmentStatus}))
          }}>Issue out items</p>
          <p style={{color: 'red', cursor: 'pointer'}}>Delete</p>
        </Space>
      )
      return (
        <Popover content={content} trigger='click'>
          <IconButton>
            <FiMoreVertical />
          </IconButton>
           
        </Popover>
      )
    },
  },
 
];


  const handleChangePage = (event, newPage) => {
    (async () => {
      setPage(newPage);
      await dispatcher(getAllInvoice({ page: newPage, size: rowsPerPage }));
    })();
  };

  const handleChangeRowsPerPage = (event) => {
    (async () => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      await dispatcher(
        getAllInvoice({ page: 0, size: parseInt(event.target.value, 10) })
      );
    })();
  };
 
  const handleItemShipment = async () => {
      try {
        setItemShipmentProps(prevState => ({...prevState, loading: true}));

        if(itemShipmentProps.shipmentStatus === 'ISSUED'){
          notificationAlert('error', 'Action denied', 'Items was previously issued'); 
          setItemShipmentProps(prevState => ({...prevState, loading: false, visible: false}));

          return;
        }
        const data = {
          invoiceId: itemShipmentProps.invoiceId,
          items: itemShipmentProps.items.map(item => ({id: item.id, quantity: item.quantity}))
        };
        const response = await Services.issueItem({data});
        await  dispatcher(getAllInvoice({page: page, size: rowsPerPage}));
       
        notificationAlert('success', 'Item Issued', 'Item has been successfully issued');
        setItemShipmentProps(prevState => ({...prevState, loading: false, visible: false}));
      } catch (error) {
        setItemShipmentProps(prevState => ({...prevState, loading: false}));
        if(error.response){
          notificationAlert('error', 'Failed', error.response.data.message ?? 'Please try again later');
        }
      }
     
  }

  return (
    <Switch>
      <Route exact path={path}>
        <ToolBar
          title="Invoice"
          showtools
          onCreate={() => history.push(`${url}/add-invoice`)}
          createButtonText="Create Invoice"
        />
        <StyledCard mt={2}>
          <TableWithPagination
            rowsPerPage={rowsPerPage}
            page={page}
            total={paginationProps.totalItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            columns={columns}
            dataSource={invoiceList}
            //onRow={handleTableRow}
          />
        </StyledCard>
        <AntModal
          visible={itemShipmentProps.visible}
          title='Issue out Items'
          okText='Confirm'
          loading={itemShipmentProps.loading}
          onOk={handleItemShipment}
          onCancel={() => setItemShipmentProps(prevState => ({...prevState, visible: false}))}
        >
            <StyledBoldText>Are sure of issuing out the following Items?</StyledBoldText>
            <Table
              columns={itemShipmentColumn}
              dataSource={itemShipmentProps.items}
              pagination={false}
            />
        </AntModal>
      </Route>
      <Route path={`${path}/add-invoice`}>
        <AddInvoice />
      </Route>
      <Route path={`${path}/:id`}>
        <SingleInvoice/>
      </Route>
    </Switch>
  );
};
