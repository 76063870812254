import React, { useState } from "react";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { ToolBar } from "../../globalComponents/ToolBar";
import { StyledCard } from "../../globalComponents/styles";
import {  
  useParams,
} from "react-router";
import { Row, Col, Statistic} from "antd";
import { SingleItemProperty } from "../Items/ItemDetails";
import { useDispatch } from "react-redux";
import { getSingleWarehouse } from "./slice";

const columns = [
    {
      title: "Item",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Item Class",
      dataIndex: "itemCategory",
      key: "itemCategory",
      render: (data) => {
        return data === "1" ? "Stock Item" : "Component Item";
      },
    },
    {
      title: "Unit Price",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
      render: (data) => <Statistic valueStyle={{fontSize: '1vw'}} value={data} prefix='₦' precision={2} />
    },
    {
      title: "Unit Cost",
      dataIndex: "lastUnitCost",
      key: "lastUnitCost",
      render: (data) => <Statistic valueStyle={{fontSize: '1vw'}} value={data} prefix='₦' precision={2} />
    },
    {
      title: "Stock on hand",
      dataIndex: "quantity",
      key: "quantity",
    },
    /* {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (data, allData) => {
        const content = (
          <Space direction="vertical" size="small">
            <p
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`${url}/${allData.id}`)}
            >
              View Detail
            </p>
            <p 
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`${url}/${allData.id}/edit`)}
            >Edit</p>
            <p
              onClick={() =>
                setDeleteModalProps({
                  name: allData.itemName,
                  id: allData.id,
                  visible: true,
                })
              }
              style={{ color: "red", cursor: "pointer" }}
            >
              Delete
            </p>
          </Space>
        );
        return (
          <Popover content={content} trigger="click">
            <FiMoreVertical />
          </Popover>
        );
      },
    }, */
  ];

const data = [
  {
    item: "Drilling Machine",
    previousQuantity: 30,
    adjustedBy: "-10",
    adjustedQuantity: 20,
  },
];
export const WarehouseDetails = (props) => {
  const { id } = useParams();
    const dispatcher = useDispatch();
    const [warehouseData, setWarehouseData] = useState([]);
    const [itemList, setItemList] = useState([])

  React.useEffect(() => {
    (async () => {
      try {
        const response = await dispatcher(getSingleWarehouse({ id }));
        // console.log(response, 'singlewarehouse')
        const {items, ...warehouseData} = response.data.data;
        const refinedWarehouseData = Object.entries(warehouseData).map(([key, value]) => {
            return ({title: key, value});
        }).filter(item => item.value )
        setWarehouseData(refinedWarehouseData);
        setItemList(items)
        
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id]);

  const secondSeg = warehouseData.map((item, index) => {
    return (
      <Col xs={12}>
        <SingleItemProperty key={index} title={item.title} value={item.value} />
      </Col>
    );
  });

  return (
    <>
      <ToolBar title="Warehouse Details" />
      <StyledCard mt={2} p={4}>
        <Row>{secondSeg}</Row>
      </StyledCard>
      <StyledCard mt={2}>
        <TableWithPagination
          columns={columns}
          dataSource={itemList}
          hidePagination={true}
        />
      </StyledCard>
    </>
  );
};
