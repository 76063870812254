import React, {useEffect, useState} from 'react';
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { Space, Popover, Form, Select } from "antd";
import { StyledCard, StyledModal } from "../../globalComponents/styles";
import { themes } from "../../globalAssets/theme";
import { getInput } from "../../Inventory/Items/AddItem";
import { selectCustomer } from "../../Clients/Customers/slice";
import {  selectInvoice } from "../../Sales/Invoice/slice";


import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import { FiMoreVertical } from 'react-icons/fi';
import { AddReceipt } from './AddReceipt'
import { ReceiptDetail } from './SingleReceipt';
import { fetchAllReceipt, selectReceipts } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

const data = [
  {
    bill_to: "",
    branch_id: "",
    invoice_amount: 0,
    invoice_date: "2022-03-17T03:20:00.610Z",
    invoice_number: "",
    notes: "",
    org_key: "",
    payment_amount: 0,
    payment_method: "",
  },
];


const addReceiptInput = [
  {
    name: "bill_to",
    label: "Customer Name",
    isRequired: true,
    type: "search",
    // defaultValue: "",
  },
  {
    name: "invoice_number",
    label: "Invoice Number",
    isRequired: false,
    type: "search",
    defaultValue: "",
  },
  {
    name: "invoice_amount",
    label: "Invoice Amount",
    isRequired: false,
    type: "number",
    defaultValue: "",
  },
  {
    name: "invoice_date",
    label: "Invoice Date",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  {
    name: "payment_amount",
    label: "Payment Amount",
    isRequired: false,
    type: "number",
    defaultValue: "",
  },
  {
    name: "payment_method",
    label: "Payment Method",
    isRequired: false,
    type: "text",
    defaultValue: "",
  },
  
  {
    name: "receipt_number",
    // label: "Receipt Number",
    // isRequired: false,
    // type: "number",
    // defaultValue: "",
  },
  // {
  //   name: "wareHouseContactEmail",
  //   label: "Warehouse Email",
  //   isRequired: false,
  //   type: "text",
  //   defaultValue: "",
  // },
  // {
  //   name: "headOfWareHouseId",
  //   label: "Head of Warehouse Name",
  //   isRequired: false,
  //   type: "search",
  // },
];


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const Receipt = (props) => {
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectValues, setSelectValues] = useState({});
  const [itemsState, setItemsState] = useState(data);

  const [addGroupModalProps, setAddGroupModalProps] = useState({
    isVisible: false,
    loading: false,
  });
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { paginationProps, allReceipts } = useSelector(selectReceipts);
  const { invoiceList } = useSelector(selectInvoice);
  const { customerList } = useSelector(selectCustomer);
  console.log({ allReceipts, customerList, invoiceList }, "allreceipcts");

  useEffect(() => {
    dispatch(fetchAllReceipt({ page: 0, size: 10 }));
  }, []);

  // useEffect(() => {
  //   const newItemsState = [...itemsState].map((itm) => {
  //     console.log(itm, 'hello2')

  //     // itm.item = invoiceList;
  //     // return itm;
  //   });
  //   console.log(newItemsState);
  //   // setItemsState(newItemsState);
  // }, [invoiceList]);

  const columns = [
    {
      title: "Reciept Number",
      dataIndex: "receiptNumber",
      key: "receiptNumber",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (data) => moment(data).format("Do, MMM YYYY"),
    },

    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Amount Paid",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
    },
    /* {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (data, allData) => {
        return (<p>{parseInt(allData.invoiceAmount) - parseInt(allData.paymentAmount)}</p>)
      }
    }, */
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (data, allData) => {
        const content = (
          <Space direction="vertical" size="small">
            <p
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`${url}/${allData.id}`)}
            >
              View Details
            </p>
            <p style={{ color: "red", cursor: "pointer" }}>Delete</p>
          </Space>
        );
        return (
          <Popover content={content} trigger="click">
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

  const handleSelectChange = (selectName) => (value) => {
    const prevSelectValues = { ...selectValues };
    prevSelectValues[selectName] = value;
    setSelectValues(prevSelectValues);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(fetchAllReceipt({ page: newPage, size: rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllReceipt({ page: 0, size: parseInt(event.target.value, 10) })
    );
  };

  //handleItemSelect
    const handleItemSelect = (invoiceId) => {
    const currentItemState = [...itemsState];
    const selectedItemObj = currentItemState;
    const selecteditem = selectedItemObj.invoiceList.find(
      (itm) => itm.id === invoiceId
    );
    selectedItemObj.invoiceId = selecteditem.id;
    selectedItemObj.bill_to = selecteditem.bill_to;
    selectedItemObj.invoice_number = selecteditem.invoice_number;
    selectedItemObj.invoice_amount = selecteditem.amount_due;
    selectedItemObj.invoice_date = selecteditem.invoice_date;
    selectedItemObj.orgId = selecteditem.orgId;

    // replaceItem(currentItemState, selectedItemObj);
  };

  // const receiptInputs34 = addReceiptInput.map((item) =>
  //   // getInput(item, handleSelectChange)
  //   console.log(item.name === "invoice_number")
  // );

  const customerOptionList = invoiceList.map((item, index) => {
    return (
      <Option value={item.id} key={index}>
        {`${item.bill_to}`}
      </Option>
    );
  });
  

  const receiptInputs = addReceiptInput.map(
    (item) =>
      getInput(item, handleSelectChange, customerOptionList, handleItemSelect)
    // console.log(item)
  );

  return (
    <Switch>
      <Route exact path={path}>
        <ToolBar
          title="Receipt"
          showtools
          // onCreate={() => history.push(`${url}/add-receipt`)}
          // createButtonText="Create Receipt"
          onCreate={() =>
            setAddGroupModalProps((prevState) => ({
              ...prevState,
              isVisible: true,
            }))
          }
          createButtonText="Create Receipt"
        />
        <StyledCard mt={2}>
          <TableWithPagination
            rowsPerPage={rowsPerPage}
            page={page}
            total={paginationProps.totalItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            columns={columns}
            dataSource={allReceipts}
          />
        </StyledCard>
        <CreateReceiptModal
          visible={addGroupModalProps.isVisible}
          onCancel={() =>
            setAddGroupModalProps((prevState) => ({
              ...prevState,
              isVisible: false,
            }))
          }
          //  onFinish={onFinish}
          loading={addGroupModalProps.loading}
          receiptInputs={receiptInputs}
        />
        ;
      </Route>
      {/* <Route path={`${path}/add-receipt`}>
        <AddReceipt />
      </Route> */}
      <Route path={`${path}/:id`}>
        <ReceiptDetail />
      </Route>
    </Switch>
  );
};

const CreateReceiptModal = (props) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.submit();
  };

  return (
    <StyledModal
      title=" New Receipt"
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={onOk}
      okText="Create"
      onCancel={props.onCancel}
    >
      <Form form={form} {...layout} onFinish={props.onFinish}>
        {props.receiptInputs}
      </Form>
    </StyledModal>
  );
};