import { createGlobalStyle } from 'styled-components';
import { createTheme  } from '@material-ui/core';


export const  themes = {
    white: '#fff',
    primaryColor: '#1A88E1',
    primaryColor2: '#1702FF',
    primaryColor3: '#177ACA',
    background: '#F0F3FA',
    foreground: '#FFFFFF',
    subtleBlack: '#B4B4B4',
    lightText: '#5E5D5D',
    boldText: '#242424',
    headerText: '#000000',
    shadow: "0px 3px 10px #0000001A",
    filter: "drop-shadow(0px 4px 20px rgba(201, 201, 201, 0.25))",
    border: '#C4C4C4',
    highlight: "#F5F5F5",
    faintBlue: '#F0F3FA',
    red: '#F44336',
    grey: '#A0AEC0',
}

export const lightTheme = createTheme({
    white: '#fff',
    primaryColor: '#1A88E1',
    primaryColor2: '#1702FF',
    primaryColor3: '#177ACA',
    background: '#F0F3FA',
    foreground: '#FFFFFF',
    subtleBlack: '#B4B4B4',
    lightText: '#5E5D5D',
    boldText: '#242424',
    headerText: '#000000',
    shadow: "0px 3px 10px #0000001A",
    filter: "drop-shadow(0px 4px 20px rgba(201, 201, 201, 0.25))",
    border: '#C4C4C4',
    highlight: "#F5F5F5",
    faintBlue: '#F0F3FA',
    red: '#F44336',
    grey: '#A0AEC0',
})


export const darkTheme = createTheme({
    white: '#fff',
    background: '#18191A',
    foreground: '#242526',
    primaryColor: '#1A88E1',
    primaryColor2: '#1702FF',
    primaryColor3: '#177ACA',
    border: '#C5C5C5',
    subtleBlack: '#fff',
    lightText: '#fff',
    boldText: '#fff',
    shadow: "none",
    filter: "none",
    headerText: '#fff',
    highlight: "#363838",
    faintBlue: '#363838',
    red: '#F44336',
    grey: '#A0AEC0',
   
})

export const GlobalStyle = createGlobalStyle `
    body{
        background: ${props => props.theme.background};
        color: ${props => props.theme.boldText};
        transition: all 0.5s linear;
    }

    p{
        margin-bottom: 0px;
        color: ${props => props.theme.boldText};
    }
`