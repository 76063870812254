import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { Menu, Statistic} from "antd";
import {
  StyledCard,
  StyledModal,
  StyledNormalText,
  StyledTable,
} from "../../globalComponents/styles";
import { useRouteMatch, Route, Switch, useHistory } from "react-router";
import {
  StyledBoldText,
  StyledInputContainer,
} from "../../globalComponents/styles";
import { Box, Divider, Grid, makeStyles } from "@material-ui/core";
import { Input, Select, Col, Row, Form, Popover } from "antd";
import {
  PrimaryButton,
  TransaparentButton,
} from "../../globalComponents/Buttons";
import { FiTrash2, FiMail, FiSend } from "react-icons/fi";
import { getInput } from "../../Inventory/Items/AddItem";
import { themes } from "../../globalAssets/theme";
import { AntFormModal } from "../../globalComponents/Modal";
import { BiPrinter, BiHistory } from "react-icons/bi";
import { IoMdRefresh } from "react-icons/io";
import { FaRegFilePdf } from "react-icons/fa";
import companyLogo from "../../images/companyLogo.jpeg";
import { AntModal } from "../../globalComponents/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getInvoice, selectInvoice, getAllInvoice} from './slice';
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectItems } from '../../Inventory/Items/slice';
import { OtherChargesComponent, TotalComponent} from './AddInvoice';
import Services from "../../../services";
import { notificationAlert } from "../../utils/notificationAlert";
import moment from 'moment';
import { file } from './file';


const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const useStyles = makeStyles((theme) => ({
  icons: {
    fontSize: "1.4vw",
    color: theme.grey,
    marginRight: "15px",
    "&:hover": {
      color: theme.primaryColor,
      cursor: "pointer",
    },
  },

  historyIcon: {
    color: theme.primaryColor,
    fontSize: "1.4vw",
    marginRight: "10px",
  },
  arroweft: {
    width: 0,
    height: 0,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",

    borderRight: `20px solid ${theme.foreground}`,
  },
  logo: {
    width: "15%",
    height: "auto",
  },
  totalBackground: {
    backgroundColor: theme.background,
  },
}));

const data = [
  {
    item: "Hp laptops",
    description: "Lorem ipsum dolor",
    quantity: "2",
    rate: "$600",
    amount: "$600",
  },
];

const sendInvoiceFields = [
  {
    name: "from",
    label: "From",
    isRequired: false,
    type: "text",
  },
  {
    name: "to",
    label: "To",
    isRequired: false,
    type: "text",
  },
  {
    name: "cc",
    label: "Cc",
    isRequired: false,
    type: "text",
  },
  {
    name: "subject",
    label: "Subject",
    isRequired: false,
    type: "text",
  },
];

const paymentFields = [
  {
    name: "customer_name",
    label: "Customer",
    isRequired: false,
    type: "text",
    defaultValue: '',
    disabled: true
  },
  {
    name: "receipt_number",
    label: "Receipt Number",
    isRequired: false,
    type: "text",
    disabled: true
  },
  {
    name: "amount_received",
    label: "Amount received(NGN)",
    isRequired: true,
    type: "number",
  },
  {
    name: "bank_changes",
    label: "Bank Charges",
    isRequired: false,
    type: "number",
  },
/*   {
    name: "paymentDate",
    label: "Payment date",
    isRequired: false,
    type: "date",
  }, */
  {
    name: "account_to_deposit",
    label: "Account to Deposit",
    isRequired: false,
    type: "select",
  },
  {
    name: "payment_method",
    label: "Payment Method",
    isRequired: false,
    type: "select",
  },
  {
    name: "notes",
    label: "Notes",
    isRequired: false,
    type: "textarea",
  },
];

const paymentMethods = [
  "DEFAULT",
  "CASH",
  "CHECK",
  "BANK_TRANSFER",
  "CREDIT_DEBIT_CARD",
  "INTERNET_BANKING"
];

const accountDeposit = ["Cash on hand", "Cash in bank"];

export const SingleInvoice = (props) => {
  const classes = useStyles();
  const [paymentForm] = Form.useForm();
  const [paymentInfo, setPaymentInfo] = useState({})
  const [paymentFieldsState, setPaymentFields] = useState(paymentFields)
  const [sendInvoiceModalProps, setSendInvoiceModalProps] = useState({
    isVisible: false,
    loading: false,
  });
  const [paymentModalProps, setPaymentModalProps] = useState({
    isVisible: false,
    loading: false,
  });
  const [selectValues, setSelectValues] = useState({});

  const { singleInvoice } = useSelector(selectInvoice);
  const { id } = useParams();
  const dispatcher = useDispatch();
  const { itemList } = useSelector(selectItems);

  

  useEffect(() => {
    (async () => {
      try {

        const invoiceInfo = await dispatcher(getInvoice({id}));
        const {data: {data: paymentData}}  = await Services.getPaymentInfo({id});
        

     
          const initValues = {
            customer_name: `${paymentData.customer.firstName} ${paymentData.customer.lastName}`,
            receipt_number: paymentData.receipt_number
          }
    
          const paymentFieldsDefault = [...paymentFieldsState].map(item => {
            item.defaultValue = initValues[item.name];
            return item
          })
    
          setPaymentFields(paymentFieldsDefault);
    
         /*  paymentForm.setFieldsValue({
            customer_name: `${paymentData.customer.firstName} ${paymentData.customer.lastName}`,
            receipt_number: paymentData.receipt_number
          }) */

        setPaymentInfo(paymentData);
      } catch (error) {
        console.log(error);
      }
    })()
  }, [])

/* 
  useEffect(() => {
    if(Object.entries(paymentInfo).length > 0 && paymentModalProps.isVisible){
      const initValues = {
        customer_name: `${paymentInfo.customer.firstName} ${paymentInfo.customer.lastName}`,
        receipt_number: paymentInfo.receipt_number
      }

      const paymentFieldsDefault = [...paymentFieldsState].map(item => {
        item.defaultValue = initValues[item.name];
        return item
      })

      setPaymentFields(paymentFieldsDefault);

      paymentForm.setFieldsValue({
        customer_name: `${paymentInfo.customer.firstName} ${paymentInfo.customer.lastName}`,
        receipt_number: paymentInfo.receipt_number
      })
    }
    
  }, [paymentInfo, paymentModalProps.isVisible]) */


  
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (data, allData) => parseInt(allData?.quantity) * parseInt(allData?.price)
  },
];


  const sendInvoiceInput = [...sendInvoiceFields].map((item) => {
    return getInput(item);
  });

  const paymentMethodList = paymentMethods.map((item) => {
    return (
      <Option value={item} key={item}>
        {item}
      </Option>
    );
  });

  const accountDepositList = accountDeposit.map((item) => {
    return (
      <Option value={item} key={item}>
        {item}
      </Option>
    );
  });

  
  const handleSelectChange = (selectName) => (value) => {
    const prevSelectValues = { ...selectValues };
    prevSelectValues[selectName] = value;
    setSelectValues(prevSelectValues);
  };

  const paymentInput = [...paymentFieldsState].map((item) => {
    if (item.name === "payment_method") {
      return (
        <Col xs={11}>
          {getInput(item, handleSelectChange, paymentMethodList)}
        </Col>
      );
    }

    if (item.name === "account_to_deposit") {
      return (
        <Col xs={12}>
          {getInput(item, handleSelectChange, accountDepositList)}
        </Col>
      );
    }
    return <Col xs={12}>{getInput(item, handleSelectChange)}</Col>;
  });

  const openPaymentRecordModal = async() => {
    setPaymentModalProps((prevState) => ({ ...prevState, isVisible: true }));


    /* if(Object.entries(paymentInfo).length > 0){
      const initValues = {
        customer_name: `${paymentInfo.customer.firstName} ${paymentInfo.customer.lastName}`,
        receipt_number: paymentInfo.receipt_number
      }

      const paymentFieldsDefault = [...paymentFieldsState].map(item => {
        item.defaultValue = initValues[item.name];
        return item
      })

      setPaymentFields(paymentFieldsDefault);

      paymentForm.setFieldsValue({
        customer_name: `${paymentInfo.customer.firstName} ${paymentInfo.customer.lastName}`,
        receipt_number: paymentInfo.receipt_number
      })
    } */
  };

  const openSendInvoiceModal = () => {
    setSendInvoiceModalProps((prevState) => ({
      ...prevState,
      isVisible: true,
    }));
  };

  const handleToolClick = (name) => {
    switch (name) {
      case "recordPayment":
        openPaymentRecordModal();
        break;
      case "send":
        openSendInvoiceModal();
        break;
      default:
        return;
    }
  };

  const otherCharges = singleInvoice?.charges?.map((item, index) => {
    return <OtherChargesComponent data={item} key={index}/>
  });

  const onPaymentMade = async (values) => {
    try {
      values.payment_method = selectValues.payment_method;
      values.account_to_deposit = selectValues.account_to_deposit;
      values.receipt_id =  paymentInfo.receipt_id;
      values.receipt_number = paymentInfo.receipt_number;
      values.customer_name = `${paymentInfo.customer.firstName} ${paymentInfo.customer.lastName}`;
      values.payment_date = moment().format('YYYY-MM-DD');
      values.org_key = "";
      /* values.file = file; */

     
      //values.amount_to_deposit = "";
      delete values.bank_changes;

       setPaymentModalProps(prevState => ({...prevState, loading: true}));
      await Services.makePayment({data: values, id: singleInvoice.id})
      await  dispatcher(getAllInvoice({page: 0, size: 10}));
      setPaymentModalProps(prevState => ({...prevState, loading: false, isVisible: false}));
      notificationAlert('success', 'Payment Successful', `Payment was made`)
    } catch (error) {
      console.log(error)
      setPaymentModalProps(prevState => ({...prevState, loading: false}));

      if(error.response){
        notificationAlert('error', 'Payment Failed', error.response.data.message || 'Please try again');
      }else {
        notificationAlert('error', 'Payment Failed', 'Please try again');
      }
    }
   

  }


  return (
    <>
      <ToolBar onClick={handleToolClick} />
      <HistoryBar />
      <StyledCard mt={2}>
        <Box
          display="flex"
          alignItems="center"
          style={{ backgroundColor: "#A3B1BC", width: "fit-content" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={1}
            px={6}
          >
            <StyledBoldText style={{ color: themes.white }} variant="body2">
              Unsent
            </StyledBoldText>
          </Box>
          <Box className={classes.arroweft}></Box>
        </Box>
        <Box p={3}>
          <img className={classes.logo} src={companyLogo} alt="company logo" />
          <Box mt={5}>
            <StyledBoldText variant="h4">Invoice</StyledBoldText>
          </Box>
          <Box
            mt={3}
            pb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flexBasis="30%">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledBoldText
                  style={{ fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  Invoice number:
                </StyledBoldText>
                <StyledBoldText
                  align="left"
                  style={{ fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  {singleInvoice?.invoice_number}
                </StyledBoldText>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledBoldText
                  style={{ fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  Invoice Date :
                </StyledBoldText>
                <StyledNormalText align="left" variant="subtitle1">
                  8th Aug 2019
                </StyledNormalText>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledBoldText
                  style={{ fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  Due Date :
                </StyledBoldText>
                <StyledNormalText align="left" variant="subtitle1">
                  {singleInvoice?.invoice_date}
                </StyledNormalText>
              </Box>
            </Box>
            <Box>
              <StyledBoldText
                style={{ fontWeight: "bold" }}
                variant="subtitle1"
              >
                Bill To
              </StyledBoldText>
              <StyledNormalText align="left" variant="subtitle1">
                {`${singleInvoice?.customer.firstName} ${singleInvoice?.customer.lastName}`}
              </StyledNormalText>
             {/*  <StyledNormalText align="left" variant="subtitle1">
                Holder’s Limited
              </StyledNormalText> */}
              <StyledNormalText align="left" variant="subtitle1">
              {singleInvoice?.customer.address}
              </StyledNormalText>
            </Box>
          </Box>
          <Divider />
          <Box py={2}>
            <StyledBoldText>Notes</StyledBoldText>
            <StyledNormalText>
            {singleInvoice?.notes}
            </StyledNormalText>
          </Box>
          <Divider />
          <Box mt={3}>
            <StyledTable
              columns={columns}
              dataSource={singleInvoice?.items}
              pagination={false}
            />
          </Box>
          <Divider />
          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {/* <StyledBoldText>Attachment</StyledBoldText> */}
              <TransaparentButton text="attach a file" />
            </Box>
            <Box flexBasis="40%">
              { otherCharges }
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                mt={2}
              >
                <StyledBoldText>Total</StyledBoldText>
                <Statistic valueStyle={{fontSize: '1.2vw', color: themes.boldText}}  value={singleInvoice?.total_amount} prefix='₦' />
               {/*  <StyledBoldText>{singleInvoice?.total_amount}</StyledBoldText> */}
              </Box>
              {/* <TotalComponent title='Total' amount={singleInvoice?.total_amount}/> */}
              {/* <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                className={classes.totalBackground}
                p={2}
              >
                <StyledBoldText>Amount Due</StyledBoldText>
                <StyledBoldText>$600</StyledBoldText>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </StyledCard>
      <AntModal
        title="Send Invoice"
        visible={sendInvoiceModalProps.isVisible}
        onCancel={() =>
          setSendInvoiceModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
      >
        <Form
          colon={false}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
        >
          {sendInvoiceInput}
        </Form>
        <Box mt={3}>
          <StyledBoldText gutterBottom={true} variant="body2" align="left">
            Message
          </StyledBoldText>
          <CKEditor
            editor={ClassicEditor}
            data="<p>Write Message</p>"
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              
            }}
            onBlur={(event, editor) => {
              
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />
        </Box>
      </AntModal>
      <AntFormModal
        title={singleInvoice.invoice_number}
        visible={paymentModalProps.isVisible}
        form={paymentForm}
        initialValues={{customer: 'Adefisayo'}}
        onCancel={() =>{
          paymentForm.resetFields();

          setPaymentModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        }
        onFinish={onPaymentMade}
      >
        <Row gutter={16}>{paymentInput}</Row>
      </AntFormModal>
    </>
  );
};

const SingleHistory = (props) => {
  return (
    <StyledSingleHistory pl={4} pr={2} py={2} mb={3}>
      <StyledBoldText variant="body2">
        Payment of N 300,000.00 using cash received by Essien Joy
      </StyledBoldText>
      <Box display="flex" alignItems="center">
        <StyledNormalText variant="overline">3rd Jul 2019</StyledNormalText>
        <Box ml={2}>
          <StyledNormalText variant="overline">3:00pm</StyledNormalText>
        </Box>
      </Box>
    </StyledSingleHistory>
  );
};

export const HistoryBar = (props) => {
  const classes = useStyles();
  const content = (
    <Box
      px={6}
      py={2}
      style={{ minWidth: "50vw", maxHeight: "50vh", overflow: "auto" }}
    >
      <SingleHistory />
      <SingleHistory />
      <SingleHistory />
      <SingleHistory />
      <SingleHistory />
      <SingleHistory />
      <SingleHistory />
    </Box>
  );

  return (
    <StyledCard mt={2} py={3} px={3}>
      <Popover placement="bottomLeft" content={content} trigger="click">
        <Box
          style={{ cursor: "pointer" }}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <BiHistory className={classes.historyIcon} />
          <StyledBoldText
            style={{ color: themes.primaryColor, fontWeight: "bold" }}
            variant="body2"
          >
            Activity ( Comments and History)
          </StyledBoldText>
        </Box>
      </Popover>
    </StyledCard>
  );
};

export const ToolBar = (props) => {
  const classes = useStyles();
  return (
    <StyledCard
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      py={3}
      px={3}
    >
      <FaRegFilePdf className={classes.icons} />
      <IoMdRefresh className={classes.icons} />
      <BiPrinter className={classes.icons} />
      <FiMail className={classes.icons} />
      <FiTrash2 className={classes.icons} />
      {/* <FiSend className={classes.icons} onClick={() => props.onClick("send")} /> */}
      {!props.hidePaymentButton && <TransaparentButton
        style={{ textTransform: "capitalize " }}
        text="Record a Payment"
        onClick={() => props.onClick("recordPayment")}
      />}
    </StyledCard>
  );
};

const StyledSingleHistory = styled(Box)`
  border: 1px solid ${themes.border};
  width: 100%;
  border-radius: 0px 20px 20px 20px;
`;
