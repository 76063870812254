import { createSlice } from '@reduxjs/toolkit';
import Services from '../../../services';


const invoiceSlice = createSlice({
    name: 'invoiceSlice',
    initialState: {
        invoiceList: [],
        singleInvoice: { customer: {}, charges:[]},
        paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
    },
    reducers: {
        addSingleInvoice: (state, {payload}) => {
            state.singleInvoice = payload;
        },
        addInvoiceList: (state, {payload}) => {
            state.invoiceList = payload;
        },
        addPaginationProps: (state, { payload }) => {
            state.paginationProps = payload;
        },
    }
});


export const { addSingleInvoice, addInvoiceList, addPaginationProps} = invoiceSlice.actions;


export const getAllInvoice = payload => dispatcher => {
    return Services.getAllInvoice(payload).then(
        response => {
            const {currentPage: page, totalPages, totalItems, invoices} = response.data.data;
            dispatcher(addPaginationProps({page, totalPages, totalItems}));
            dispatcher(addInvoiceList(invoices));
            
            return Promise.resolve(response.data.data);
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}


export const getInvoice = payload => dispatcher => {
    return Services.getInvoice(payload).then(
        response => {
            
            dispatcher(addSingleInvoice(response.data.data));
            return Promise.resolve(response.data.data);
        }
    ).catch(error => {
        if(error.response){
            return Promise.reject(error.response.data);
        }
    })
}




export const selectInvoice = state =>  state.invoiceReducer;

export default invoiceSlice.reducer;