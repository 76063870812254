import React, { useState,  useCallback } from "react";
import styled from "styled-components";
import { ToolBar } from "../../globalComponents/ToolBar";
import { Form, Input, Select, Row, Col, Space, DatePicker } from "antd";
import { StyledCard } from "../../globalComponents/styles";
import {  useHistory } from "react-router";
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  withStyles,
  Collapse,
} from "@material-ui/core";

import {
  PrimaryButton,
  TransaparentButton,
} from "../../globalComponents/Buttons";
import {
  StyledInputContainer,
  StyleLabel,

  StyledNormalText,
} from "../../globalComponents/styles";
import {
  StyledImagePreview,
  StyledImageDrop,
  StyledMutedText,
  StyledBottomImage,
} from "../styles";
import { FiCamera, FiPlus } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import { getBase64 } from "../../utils/getBase64";
import {  selectWarehouse } from "../Warehouse/slice";
import { useSelector, useDispatch } from "react-redux";
import { createItem,  fetchAllItems } from "./slice";
import { selectItemGroups } from "../ItemGroups/slice";
import { Feedback } from "../../globalComponents/Feedback";
import { selectDashboard } from "../../Dashboard/slice";
import { convertStringToNumber } from "../../utils/convertStringToNumber";
import { selectVendor } from '../../Clients/Vendors/slice';
const { TextArea } = Input;
const { Option } = Select;

const CheckboxWithStyle = withStyles((theme) => ({
  root: {
    color: theme.boldText,
    "&$checked": {
      color: theme.boldText,
    },
  },
}))((props) => <Checkbox color="default" {...props} />);

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formFields1 = [
  { name: "itemName", label: "Item Name", isRequired: true, type: "text" },
  { name: "sku", label: "SKU", isRequired: false, type: "text" },
  { name: "itemGroup", label: "Item Group", isRequired: false, type: "select" },
  { name: "itemType", label: "Item Type", isRequired: false, type: "select" },
  {
    name: "itemCategory",
    label: "Item Category",
    isRequired: true,
    type: "select",
  },
  {
    name: "description",
    label: "Description",
    isRequired: false,
    type: "textarea",
  },
];

const formFields2 = [
  {
    name: "partNumber",
    label: "Part Number",
    isRequired: false,
    type: "number",
  },
  {
    name: "sellingPrice",
    label: "Selling Price",
    isRequired: true,
    type: "number",
  },
  { name: "costPrice", label: "Cost Price", isRequired: false, type: "number" },
  {
    name: "unit",
    label: "Unit",
    isRequired: true,
    type: "select",
  },
  {
    name: "itemDimension",
    label: "Dimension",
    isRequired: false,
    type: "text",
  },
  {
    name: "lastUnitCost",
    label: "Last Unit Cost",
    isRequired: false,
    type: "number",
  },
  { name: "itemWeight", label: "Weight", isRequired: false, type: "number" },
];

const warehouseFields = [
  { name: "warehouse1", label: "Warehouse", isRequired: true, type: "select" },
  {
    name: "openStock1",
    label: "Opening Stock",
    isRequired: true,
    type: "number",
  },
];

const sales = [
  {
    name: "salesAccountId",
    label: "Sales Account",
    isRequired: false,
    type: "select",
  },
];

const purchase = [
  {
    name: "purchaseAccountId",
    label: "Purchase Account",
    isRequired: false,
    type: "select",
  },
];

const inventoryAcct = [
  {
    name: "inventoryAccountId",
    label: "Inventory Account",
    isRequired: false,
    type: "number",
  },
];

const taxAcct = [
  {
    name: "taxAccountId",
    label: "Tax Account",
    isRequired: false,
    type: "number",
  },
];

const reorder = [
  {
    name: "reOrderPoint",
    label: "Reorder point",
    isRequired: false,
    type: "number",
  },
  {
    name: "reOrderQuantity",
    label: "Reorder Quantity",
    isRequired: false,
    type: "number",
  },
  {
    name: "maxInventory",
    label: "Maximum Inventory",
    isRequired: false,
    type: "number",
  },
];

const vendor = [
  {
    name: "vendorId",
    label: "Preferred Vendor",
    isRequired: false,
    type: "select",
  },
];



const missingParams = {
  accountId: 5,
  wareHouseId: 2,
  barcode: "sdfsdf",
  manufacturer: "jqjdijqefok",
  trashed: false,
  fileFormat: "ijqwpokwep",
  fileUrl: "sadfaijosdf",
  quantity: 432,
  accountingNumber: 4232342,
  /* vendorId: 2, */
};

//item Category
const itemCategoryEnum = [
  { value: "STOCK_ITEM", name: "Stock Item" },
  { value: "COMPONENT_ITEM", name: "Component Item" },
];

//item Type
const itemTypeEnum = [
  { value: "GOODS", name: "Goods" },
  { value: "SERVICE", name: "Service" },
];

const units = ["Pieces", "Kg", "Dozen", "Packs"];

export const AddItem = (props) => {
  const [warehouseState, setWarehouseState] = useState(warehouseFields);
  const [openAccounting, setOpenAccounting] = useState(false);
  const [selectValues, setSelectValues] = useState({});
  const [imageArr, setImageArr] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [feedbackProps, setFeedbackProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  //useSelector
  const { warehouseList } = useSelector(selectWarehouse);
  const { itemGroupList } = useSelector(selectItemGroups);
  const { vendorList } = useSelector(selectVendor);
  const { userProfile } = useSelector(selectDashboard);

  const [form] = Form.useForm();
  const history = useHistory();
  const dispatcher = useDispatch();

  //
  // console.log(warehouseList, 'warehouseList');
  // console.log(itemGroupList, 'itemGroupList');
  // console.log(vendorList, "vendorList");
    //vendor list
  const vendorListOption = vendorList.map((item, index) => {
    return (
      <Option key={index} value={item.id}>
        {`${item.firstName} ${item.lastName}`}
      </Option>
    );
  });
  //warehouse list
  const warehouseOptions = warehouseList.map((item, index) => {
    return (
      <Option key={index} value={item.id}>
        {item.name ?? `item${index}`}
      </Option>
    );
  });
    // Item category
  const itemCategoryOptions = itemCategoryEnum.map((item, index) => {
    return (
      <Option value={item.value} key={index}>
        {item.name}
      </Option>
    );
  });

  //Item Type
  const itemTypeOptions = itemTypeEnum.map((item, index) => {
    return (
      <Option value={item.value} key={index}>
        {item.name}
      </Option>
    );
  });

  // Item Group(useSelector)
  const itemGroupOption = itemGroupList.map((item, index) => {
    return (
      <Option key={index} value={item.id}>
        {item.groupName}
      </Option>
    );
  });

  const unitsOption = units.map((item, index) => {
    return (
      <Option value={item} key={index}>
        {item}
      </Option>
    );
  });

  const handleSelectChange = (selectName) => (value) => {
    const prevSelectValues = { ...selectValues };
    prevSelectValues[selectName] = value;
    setSelectValues(prevSelectValues);
  };

  const firstSection = formFields1.map((item, index) => {
    if (item.name === "itemCategory") {
      return getInput(item, handleSelectChange, itemCategoryOptions);
    }

    if (item.name === "itemType") {
      return getInput(item, handleSelectChange, itemTypeOptions);
    }

    if (item.name === "itemGroup") {
      return getInput(item, handleSelectChange, itemGroupOption);
    }

    return getInput(item);
  });

  const secondSection = formFields2.map((item, index) => {
    return <Col sm={12}>{getInput(item, handleSelectChange, unitsOption)}</Col>;
  });

  const warehouseStateList = [...warehouseState].map((item) => {
    return (
      <Col sm={12}>{getInput(item, handleSelectChange, warehouseOptions)}</Col>
    );
  });

  const salesAccountList = sales.map((item) =>
    getInput(item, handleSelectChange)
  );

  const purchaseAccountList = purchase.map((item) =>
    getInput(item, handleSelectChange)
  );

  const inventoryAccountList = inventoryAcct.map((item) =>
    getInput(item, handleSelectChange)
  );

  const taxAccountList = taxAcct.map((item) =>
    getInput(item, handleSelectChange)
  );

  const reorderList = reorder.map((item) => {
    return <Col xs={8}>{getInput(item, handleSelectChange)}</Col>;
  });

  const vendorInputList = vendor.map((item) => getInput(item, handleSelectChange, vendorListOption));

  const handleAddWarehouse = () => {
    const prevWarehouse = [...warehouseState];
    const newWarehouse = [
      {
        name: `warehouse1${prevWarehouse.length}`,
        label: "Warehouse",
        isRequired: false,
        type: "select",
      },
      {
        name: `openStock1${prevWarehouse.length}`,
        label: "Opening Stock",
        isRequired: false,
        type: "number",
      },
    ];

    setWarehouseState((prevState) => [...prevState, ...newWarehouse]);
  };

  const handleOpenAccounting = (e) => {
    setOpenAccounting(e.target.checked);
  };

  //come back to this for debug images
  const onFinish = async (values) => {
    try {
      setButtonLoading(true);

      const selectInputValues = { ...selectValues };

      const formattedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => {
          value = selectInputValues[key] ?? value;
          return [key, value];
        })
      );

      const itemStocks = Object.entries(formattedValues).filter(
        ([key, value]) => key.startsWith("openStock")
      );

      const itemStockWithWarehouse = Object.entries(formattedValues)
        .filter(([key, value]) => key.startsWith("warehouse"))
        .map(([warehouseKey, warehouseValue], index) => {
          const [stockKey, stockValue] = itemStocks[index];
          return { warehouseID: warehouseValue, openingStock: stockValue };
        });

      let valuesWithoutItemStockAndWarehouse = Object.fromEntries(
        Object.entries(formattedValues).filter(
          ([key, value]) =>
            !key.startsWith("openStock") && !key.startsWith("warehouse")
        )
      );

      valuesWithoutItemStockAndWarehouse.wareHouseRequest =
        itemStockWithWarehouse;
      const refineImage = [...imageArr].map((item) => ({ productImage: item }));

      valuesWithoutItemStockAndWarehouse.imageList = refineImage;
      valuesWithoutItemStockAndWarehouse.orgId =
        userProfile?.organisation?.orgId;
      valuesWithoutItemStockAndWarehouse = Object.fromEntries(
        Object.entries(valuesWithoutItemStockAndWarehouse).filter(
          ([key, value]) => value !== undefined
        )
      );
      valuesWithoutItemStockAndWarehouse.purchaseAccountId = 34;
      valuesWithoutItemStockAndWarehouse.salesAccountId = 54;
      const refinedValues = convertStringToNumber(
        valuesWithoutItemStockAndWarehouse
      );
        // console.log({values}, 'values');
      await dispatcher(
        createItem({ data: { ...refinedValues, ...missingParams, values } })
      );
      await dispatcher(fetchAllItems({}));
      setButtonLoading(false);
      setFeedbackProps((prevState) => ({
        ...prevState,
        message: "Item has been created",
        open: true,
        type: "success",
      }));
      history.push("/items");
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
      setFeedbackProps((prevState) => ({
        ...prevState,
        message: error?.message ?? "Error Occurred, please try again later",
        open: true,
        type: "error",
      }));
    }
  };

  const getImage = (imageBase64Arr) => {
    setImageArr(imageBase64Arr);
  };

  return (
    <>
      <ToolBar title="Add Item" />
      <Form onFinish={onFinish} {...layout} form={form}>
        <StyledCard mt={2} py={3} px={4} display="flex">
          <Grid item xs={6}>
            <ItemImageComponent getImage={getImage} imageArr={imageArr} />
          </Grid>
          <Grid item xs={6}>
            <Box px={3}>{firstSection}</Box>
          </Grid>
        </StyledCard>
        <StyledCard mt={2} py={3} px={4} display="flex">
          <Grid item xs={12}>
            <Box px={3}>
              <Row gutter={16}>{secondSection}</Row>
            </Box>
          </Grid>
        </StyledCard>
        <StyledCard mt={2} py={3} px={4} display="flex">
          <Grid item xs={12}>
            <Box px={3}>
              <Typography variant="subtitle1">Warehouse</Typography>
            </Box>
            <Box px={3} mt={2}>
              <Row gutter={16}>{warehouseStateList}</Row>
            </Box>
            <Box px={3} mt={2}>
              <PrimaryButton
                text="Add Warehouse"
                onClick={handleAddWarehouse}
              />
            </Box>
          </Grid>
        </StyledCard>
        <StyledCard mb={2} mt={2} py={3} px={4} display="flex">
          <Grid item xs={12}>
            <Box display="flex" px={2} alignItems="center">
              <CheckboxWithStyle onChange={handleOpenAccounting} />
              <Typography variant="subtitle1">Inventory Accounting</Typography>
            </Box>
            <Collapse in={openAccounting}>
              <Box px={3} mt={6}>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Box mb={6}>
                      <Box mb={2}>
                        <Typography gutterBottom={true} variant="subtitle2">
                          {" "}
                          Sales{" "}
                        </Typography>
                      </Box>
                      {salesAccountList}
                    </Box>
                    <Box>
                      <Box mb={2}>
                        <Typography gutterBottom={true} variant="subtitle2">
                          {" "}
                          Inventory{" "}
                        </Typography>
                      </Box>
                      {inventoryAccountList}
                    </Box>
                  </Col>
                  <Col xs={12} pt="2">
                    <Box mb={6}>
                      <Box mb={2}>
                        <Typography gutterBottom={true} variant="subtitle2">
                          {" "}
                          Purchase{" "}
                        </Typography>
                      </Box>
                      {purchaseAccountList}
                    </Box>
                    <Box mb={2}>
                      <Typography gutterBottom={true} variant="subtitle2">
                        {" "}
                        Tax{" "}
                      </Typography>
                    </Box>
                    {taxAccountList}
                  </Col>
                </Row>
              </Box>
            </Collapse>
          </Grid>
        </StyledCard>
        <StyledCard>
          <Box pt={4} px={6}>
            <Typography variant="subtitle1">
              Reorder point Parameters
            </Typography>
          </Box>
          <Box px={6}>
            <Row gutter={16}>{reorderList}</Row>
          </Box>
        </StyledCard>
        <StyledCard>
          <Grid xs={6}>
            <Box pt={4} px={6} mt={2}>
              {vendorInputList}
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box pb={6} mt={6} display="flex" justifyContent="center">
              <TransaparentButton
                style={{ width: "30%", marginRight: "20px" }}
                text="Cancel"
              />
              <PrimaryButton
                style={{ width: "30%" }}
                text="Save"
                type="submit"
                loading={buttonLoading}
              />
            </Box>
          </Grid>
        </StyledCard>
      </Form>
      <Feedback
        open={feedbackProps.open}
        message={feedbackProps.message}
        type={feedbackProps.type}
        onClose={() =>
          setFeedbackProps((prevState) => ({ ...prevState, open: false }))
        }
      />
    </>
  );
};

export const ItemImageComponent = (props) => {
  const [activeImage, setActiveImage] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    let newImages = [];
    acceptedFiles.slice(0, 3).forEach(async (file, index) => {
      const response = await getBase64(file).catch((e) => console.log(e));
      newImages = [...newImages, response];

      if (newImages.length === acceptedFiles.slice(0, 3).length) {
        props.getImage(newImages);
        /* setImagesState(newImages); */
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const imageList =  [...props.imageArr].map((item, index) => {
    return (
      <StyledBottomImage
        key={index}
        onClick={() => setActiveImage(index)}
        isActive={activeImage === index}
      >
        <img src={props.imageArr[index]} alt="" />
      </StyledBottomImage>
    );
  });

  return (
    <>
      <Box px={3} pt={2}>
        <StyledImageDrop isImage={props.imageArr.length} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the image(s) here ...</p>
          ) : !props.imageArr.length ? (
            <>
              <FiCamera />
              <StyledMutedText variant="body1">
                Drag image to upload
              </StyledMutedText>
              <Box mt={2}>
                <PrimaryButton text="Choose Image" />
              </Box>
            </>
          ) : (
            <StyledImagePreview>
              <img src={props.imageArr[activeImage]} alt="" />
            </StyledImagePreview>
          )}
        </StyledImageDrop>
      </Box>
      {!props.hideFooterText && (
        <Box px={3}>
          <Typography variant="caption">You can add up to 3 images</Typography>
        </Box>
      )}
      <Box mt={3} px={3} display="flex" alignItems="center">
        {imageList}
      </Box>
    </>
  );
};

export const getInput = (
  inputObject,
  onChange,
  selectOptions = [],
  searchObject = {},
  onFooterClicked
) => {
  if (
    inputObject.type === "text" ||
    inputObject.type === "number" ||
    inputObject.type === "phone"
  ) {
    return (
      <Form.Item
        key={inputObject.name}
        name={inputObject.name}
        label={<StyleLabel>{inputObject.label}</StyleLabel>}
        rules={[{ required: inputObject.isRequired }]}
      >
        <StyledInputContainer>
          <Input
            required={inputObject.isRequired}
            type={inputObject.type}
            disabled={inputObject.disabled}
            defaultValue={inputObject.defaultValue}
            bordered={false}
          />
        </StyledInputContainer>
      </Form.Item>
    );
  } else if (inputObject.type === "textarea") {
    return (
      <Form.Item
        key={inputObject.name}
        name={inputObject.name}
        label={<StyleLabel>{inputObject.label}</StyleLabel>}
        rules={[{ required: inputObject.isRequired }]}
      >
        <StyledInputContainer>
          <TextArea
            defaultValue={inputObject.defaultValue}
            bordered={false}
            autoSize={{ minRows: 3 }}
          />
        </StyledInputContainer>
      </Form.Item>
    );
  } else if (inputObject.type === "select") {
    return (
      <Form.Item
        key={inputObject.name}
        name={inputObject.name}
        label={<StyleLabel>{inputObject.label}</StyleLabel>}
        rules={[{ required: inputObject.isRequired }]}
      >
        {/*  <StyledInputContainer> */}
        <StyledSelectInput
          /*    required={true} */
          placeholder={`Select ${inputObject.label}`}
          style={{ width: "100%", textAlign: "left" }}
          bordered={false}
          onChange={onChange(inputObject.name)}
          defaultValue={inputObject.defaultValue}
        >
          {selectOptions.length > 0 ? (
            selectOptions
          ) : (
            <>
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="disabled" disabled>
                Disabled
              </Option>
              <Option value="Yiminghe">yiminghe</Option>
            </>
          )}
        </StyledSelectInput>
        {/*  </StyledInputContainer> */}
      </Form.Item>
    );
  } else if (inputObject.type === "date") {
    return (
      <Form.Item
        key={inputObject.name}
        name={inputObject.name}
        label={<StyleLabel>{inputObject.label}</StyleLabel>}
        rules={[{ required: inputObject.isRequired }]}
      >
        <StyledInputContainer>
          <DatePicker
            onChange={onChange(inputObject.name)}
            defaultValue={inputObject.defaultValue}
            placeholder=""
            style={{ width: "100%" }}
            bordered={false}
          />
        </StyledInputContainer>
      </Form.Item>
    );
  } else if (inputObject.type === "search") {
    return (
      <Form.Item
        key={inputObject.name}
        name={inputObject.name}
        label={<StyleLabel>{inputObject.label}</StyleLabel>}
        rules={[{ required: inputObject.isRequired }]}
      >
        <StyledInputContainer>
          <Select
            showSearch
            style={{ width: "100%", textAlign: "left" }}
            placeholder={`Select ${inputObject.label}`}
            bordered={false}
            optionFilterProp="children"
            onChange={onChange(inputObject.name)}
            onFocus={searchObject?.onFocus}
            onBlur={searchObject?.onBlur}
            onSearch={searchObject?.onSearch}
            filterOption={(input, option) => {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            defaultValue={inputObject.defaultValue}
          >
            {selectOptions.length > 0 ? (
              selectOptions
            ) : (
              <>
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="disabled" disabled>
                  Disabled
                </Option>
                <Option value="Yiminghe">yiminghe</Option>
              </>
            )}
          </Select>
        </StyledInputContainer>
        {onFooterClicked && (
          <Box
            onClick={() => onFooterClicked(inputObject.name)}
            style={{ cursor: "pointer", color: "red" }}
            mt={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <FiPlus />
            <StyledNormalText style={{ color: "red" }} variant="caption">
              Add Customer
            </StyledNormalText>
          </Box>
        )}
      </Form.Item>
    );
  }
};

const StyledSelect = styled(Select)`
  /* border: 1px solid ${(props) => props.theme.border}; */
  width: 100%;
  padding: 4px;
  border-radius: 5px;
  & .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${(props) => props.theme.border} !important;
    padding: 4px !important;
    border-radius: 5px !important;
  }

  & .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker{
    border-color: ${(props) => props.theme.border} !important;
    padding: 4px !important;
    border-radius: 5px !important;
  }
  & p {
    color: ${(props) => props.theme.lightText};
  }
`;

export const StyledSelectInput = styled(Select)`
    border: 1px solid ${(props) => props.theme.border};
    background-color: ${props => props.theme.secondaryColor};
    box-shadow: none;
    padding: 4px;
    border-radius: 5px !important;

    &:focus, &:hover{
        /* border: 1px solid ${props => props.theme.primaryColor}; */
       /*  background-color: ${props => props.theme.secondaryColor}; */
        outline: 0;
    }


  `
