import React from 'react';
import styled from 'styled-components';
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { Menu, Space, Popover } from 'antd';
import { StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import { FiMoreVertical } from 'react-icons/fi';
import { AddSalesOrder } from './AddSalesOrder';


const columns = [
  {
    title: 'S/N',
    dataIndex: 'sn',
    key: 'sn',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'SKU',
    dataIndex: 'barcode',
    key: 'barcode',
  },
  {
    title: 'Stock On Hand',
    dataIndex: 'stockOnHand',
    key: 'stockOnHand',
  },

  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '5%',
    render: (data, allData) => {
      const content = (
        <Space direction='vertical' size='small'>
          <p>Edit</p>
          <p style={{color: 'red', cursor: 'pointer'}}>Delete</p>
        </Space>
      )
      return (
        <Popover content={content} trigger='click'>
           <FiMoreVertical />
        </Popover>
      )
    },
  },
 
];

const data = [
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
  {
    item: 'Building',
    itemId: '00009',
    itemClass: 'Stock Item',
    unitCost: '30000',
    unitPrice: '15000',
    stockOnHand: 23000,
  },
];

export const SalesOrders = (props) => {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { url, path } = useRouteMatch();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (e) => {
    console.log('click ', e);
  };

 
  const handleTableRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        history.push(`${url}/item-details`);
      },
    };
  };

  return (
    <Switch>
      <Route exact path={path}>
        <ToolBar
          title="Sales Orders"
          showtools
          onCreate={() => history.push(`${url}/add-order`)}
          createButtonText="Create Sales Order"
        />
        <StyledCard mt={2}>
          <TableWithPagination
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            columns={columns}
            dataSource={data}
            /* onRow={handleTableRow} */
          />
        </StyledCard>
      </Route>
      <Route path={`${path}/add-order`}>
        <AddSalesOrder />
      </Route>
    </Switch>
  );
};
