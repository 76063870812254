import { configureStore } from '@reduxjs/toolkit';
import itemGroupReducer from '../Components/Inventory/ItemGroups/slice';
import itemReducer from '../Components/Inventory/Items/slice';
import warehouseReducer from '../Components/Inventory/Warehouse/slice';
import dashboardReducer from '../Components/Dashboard/slice';
import invoiceReducer from '../Components/Sales/Invoice/slice';
import customerReducer from '../Components/Clients/Customers/slice';
import vendorReducer from '../Components/Clients/Vendors/slice';
import receiptReducer from '../Components/Sales/Receipt/slice';
import adjustmentsReducer from '../Components/Inventory/Adjustment/slice'
import transfarOrderReducer from "../Components/Inventory/TransferOrders/slice";


export const store = configureStore({
  reducer: {
    itemGroupReducer,
    itemReducer,
    warehouseReducer,
    dashboardReducer,
    invoiceReducer,
    customerReducer,
    vendorReducer,
    receiptReducer,
    adjustmentsReducer,
    transfarOrderReducer,
  },
});
