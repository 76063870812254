import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { ToolBar } from "../../globalComponents/ToolBar";
import {
  StyledBoldText,
  StyledCard,
  StyledModal,
} from "../../globalComponents/styles";
import { useRouteMatch, Route, Switch, useHistory } from "react-router";
import { FiMoreVertical } from "react-icons/fi";
import { AntFormModal } from "../../globalComponents/Modal";
import { Box, Paper } from "@material-ui/core";
import { getInput } from "../../Inventory/Items/AddItem";
import { Form, Space, Popover, Select, notification } from "antd";
import { Divider } from "@material-ui/core";
import {
  PrimaryButton,
  TransaparentButton,
} from "../../globalComponents/Buttons";
import Services from "../../../services";
import { Feedback } from "../../globalComponents/Feedback";
import { DeleteModal } from "../../globalComponents/deleteModal";
import { createVendor, getAllVendor, selectVendor } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboard } from "../../Dashboard/slice";
import { themes } from "../../globalAssets/theme";
import { notificationAlert } from "../../utils/notificationAlert";

const { Option } = Select;

export const addCustomerInput = [
  {
    name: "first_name",
    label: "First Name",
    isRequired: true,
    type: "text",
    id: 'firstName',
    defaultValue: "",
  },
  {
    name: "last_name",
    label: "Last Name",
    isRequired: true,
    type: "text",
    defaultValue: "",
    id: 'lastName'
  },
  {
    name: "other_name",
    label: "Other Name",
    isRequired: false,
    type: "text",
    defaultValue: "",
    id: 'otherName'
  },
  {
    name: "email",
    label: "Email",
    isRequired: true,
    type: "text",
    defaultValue: "",
    id: 'email'
  },
  {
    name: "phone_number",
    label: "Phone Number",
    isRequired: true,
    type: "phone",
    defaultValue: "",
    id: 'phoneNumber'
  },
  {
    name: "address",
    label: "Address",
    isRequired: true,
    type: "text",
    defaultValue: "",
    id :'address',
  }
];



const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const Vendor = (props) => {
  const [editForm] = Form.useForm();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { vendorList, paginationProps } = useSelector(selectVendor);
  const { usersByOrg, userProfile } = useSelector(selectDashboard);
  const [selectValues, setSelectValues] = useState({});
  const [addGroupModalProps, setAddGroupModalProps] = useState({
    isVisible: false,
    loading: false,
  });

  const [feedbackProps, setFeedbackProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [editModalProps, setEditModalProps] = useState({
    isVisible: false,
    selectedItemInput: [],
    loading: false,
    initialValues: {},
  });


  console.log({vendorList});
  
 /*  const [deleteModalProps, setDeleteModalProps] = useState({
    visible: false,
    name: "",
    id: null,
    loading: false,
  });
 

 */
  const dispatcher = useDispatch();

  useEffect(() => {
    editForm.resetFields();
    const formattedInitialValues = Object.entries(editModalProps.initialValues).map(([key, value]) => {
      if(key === 'firstName') {
        key = 'first_name'
        return [key, value];
      }
      if(key === 'lastName'){
        key = 'last_name';
        return [key, value];
      }
      if(key === 'phoneNumber'){
        key = 'phone_number';
        return [key, value];
      }

      return [key, value]
    });

    editForm.setFieldsValue(Object.fromEntries(formattedInitialValues));
  }, [editModalProps.isVisible]);

  const handleSelectChange = (selectName) => (value) => {
    const prevSelectValues = { ...selectValues };
    prevSelectValues[selectName] = value;
    setSelectValues(prevSelectValues);
  };

  const userList = [...usersByOrg].map((user, index) => {
    return (
      <Option key={index} value={user.id}>
        {user.fullName !== " " ? user.fullName : "Adefisayo Adeniji"}
      </Option>
    );
  });

  const searchObject = {
    onSearch: (val) => {},
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, allData) => {
        return `${allData.firstName} ${allData.lastName}`
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (data, allData) => {
        const content = (
          <Space direction="vertical" size="small">
            <p
               onClick={() => {
                const newData = addCustomerInput.map((item) => {
                  item.defaultValue = allData[item.id];
                  return getInput(
                    item,
                  );
                });
                setEditModalProps((prevState) => ({
                  ...prevState,
                  selectedItemInput: newData,
                  isVisible: true,
                  initialValues: allData,
                }));
              }}
              style={{ cursor: "pointer" }}
            >
              Edit
            </p>
            <p
              
              style={{ color: "red", cursor: "pointer" }}
            >
              Delete
            </p>
          </Space>
        );

  
        return (
          <Popover
            content={content}
            trigger="click"
          >
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

  const handleChangePage = (event, newPage) => {
    (async () => {
      setPage(newPage);
      await dispatcher(getAllVendor({ page: newPage, size: rowsPerPage }));
    })();
  };

  const handleChangeRowsPerPage = (event) => {
    (async () => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      await dispatcher(
        getAllVendor({ page: 0, size: parseInt(event.target.value, 10) })
      );
    })();
  };

  const customerInputs = addCustomerInput.map((item) =>
    getInput(item)
  );

  const onFinish = async (values) => {
    const data = {
      /* "other_name": "kdfwlfjwo", */
      "vendor_image": "lkwfowefpowefkwpeofk"
    }
    try {
      setAddGroupModalProps((prevState) => ({ ...prevState, loading: true }));
      values.headOfWareHouseId = selectValues.headOfWareHouseId;
      values.orgId = userProfile.orgId;
      await dispatcher(createVendor({ data: {...data, ...values} }));
      await dispatcher(getAllVendor({page, size: rowsPerPage}));
      setAddGroupModalProps((prevState) => ({
        ...prevState,
        isVisible: false,
        loading: false,
      }));
      setFeedbackProps({
        type: "success",
        open: true,
        message: "customer has been added",
      });
    } catch (error) {
      setAddGroupModalProps((prevState) => ({ ...prevState, loading: false }));
      setFeedbackProps({
        type: "error",
        open: true,
        message: "An error occurred, please try again later",
      });
    }
  };

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackProps((prevState) => ({ ...prevState, open: false }));
  };



 const onEditFinish = async (values) => {
   console.log(values);
    try {
      setEditModalProps((prevState) => ({ ...prevState, loading: true }));
      await Services.editVendors({
        data: values,
        id: editModalProps.initialValues.id,
      });
      await dispatcher(getAllVendor({ page, size: rowsPerPage }));
      setEditModalProps((prevState) => ({
        ...prevState,
        loading: false,
        isVisible: false,
      }));
      notificationAlert(
        "success",
        "Vendor updated",
        `${editModalProps.initialValues.name} has been updated`
      );
    } catch (error) {
      setEditModalProps((prevState) => ({ ...prevState, loading: false }));
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response.data.message || "Please try again later"
        );
      } else {
        notificationAlert("error", "Error Occurred", "Please try again later");
      }
    }
  }; 

console.log({paginationProps});

  return (
    <>
      <ToolBar
        title="All Vendor"
        showtools
        onCreate={() =>
          setAddGroupModalProps((prevState) => ({
            ...prevState,
            isVisible: true,
          }))
        }
        createButtonText="Add new Vendor"
      />
      <StyledCard mt={2}>
        <TableWithPagination
          rowsPerPage={rowsPerPage}
          page={page}
          total={paginationProps.totalItems}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          columns={columns}
          dataSource={vendorList}
        />
      </StyledCard>
      <CreateCustomerModal
        visible={addGroupModalProps.isVisible}
        onCancel={() =>
          setAddGroupModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        onFinish={onFinish}
        loading={addGroupModalProps.loading}
        warehouseInputs={customerInputs}
      />
      <AntFormModal
        title="Edit Vendor"
        visible={editModalProps.isVisible}
        onCancel={() =>
          setEditModalProps((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        form={editForm}
        okText='Submit'
        loading={editModalProps.loading}
        onFinish={onEditFinish}
      >
        {editModalProps.selectedItemInput}
      </AntFormModal>

      <Feedback
        open={feedbackProps.open}
        message={feedbackProps.message}
        type={feedbackProps.type}
        onClose={handleFeedbackClose}
      />
      {/* <DeleteModal
        open={deleteModalProps.visible}
        loading={deleteModalProps.loading}
        itemName={deleteModalProps.name}
        onClose={() =>
          setDeleteModalProps((prevState) => ({ ...prevState, visible: false }))
        }
        onDelete={handleWarehouseDelete}
        title="Delete Warehouse"
      /> */}
    </>
  );
};

export const CreateCustomerModal = (props) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.submit();
  };

  return (
    <StyledModal
      title=" New Vendor"
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={onOk}
      okText="Create"
      onCancel={props.onCancel}
    >
      <Form form={form} {...layout} onFinish={props.onFinish}>
        {props.warehouseInputs}
      </Form>
    </StyledModal>
  );
};

const EditWarehouseModal = (props) => {
  const onOk = () => {
    props.form.submit();
  };

  const cancelAndReset = () => {
    props.onCancel();
  };

  return (
    <StyledModal
      title="Edit Warehouse"
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        htmlType: "submit",
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={onOk}
      okText="Create"
      onCancel={cancelAndReset}
    >
      <Form
        initialValues={props.initialValues}
        form={props.form}
        {...layout}
        onFinish={props.onFinish}
      >
        {props.warehouseInputs}
      </Form>
    </StyledModal>
  );
};
