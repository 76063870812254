import React, { useState, useEffect } from "react";
import { ToolBar } from "../../globalComponents/ToolBar";
import { Row, Col } from "antd";
import { StyledCard } from "../../globalComponents/styles";
import { useParams } from "react-router";
import { Box, Grid } from "@material-ui/core";
import {
  StyledNormalText,
  StyledBoldText,
} from "../../globalComponents/styles";
import Services from "../../../services";
import { ItemImageComponent } from './AddItem';

const firstSegment = [
  {
    title: "Item Name",
    value: "Mike Bamidele",
    key: "itemName",
  },
  {
    title: "Item ID",
    value: "Lucas John",
    key: "id",
  },
  {
    title: "Item Category",
    value: "Service",
    key: "itemCategory",
  },
  { key: "itemGroup", title: "Item Group", value: "" },
  { key: "itemType", title: "Item Type", value: "" },
  {
    title: "SKU/UPC",
    value: "dd/mm/yy",
    key: "sku",
  },
  {
    title: "Description",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis tempor, tincidunt. Eget molestie placerat mattis sed eget hendrerit quam leo eget.",
    key: "description",
  },
];

const secondSegment = [
  {
    key: "partNumber",
    title: "Part Number",
    value: "",
  },
  {
    key: "sellingPrice",
    title: "Selling Price",
    isRequired: false,
    type: "number",
  },
  { key: "costPrice", title: "Cost Price", isRequired: false, type: "number" },
  {
    key: "unit",
    title: "Unit",
    isRequired: false,
    type: "select",
  },
  {
    key: "itemDimension",
    title: "Dimension",
    isRequired: false,
    type: "text",
  },
  {
    key: "lastUnitCost",
    title: "Last Unit Cost",
    isRequired: false,
    type: "number",
  },
  { key: "itemWeight", title: "Weight", isRequired: false, type: "number" },
];

const stock = [
  {
    title: "Quantity on Hand",
    value: "2000",
    key: "quantity",
  },
  {
    title: "Reorder Level",
    value: "20",
    key: "reOrderPoint",
  },
  /*   {
    title: 'Opening Stock',
    value: '40',
    key: 'openingStock'
  }, */
];

const sales = [
  {
    title: "Sales Account",
    value: "Income",
    key: "salesAccountId",
  },
 
];

const purchase = [
  {
    title: "Purchase Account",
    value: "Cost of good sold",
    key: "purchaseAccountId",
  },
  
];

const inventory = [
  {
    title: "Inventory Account",
    value: "Income",
    key: "inventoryAccountCode",
  },
];

const tax = [
  {
    title: "Tax Account",
    value: "Income",
    key: "taxAccountId",
  },
];

const columns = [
  {
    title: "Warehouse Name",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Quantity at Hand",
    dataIndex: "quantity",
    key: "quantity",
  },
];

const data = [
  {
    key: "Ojota Zone",
    quantity: 200,
  },
  {
    key: "Ikeja Zone",
    quantity: 200,
  },
  {
    key: "Abuja Zone",
    quantity: 200,
  },
  {
    key: "Aba Zone",
    quantity: 200,
  },
];

export const ItemDetails = (props) => {
  const  {id}  = useParams();
  const [singleItem, setItem] = useState({ itemsImages: [] });

  useEffect(() => {
    (async () => {
      try {
        const response = await Services.getSingleItem({id});
        console.log(response, 'singleItem')
        setItem(response.data.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id]);
 

  const firstSeg = firstSegment.map((item, index) => {
    if (item.key === "itemCategory") {
      item.value = singleItem[item.key]
        ? singleItem[item.key].split("_").join(" ")
        : "No value";
    } else if (item.key === "itemGroup") {
      item.value = singleItem?.itemGroups?.id;
    } else {
      item.value = singleItem[item.key] ?? "No value";
    }

    return (
      <SingleItemProperty key={index} title={item.title} value={item.value} />
    );
  });

  const secondSeg = secondSegment.map((item, index) => {
    if (item.key === "itemType") {
      item.value =
        singleItem[item.key] === "1"
          ? "Goods"
          : singleItem[item.key] === "2"
          ? "Service"
          : "No value";
    } else {
      item.value = singleItem[item.key] ?? "No value";
    }

    return (
      <Col xs={12}>
        <SingleItemProperty key={index} title={item.title} value={item.value} />
      </Col>
    );
  });

  const stockSeg = stock.map((item, index) => {
    item.value = singleItem[item.key] ?? "No value";
    return (
      <Col xs={12}>
        <SingleItemProperty key={index} title={item.title} value={item.value} />
      </Col>
    );
  });

  const salesSeg = sales.map((item, index) => {
    item.value = singleItem[item.key] ?? "No value";
    return (
      <SingleItemProperty key={index} title={item.title} value={item.value} />
    );
  });

  const purchaseSeg = purchase.map((item, index) => {
    item.value = singleItem[item.key] ?? "No value";
    return (
      <SingleItemProperty key={index} title={item.title} value={item.value} />
    );
  });

  const inventorySeg = inventory.map((item, index) => {
    item.value = singleItem[item.key] ?? "No value";
    return (
      <SingleItemProperty key={index} title={item.title} value={item.value} />
    );
  });

  const taxSeg = tax.map((item, index) => {
    item.value = singleItem[item.key] ?? "No value";
    return (
      <SingleItemProperty key={index} title={item.title} value={item.value} />
    );
  });


  const imageArr = singleItem?.itemsImages?.map((item) => {
    console.log(item, '888')
    return item.fileUrl;
  });
  // const imagearr2 = singleItem.itemGroups.items.map((itm)=>{
  //   console.log(itm.itemsImages.map((itm2)=>{
  //     console.log(itm2.fileUrl, "itm2");
  //   }), "111");
  // });

  //? work in progress....
  const image123 = singleItem.itemGroups.items
   const imagearr2 = image123.map((itm) => {
     const image345 = itm.itemsImages;
    const image567 = image345.map((itm2)=>{
       return itm2.fileName;
     })
     return image567;
    
   });
  
  console.log(imagearr2, "imageArr");

  return (
    <>
      <ToolBar title="Product Name" />
      <StyledCard mt={2} py={3} px={4} display="flex">
        <Grid xs={5}>
          <ItemImageComponent imageArr={`data:image/jpg,base64,${imageArr}`} hideFooterText={true} />
        </Grid>
        <Grid xs={7}>
          <Box ml={6} mt={2}>
            {firstSeg}
          </Box>
        </Grid>
      </StyledCard>
      <StyledCard mt={2} px={4} pt={4}>
        <Row>{secondSeg}</Row>
      </StyledCard>
      <StyledCard mt={2} px={4} pt={4}>
        <SegmentTitle text="Physical Stock" />
        <Row>{stockSeg}</Row>
      </StyledCard>
      <StyledCard mt={2} px={4} pt={4}>
        <Row style={{ marginBottom: 32 }}>
          <Col xs={12}>
            <SegmentTitle text="Sales" />
            {salesSeg}
          </Col>
          <Col xs={12}>
            <SegmentTitle text="Purchase" />
            {purchaseSeg}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SegmentTitle text="Inventory" />
            {inventorySeg}
          </Col>
          <Col xs={12}>
            <SegmentTitle text="Tax" />
            {taxSeg}
          </Col>
        </Row>
      </StyledCard>
      {/* <StyledCard mt={2} px={4} pt={4} pb={6}>
            <Box mb={2} display='flex' justifyContent='space-between' alignItems='center'>
            <SegmentTitle text="Stock Locations" />
            <PrimaryButton text='Add another Warehouse'/>
            </Box>
            <Box>
                <StyledTable columns={columns} dataSource={data}/>
            </Box>
      </StyledCard> */}
    </>
  );
};

export const SingleItemProperty = ({ title, value, ...props }) => {
  return (
    <Box display="flex" alignItems="flex-start">
      <Box flexShrink={0} flexBasis="40%" mb={4}>
        <StyledBoldText variant="body1">{title}</StyledBoldText>
      </Box>
      <Box flexBasis="auto" mx={2}>
        <StyledBoldText style={{ fontWeight: 500 }} variant="body1">
          :
        </StyledBoldText>
      </Box>
      <Box flexBasis="auto">
        <StyledNormalText variant="body1">{value}</StyledNormalText>
      </Box>
    </Box>
  );
};


const SegmentTitle = (props) => {
  return (
    <Box mb={3}>
      <StyledBoldText variant="subtitle2">{props.text}</StyledBoldText>
    </Box>
  );
};
