import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  StyledCard,
  StyledNormalText,
} from "../../globalComponents/styles";
import {
  StyledBoldText,
} from "../../globalComponents/styles";
import { Box, Divider } from "@material-ui/core";
import { themes } from "../../globalAssets/theme";
import companyLogo from "../../images/companyLogo2.jpeg";
import { ToolBar, HistoryBar } from "../Invoice/SingleInvoice";
import { useParams } from "react-router";
import { useDispatch, useSelector} from 'react-redux';
import { fetchSingleReceipt, selectReceipts } from './slice';
import moment from 'moment';
import { Statistic } from 'antd';

const initialBillInfo = [
  {
    title: "bill To",
    value: "",
  },
  {
    title: "Payment Date",
    value: "",
  },
  {
    title: "Payment Method",
    value: "",
  },
];

export const ReceiptDetail = (props) => {
  const { singleReceipt } = useSelector(selectReceipts);
  const [billInfo, setBillInfo] = useState(initialBillInfo)
  const { id } = useParams();
  const dispatcher = useDispatch()



  useEffect(() => {
    dispatcher(fetchSingleReceipt({id}))
  }, [])

  useEffect(() => {
    const billInfo = [
      {
        title: "bill To",
        value: singleReceipt.billTo,
      },
      {
        title: "Payment Date",
        value: moment(singleReceipt.created_at).format('Do MMM, YYYY'),
      },
      {
        title: "Payment Method",
        value: singleReceipt.paymentMethod,
      },
    ];
    setBillInfo(billInfo);
  }, [singleReceipt])

    const billInfoList = billInfo.map((item, index) => {
        return <SingleDetail data={item} key={index}/>
    })

  return (
    <>
      <ToolBar hidePaymentButton />
      <HistoryBar />
      <StyledCard pb={6} mt={3} px={4} py={2}>
        <Box mb={6} display="flex" alignItems="flex-end">
          <StyledImage src={companyLogo} alt="logo" />
          <StyledBoldText variant="h5">First Marine</StyledBoldText>
        </Box>
        <StyledBoldText gutterBottom={true} variant="h4">
          Receipt
        </StyledBoldText>
        <Box display="flex">
          <StyledBoldText variant="subtitle2">Receipt Number:</StyledBoldText>
          <Box ml={3}>
            <StyledBoldText variant="subtitle2">{singleReceipt.receiptNumber}</StyledBoldText>
          </Box>
        </Box>
        <StyledReciptBody>
          <Box display="flex">
            <Box
              px={6}
              py={4}
              flexBasis="50%"
              style={{ backgroundColor: "#F3F6FC" }}
            >
                {billInfoList}
            </Box>
            <Box
              px={6}
              py={4}
              flexBasis="50%"
              style={{
                backgroundColor: themes.primaryColor,
                color: themes.white,
              }}
            >
              <StyledBoldText
                style={{ color: themes.white }}
                gutterBottom={true}
                align="center"
                variant="body1"
              >
                Payment Amount
              </StyledBoldText>

              <Statistic valueStyle={{color: themes.white, fontSize: '3vw', fontWeight: 'bolder', textAlign: 'center'}} value={singleReceipt.paymentAmount} prefix="₦"  precision={2}/>
             {/*  <StyledBoldText
                style={{ color: themes.white }}
                align="center"
                variant="h3"
              >
                ${singleReceipt.paymentAmount}
              </StyledBoldText> */}
            </Box>
          </Box>
          <Box mt={6} py={4} px={6} style={{ backgroundColor: "#F3F6FC" }}>
              <Box display='flex' justifyContent='space-between'>
                  <SingleDetail data={{title: 'invoice number', value: singleReceipt.invoiceNumber}}/>
                  <SingleDetail isamount data={{title: 'Invoice Amount', value: singleReceipt.invoiceAmount}}/>
              </Box>
              {/* <Box px={6} mt={4} mb={2}>
                  <Divider/>
              </Box>
              <Box display='flex' justifyContent='space-between'>
                  <SingleDetail data={{title: 'invoice number', value: 'INV/0000001'}}/>
                  <SingleDetail data={{title: 'Invoice Amount', value: 'N 300,0001'}}/>
              </Box> */}
          </Box>
        </StyledReciptBody>
      </StyledCard>
    </>
  );
};

const SingleDetail = (props) => {
  return (
    <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
      <StyledBoldText
        style={{ textTransform: "capitalize" }}
        variant="subtitle2"
      >
        {props.data.title}:
      </StyledBoldText>
      <Box ml={4}>
        {
          props.isamount? 
          <Statistic value={props.data.value} prefix="₦"  precision={2}/>
          :
        <StyledNormalText
          style={{ textTransform: "capitalize" }}
          variant="body2"
          align='left'
        >
          {props.data.value}
        </StyledNormalText>
}
      </Box>
    </Box>
  );
};
const StyledReciptBody = styled(Box)`
  width: 90%;
  margin: 0 auto;
  margin-top: 24px;
`;
const StyledImage = styled.img`
  width: 10%;
`;
