import React from 'react';
import styled from 'styled-components';
import { TablePagination } from '@material-ui/core';
import { StyledTable } from './styles';

export const TableWithPagination = (props) => {
  return (
    <>
      <StyledTable pagination={false} onRow={props.onRow} columns={props.columns} dataSource={props.dataSource} />
      {!props.hidePagination && <TablePagination
        component="div"
        count={props.total ?? 100}
        page={props.page}
        onChangePage={props.handleChangePage}
        rowsPerPage={props.rowsPerPage}
        onChangeRowsPerPage={props.handleChangeRowsPerPage}
      />}
    </>
  );
};
