import React from 'react';
import { Typography, Box, Icon } from '@material-ui/core';
import { StyledCard } from './styles';
import { PrimaryButton, TransaparentButton } from './Buttons';
import { SelectInput } from './SelectInput';

export const ToolBar = (props) => {
  return (
    <StyledCard px={3} py={2}>
      <Typography variant="h6">{props.title}</Typography>
      {props.showtools && (
        <>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <PrimaryButton text={props.createButtonText} onClick={props.onCreate} />
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
             <Box style={{width: '40%'}}> 
                <SelectInput />
             </Box>   
           
            <Box display="flex" justifyContent="center" alignItems="center">
              <TransaparentButton
                text="Import"
                endIcon={<Icon>download</Icon>}
              />
              <Box ml={2}>
                <TransaparentButton
                  text="Export"
                  endIcon={<Icon>upload</Icon>}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </StyledCard>
  );
};
