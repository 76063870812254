export const mobile = {
    large: '32px',
    medium: '18px',
    small: '12px',
    normal: '16px',
}

export const desktop = {
    large: '2.1vw',
    medium: '1.4vw',
    normal: '1.1vw',
    small: '0.8vw'
}