import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  darkTheme,
  lightTheme,
  GlobalStyle,
} from "./Components/globalAssets/theme";
import {
  ThemeProvider as MuiThemeProvider,
  makeStyles,
  StylesProvider,
} from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
/* import { useTheme } from '@material-ui/core/styles'; */
import { Container, Box, Button } from "@material-ui/core";
import { TopBar } from "./Components/globalComponents/TopBar";
import { SideBar } from "./Components/globalComponents/SideBar";
import { BsFillBellFill } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { DropDown } from "./Components/globalComponents/DropDown";

import styled from "styled-components";

import { Switch, Route, useLocation } from "react-router-dom";

import { Menu, Select, Table, Avatar, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Items as InventoryItems } from "./Components/Inventory/Items/index";
import { ItemGroups as InventoryItemGroups } from "./Components/Inventory/ItemGroups/index";
import { Warehouse as InventoryWarehouse } from "./Components/Inventory/Warehouse/index";
import { InventoryAdjustment } from "./Components/Inventory/Adjustment/index";
import { TransferOrder as InventoryTransferOrders } from "./Components/Inventory/TransferOrders/index";
import { SalesOrders } from "./Components/Sales/SalesOrders/index";
import { loadProgressBar } from "axios-progress-bar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllWarehouse } from "./Components/Inventory/Warehouse/slice";
import {
  fetchAllItemGroups,
  fetchItemGroupsByOrg,
} from "./Components/Inventory/ItemGroups/slice";
import {
  getUserProfile,
  getUsersByOrg,
  selectDashboard,
  getItemGroupNumber,
} from "./Components/Dashboard/slice";
import { useSelector } from "react-redux";
import { Invoice } from "./Components/Sales/Invoice/index";
import { Receipt } from "./Components/Sales/Receipt/index";
import { Customers } from "./Components/Clients/Customers/index";
import { fetchAllItems } from "./Components/Inventory/Items/slice";
import { getAllCustomer } from "./Components/Clients/Customers/slice";
import { Dashboard } from "./Components/Dashboard/index";
import Services, { mainDomain } from "./services";
import hostname from "./domain";
import { Vendor } from "./Components/Clients/Vendors";
import { getAllVendor } from "./Components/Clients/Vendors/slice";

const { Option } = Select;
const { SubMenu } = Menu;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "1.2vw",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function App() {
  loadProgressBar();
  const [isLightMood, setMood] = React.useState(true);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatcher = useDispatch();
  const { userProfile } = useSelector(selectDashboard);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      localStorage.invToken = token;
    }
  }, []);

  useEffect(() => {
    (async () => {
      try{
        if (
          localStorage.invToken !== undefined &&
          localStorage.invToken !== null
        ) {
          dispatcher(getAllWarehouse({}));
          dispatcher(fetchAllItemGroups({}));
          dispatcher(getUserProfile({}));
          dispatcher(fetchAllItems({ page: 0, size: 10 }));
          dispatcher(getAllCustomer({ page: 0, size: 10 }));
          dispatcher(getAllVendor({ page: 0, size: 10 }));
         // dispatcher(getItemGroupNumber());
        }
      }catch(error){
          console.log(error.statusCode);
      }
    
    })();
  }, []);

  useEffect(() => {
    if (Object.entries(userProfile).length > 0) {
      dispatcher(
        fetchItemGroupsByOrg({
          orgId: userProfile.organisation.orgId,
          page: 0,
          size: 10,
        })
      );
    }
  }, [userProfile]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const switchURL = (URL) => {
    return mainDomain.split("api").length > 2
      ? URL.replace(/dev/, "apps")
      : URL.replace(/apps/, "dev");
  };

  const handleLogout = async () => {
    try {
      const response = await Services.logout();
      console.log(response);
      localStorage.removeItem("invToken");
      window.location.assign(switchURL("http://apps.ezoneerp.com/login"));
    } catch (error) {}
  };

  const handleClose = (event, itemName) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      if (itemName === "Logout") {
        /* localStorage.removeItem('invToken');
        window.location.assign("http://apps.ezoneerp.com/login"); */
        handleLogout();
      } else if (itemName === "My Account") {
        window.location.assign(
          switchURL("http://apps.ezoneerp.com/user-profile")
        );
      } else if (itemName === "Subscriptions") {
        window.location.assign(
          switchURL("http://apps.ezoneerp.com/subscriptions")
        );
      } else if (itemName === "Help") {
        window.location.assign(switchURL("http://apps.ezoneerp.com/help"));
      }
      console.log(itemName);
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={isLightMood ? lightTheme : darkTheme}>
        <ThemeProvider theme={isLightMood ? lightTheme : darkTheme}>
          <GlobalStyle />
          <CssBaseline />
          <Container disableGutters={true} maxWidth={false}>
            <TopBar />
            <SideBar />
            <StyledContainer>
              <TopBar hidden />
              <StyledUserBar>
                <StyledInnerUserBar1></StyledInnerUserBar1>
                <StyledInnerUserBar>
                  <Box></Box>
                  <Box></Box>
                </StyledInnerUserBar>
                <StyledUserToolBar ref={anchorRef} px={2}>
                  <Badge
                    overflowCount={10}
                    offset={[5, 0]}
                    style={{ border: "none", boxShadow: "none" }}
                    size="small"
                    count={9}
                  >
                    <BsFillBellFill
                      style={{ fontSize: "1.6vw", color: "#CCCCCC" }}
                    />
                  </Badge>
                  <Box
                    style={{ color: "#fff" }}
                    ml={4}
                    display="flex"
                    alignItems="center"
                  >
                    <Box mr={2}>
                      <Avatar size="small" icon={<UserOutlined />} />
                    </Box>
                    <Box>
                      <Button
                        endIcon={<IoMdArrowDropdown />}
                        onClick={handleToggle}
                        style={{ color: "#fff" }}
                      >
                        {userProfile.fullName === " "
                          ? `${userProfile.firstName} ${userProfile.lastName}`
                          : userProfile.fullName}
                      </Button>
                      <DropDown
                        items={[
                          "My Account",
                          "Subscriptions",
                          "Help",
                          "Logout",
                        ]}
                        handleListKeyDown={handleListKeyDown}
                        ref={anchorRef}
                        open={open}
                        handleClose={handleClose}
                      />
                    </Box>
                  </Box>
                </StyledUserToolBar>
              </StyledUserBar>
              <Box mx={2} my={2}>
                <Switch>
                  <Route exact path="/">
                    <Dashboard />
                  </Route>
                  <Route path="/items">
                    <InventoryItems />
                  </Route>
                  <Route path="/item-groups">
                    <InventoryItemGroups />
                  </Route>
                  <Route path="/warehouse">
                    <InventoryWarehouse />
                  </Route>
                  <Route path="/inventory-adjustment">
                    <InventoryAdjustment />
                  </Route>
                  <Route path="/transfer-orders">
                    <InventoryTransferOrders />
                  </Route>
                  <Route path="/sales-orders">
                    <SalesOrders />
                  </Route>
                  <Route path="/invoice">
                    <Invoice />
                  </Route>
                  <Route path="/receipts">
                    <Receipt />
                  </Route>
                  <Route path="/customers">
                    <Customers />
                  </Route>
                  <Route path="/vendors">
                    <Vendor/>
                  </Route>
                </Switch>
              </Box>
            </StyledContainer>
          </Container>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

const StyledContainer = styled(Box)`
  width: 83%;
  margin-left: 17%;
`;

const StyledUserBar = styled(Box)`
  background-color: ${(props) => props.theme.primaryColor};
  width: 100%;
  border-top-left-radius: 5vw;
  border-bottom-right-radius: 5vw;
  height: 10vh;
  display: flex;
  justify-content: flex-start;
`;

const StyledInnerUserBar = styled(Box)`
  width: 50%;
  height: 100%;

  display: flex;

  & > div {
    width: 50%;
    transform: skewX(40deg);
  }

  & > div:first-child {
    background-color: ${(props) => props.theme.primaryColor2};
    opacity: 0.4;
  }

  & > div:nth-child(2) {
    background-color: ${(props) => props.theme.primaryColor3};
  }
`;
const StyledInnerUserBar1 = styled(Box)`
  width: 25%;
  background-color: #200990;
  display: relative;
  z-index: -1;
`;

const StyledUserToolBar = styled(Box)`
  display: flex;
  width: 25%;
  align-items: center;
`;
export default App;
