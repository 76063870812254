import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { StyledNormalText, StyledCard } from "../globalComponents/styles";
import { Statistic } from "antd";
import { themes } from "../globalAssets/theme";
import { selectItemGroups } from "../Inventory/ItemGroups/slice";
import { selectItems } from "../Inventory/Items/slice";
import { selectWarehouse } from "../Inventory/Warehouse/slice";
import { useSelector } from "react-redux";

const stat = [
  /*  { name: "Total Items", count: 2000 }, */
  /*  { name: "Total Active Items", count: 1900 },
  { name: "Total Inactive Items", count: 100 }, */
  /*  { name: "Total Item Group", count: 2000 }, */
  /*   { name: "Total Warehouse", count: 2000 }, */
  /*  { name: "Total Warehouse", count: 2000 }, */
];
export function Dashboard(props) {
  const { paginationProps: totalItemGroup, itemGroupList } = useSelector(selectItemGroups);
  const {itemList} = useSelector(selectItems);
  const { warehouseList } = useSelector(selectWarehouse);


  return (
    <>
      <StyledStatContainer>
        <SingleStat count={itemList.length} name="Total Item" />
        <SingleStat count={totalItemGroup.totalItemGroup} name="Total Item Group" />
        <SingleStat count={warehouseList.length} name="Total Warehouse" />
      </StyledStatContainer>
    </>
  );
}

const SingleStat = (props) => {
  return (
    <StyledStatCard>
      <Statistic
        valueStyle={{
          color: themes.primaryColor,
          fontWeight: "bold",
          fontSize: "2.5vw",
        }}
        value={props.count}
      />
      <StyledNormalText variant="body2">{props.name}</StyledNormalText>
    </StyledStatCard>
  );
};

const StyledStatContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledStatCard = styled(StyledCard)`
  flex-basis: 23%;
  display: grid;
  place-items: center;
  padding: 20px;
  margin-right: 2%;
  margin-bottom: 15px;
`;
