import {
  StyledPrimaryButton,
  StyledTransparentButton,
  StyledCancelButton,
} from "./styles";
import * as React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    /*  margin: theme.spacing(1), */
    position: "relative",
  },
  buttonProgress: {
    color: theme.PrimaryButton,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const PrimaryButton = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <StyledPrimaryButton
        disabled={props.loading}
        {...props}
        variant="contained"
      >
        {props.text}
      </StyledPrimaryButton>

      {props.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export const CancelButton = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <StyledCancelButton
        disabled={props.loading}
        {...props}
        variant="contained"
      >
        {props.text}
      </StyledCancelButton>
      {props.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export const TransaparentButton = (props) => {
  return (
    <StyledTransparentButton
      variant="outlined"
      endIcon={props.endIcon}
      {...props}
    >
      {props.text}
    </StyledTransparentButton>
  );
};
