import React, { useState } from 'react';
import styled from 'styled-components';
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { StyledBoldText, StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import { FiMoreVertical, FiTrash2 } from 'react-icons/fi';
import { ModalComponent } from '../../globalComponents/Modal';
import { Box, Paper } from '@material-ui/core';
import { getInput } from '../Items/AddItem';
import { Form, Row, Col, Input, Select } from 'antd';
import { Divider } from '@material-ui/core';
import {
  PrimaryButton,
  TransaparentButton,
} from '../../globalComponents/Buttons';
import {
  StyledInputContainer,
  StyleLabel,
} from '../../globalComponents/styles';
import { SingleItemProperty } from '../Items/ItemDetails';

const secondSegment = [
  {
    title: 'Warehouse',
    value: 'Ojota Zone',
  },
  {
    title: 'Account',
    value: 'Mike Bamidele',
  },
  {
    title: 'Ref no',
    value: '000999000YU',
  },
  {
    title: 'Date',
    value: '3rd Jul,2020',
  },
  {
    title: 'Reason',
    value:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis tempor, tincidunt. Eget molestie placerat mattis sed eget hendrerit quam leo eget.',
  },
];

const columns = [
    {
        title: 'Item',
        dataIndex: 'item',
        key: 'item',
    },
    {
        title: 'Previous Quantity',
        dataIndex: 'previousQuantity',
        key: 'previousQuantity',
    },
    {
        title: 'Adjusted By',
        dataIndex: 'adjustedBy',
        key: 'adjustedBy',
    },
    {
        title: 'Adjusted Quantity',
        dataIndex: 'adjustedQuantity',
        key: 'adjustedQuantity',
    },
]


const data = [
    {
        item: 'Drilling Machine',
        previousQuantity: 30,
        adjustedBy: '-10',
        adjustedQuantity: 20

    }
]
export const AdjustmentDetails = (props) => {
  const secondSeg = secondSegment.map((item, index) => {
    return (
      <Col xs={12}>
        <SingleItemProperty key={index} title={item.title} value={item.value} />
      </Col>
    );
  });

  return (
    <>
      <ToolBar title="New Inventory Adjustments" />
      <StyledCard mt={2} p={4}>
        <Row>{secondSeg}</Row>
      </StyledCard>
      <StyledCard mt={2}>
        <TableWithPagination
          columns={columns}
          dataSource={data}
      /*     onRow={handleTableRow} */
          hidePagination={true}
        />
      </StyledCard>
    </>
  );
};
