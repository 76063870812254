import React from "react";
import styled from "styled-components";
import { Menu, Select, Table } from "antd";
import bellow1 from "../images/bgs/below1.png";
import bellow2 from "../images/bgs/below2.png";
import bellow3 from "../images/bgs/below3.png";
import upper1 from "../images/bgs/upper1.png";
import upper2 from "../images/bgs/upper2.png";
import { MdDashboard, MdLabel } from "react-icons/md";
import { Box } from "@material-ui/core/";
import { StyledLogo, StyledTopBar } from "./styles";
import Logo from "../images/logo.png";
import { TopBar } from "./TopBar";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

const inventoryLinkObj = [
  { name: "Items", link: "/items" },
  { name: "Item Groups", link: "/item-groups" },
  { name: "Warehouse", link: "/warehouse" },
  { name: "Transfer Orders", link: "/transfer-orders" },
  { name: "Adjustments", link: "/inventory-adjustment" },
];

const SalesLinkObj = [
/*   { name: "Sales Orders", link: "/sales-orders" }, */
  { name: "Invoice", link: "/invoice" },
  { name: "Receipts", link: "/receipts" },
];

const partiesObj = [
  { name: "Customers", link: "/customers" },
  { name: "Vendors", link: "/vendors" },
];
export const SideBar = (props) => {
  const handleClick = (e) => {};

  const inventoryLink = inventoryLinkObj.map((item, index) => {
    return (
      <StyledSubMenuItem key={`inventory-${index}`}>
        <Link to={item.link}>{item.name}</Link>
      </StyledSubMenuItem>
    );
  });

  const salesLink = SalesLinkObj.map((item, index) => {
    return (
      <StyledSubMenuItem key={`sales-${index}`}>
        <Link to={item.link}>{item.name}</Link>
      </StyledSubMenuItem>
    );
  });

  const partiesLinks = partiesObj.map((item, index) => {
    return (
      <StyledSubMenuItem key={`parties-${index}`}>
        <Link to={item.link}>{item.name}</Link>
      </StyledSubMenuItem>
    );
  });

  return (
    <StyledSideBar>
      <Box pt={3}>
        <TopBar hidden />
        <StyledMainMenu
          onClick={handleClick}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "#fff",
          }}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
        >
          <StyledSubMenuItem icon={<MdDashboard />} key="1">
            <Link to="/">Dashboard</Link>
          </StyledSubMenuItem>
          <StyledMenu key="sub4" icon={<MdLabel />} title="Inventory">
            {inventoryLink}
          </StyledMenu>
          <StyledMenu key="sub5" icon={<MdLabel />} title="Sales">
            {salesLink}
          </StyledMenu>
          <StyledMenu key="sub6" icon={<MdLabel />} title="Clients">
            {partiesLinks}
          </StyledMenu>
        </StyledMainMenu>
      </Box>
    </StyledSideBar>
  );
};

const StyledMenu = styled(SubMenu)`
  background-color: transparent;
  color: #fff !important;
  font-weight: bold;
  &:hover {
    color: #fff !important;
  }

  & .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
    color: #fff !important;

    &:hover {
      color: #fff !important;
    }
  }
`;

const StyledSideBar = styled(Box)`
  width: 17%;
  height: 100vh;
  position: fixed;
  background: linear-gradient(359.88deg, #250943 55.29%, #1702ff 127.12%);

  & > div {
    background-image: url(${upper1}), url(${upper2}), url(${bellow1}),
      url(${bellow2}), url(${bellow3});
    background-position: 50% -5%, 50% -5%, bottom, bottom, bottom;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    height: 100%;
    width: 100%;
  }
`;

export const StyledSubMenuItem = styled(Menu.Item)`
  color: ${(props) => props.theme.white} !important;
  font-size: 14px !important;
  font-weight: bold;

  & a {
    color: ${(props) => props.theme.white};
  }

  &:hover {
    color: ${(props) => props.theme.white} !important;
  }
`;

const StyledMainMenu = styled(Menu)`
  width: 100%;
  background-color: transparent;
  color: #fff;
`;
