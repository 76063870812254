import React, {  useState } from "react";
import { TableWithPagination } from "../../globalComponents/TableWithPagination";
import { ToolBar } from "../../globalComponents/ToolBar";
import {  Popover, Space } from "antd";
import { StyledCard } from "../../globalComponents/styles";
import { useRouteMatch, Route, Switch, useHistory } from "react-router";
import { AddItem } from "./AddItem";
import { ItemDetails } from "./ItemDetails";
import { selectItems, fetchAllItems } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { FiMoreVertical } from "react-icons/fi";
import Services from "../../../services";
import { Feedback } from "../../globalComponents/Feedback";
import { DeleteModal } from "../../globalComponents/deleteModal";
import { EditItem } from './EditItem';

export const Items = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { itemList, paginationProps } = useSelector(selectItems);
  const dispatcher = useDispatch();
  
  console.log({itemList, paginationProps }, 'itemlist');


  const [deleteModalProps, setDeleteModalProps] = useState({
    visible: false,
    name: "",
    id: null,
    loading: false,
  });

  const [feedbackProps, setFeedbackProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const columns = [
    {
      title: "Item",
      dataIndex: "itemName",
      key: "itemName",
    },
    /*  {
      title: 'Item ID',
      dataIndex: 'itemId',
      key: 'itemId',
    }, */
    {
      title: "Item Class",
      dataIndex: "itemCategory",
      key: "itemCategory",
      render: (data) => {
        return data === "1" ? "Stock Item" : "Component Item";
      },
    },
    {
      title: "Unit Price",
      dataIndex: "lastUnitCost",
      key: "lastUnitCost",
    },
    {
      title: "Unit Cost",
      dataIndex: "costPrice",
      key: "costPrice",
    },
    {
      title: "Stock on hand",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Warehouse",
      dataIndex: "wareHouseName",
      key: "wareHouseName",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (data, allData) => {
        const content = (
          <Space direction="vertical" size="small">
            <p
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`${url}/${allData.id}`)}
            >
              View Detail
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`${url}/${allData.id}/edit`)}
            >
              Edit
            </p>
            <p
              onClick={() =>
                setDeleteModalProps({
                  name: allData.itemName,
                  id: allData.id,
                  visible: true,
                })
              }
              style={{ color: "red", cursor: "pointer" }}
            >
              Delete
            </p>
          </Space>
        );
        return (
          <Popover content={content} trigger="click">
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];



  /* useEffect(() => {
    getAllItems();
  },[]) */

  /*  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 */

  const handleChangePage = (event, newPage) => {
    (async () => {
      setPage(newPage);
      await dispatcher(fetchAllItems({ page: newPage, size: rowsPerPage }));
    })();
  };

  const handleChangeRowsPerPage = (event) => {
    (async () => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      await dispatcher(
        fetchAllItems({ page: 0, size: parseInt(event.target.value, 10) })
      );
    })();
  };

  const gotoAddItem = () => {
    history.push(`${url}/add-item`);
  };

  const handleItemDelete = async () => {
    try {
      setDeleteModalProps((prevState) => ({ ...prevState, loading: true }));
       await Services.deleteItem({
        id: deleteModalProps.id,
      });
      await dispatcher(fetchAllItems({ page, size: rowsPerPage }));
      setFeedbackProps({
        type: "success",
        message: `${deleteModalProps.name} has been deleted`,
        open: true,
      });
      setDeleteModalProps((prevState) => ({
        ...prevState,
        loading: false,
        visible: false,
      }));
    } catch (error) {
      console.log(error);
      setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
      setFeedbackProps({
        type: "error",
        message: `Please try again`,
        open: true,
      });
    }
  };

  return (
    <Switch>
      <Route exact path={path}>
        <ToolBar
          title="All Items"
          showtools
          onCreate={gotoAddItem}
          createButtonText="Create Item"
        />
        <StyledCard mt={2}>
          <TableWithPagination
            rowsPerPage={rowsPerPage}
            page={page}
            total={paginationProps.totalItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            columns={columns}
            dataSource={itemList}
            /*  onRow={handleTableRow} */
          />
        </StyledCard>
        <DeleteModal
          open={deleteModalProps.visible}
          loading={deleteModalProps.loading}
          itemName={deleteModalProps.name}
          onClose={() =>
            setDeleteModalProps((prevState) => ({
              ...prevState,
              visible: false,
            }))
          }
          onDelete={handleItemDelete}
          title="Delete Warehouse"
        />
       
        <Feedback
          open={feedbackProps.open}
          message={feedbackProps.message}
          type={feedbackProps.type}
          onClose={() =>
            setFeedbackProps((prevState) => ({ ...prevState, open: false }))
          }
        />
      </Route>
      <Route path={`${path}/add-item`}>
        <AddItem />
      </Route>
      <Route exact path={`${path}/:id`}>
        <ItemDetails />
      </Route>
      <Route path={`${path}/:id/edit`}>
          <EditItem/>
      </Route>
    </Switch>
  );
};
