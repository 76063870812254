import React, { useState } from 'react';
import styled from 'styled-components';
import { TableWithPagination } from '../../globalComponents/TableWithPagination';
import { ToolBar } from '../../globalComponents/ToolBar';
import { Menu } from 'antd';
import { StyledCard } from '../../globalComponents/styles';
import { useRouteMatch, Route, Switch, useHistory } from 'react-router';
import {
  StyledBoldText,
  StyledInputContainer,
} from '../../globalComponents/styles';
import { Box, Grid} from '@material-ui/core';
import { Input, Select, Col, Row , Form } from 'antd';
import {
  PrimaryButton,
  TransaparentButton,
} from '../../globalComponents/Buttons';
import { FiTrash2 } from 'react-icons/fi';
import { getInput } from '../../Inventory/Items/AddItem';

const { Option } = Select;

const data = [
  {
    item: [
      { name: 'Rice', sku: 20 },
      { name: 'Beans', sku: 15 },
    ],
    description: '',
    quantity: '',
    price: '',
    tax: [
        { name: 'Freet(10%)', sku: 20 },
        { name: 'Freet(20%)', sku: 15 },
      ],
  },
];


const salesOrder = [
    {
      name: 'customer',
      label: 'Customer',
      isRequired: false,
      type: 'select',
    },
    {
      name: 'salesOrderNumber',
      label: 'Sales Order number',
      isRequired: false,
      type: 'text',
    },
    {
      name: 'salesOrderDate*',
      label: 'Sales Order Date',
      isRequired: false,
      type: 'date',
    },
    {
        name: 'dueDate',
        label: 'Due date',
        isRequired: false,
        type: 'date',
      },
      {
        name: 'warehouse',
        label: 'Warehouse*',
        isRequired: false,
        type: 'select',
      },
      {
        name: 'salesPerson',
        label: 'Sales person',
        isRequired: false,
        type: 'select',
      },
      {
        name: 'notes',
        label: 'Notes',
        isRequired: false,
        type: 'textarea',
      },
  ];


  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  

export const AddSalesOrder = (props) => {
  const [itemsState, setItemsState] = useState(data);
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const handleDeleteRow = () => {
    const currentItemState = [...itemsState];
    currentItemState.splice(activeRowIndex, 1);
    setItemsState(currentItemState);
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      render: (data) => {
        const optionList = data.map((item, index) => {
          return (
            <Option key={index} value={item.sku}>
              {item.name}
            </Option>
          );
        });
        return (
          <StyledInputContainer>
            <Select
              /*  onChange={handleItemSelect} */
              placeholder="Select Item"
              style={{ width: '100%' }}
              bordered={false}>
              {optionList}
            </Select>
          </StyledInputContainer>
        );
      },
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (data) => {
          return (
            <StyledInputContainer>
              <Input bordered={false} />
            </StyledInputContainer>
          );
        },
      },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (data) => {
        return (
          <StyledInputContainer>
            <Input
                type='number'
              bordered={false}
              /*  onChange={handleAdjustedBy} */
              placeholder=""
            />
          </StyledInputContainer>
        );
      },
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (data) => {
          return (
            <StyledInputContainer>
              <Input
                  type='number'
                bordered={false}
                /*  onChange={handleAdjustedBy} */
                placeholder=""
              />
            </StyledInputContainer>
          );
        },
      },
      {
        title: 'Tax',
        dataIndex: 'tax',
        key: 'tax',
        render: (data) => {
          const optionList = data.map((item, index) => {
            return (
              <Option key={index} value={item.sku}>
                {item.name}
              </Option>
            );
          });
          return (
            <StyledInputContainer>
              <Select
                /*  onChange={handleItemSelect} */
                placeholder="Select Item"
                style={{ width: '100%' }}
                bordered={false}>
                {optionList}
              </Select>
            </StyledInputContainer>
          );
        },
      },

    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (data) => {
        return (
          itemsState.length > 1 && (
            <FiTrash2
              onClick={handleDeleteRow}
              style={{ fontSize: '1.4vw', marginLeft: '20px', color: 'red' }}
            />
          )
        );
      },
    },
  ];

  const handleTableRow = (data, index) => {
    return {
      onClick: (e) => {
        setActiveRowIndex(index);
      },
    };
  };

  const AddAdjustableRow = () => {
    const row =  {
        item: [
          { name: 'Rice', sku: 20 },
          { name: 'Beans', sku: 15 },
        ],
        description: '',
        quantity: '',
        price: '',
        tax: [
            { name: 'Freet(10%)', sku: 20 },
            { name: 'Freet(20%)', sku: 15 },
          ],
      };

    setItemsState((prevState) => [...prevState, row]);
  };

  const handleSelectChange = (selectName) => values => {
    
  }

  const salesOrderList = [...salesOrder].map((item) => {
    return <Col sm={12}>{getInput(item, handleSelectChange)}</Col>;
  });

  const onFinish = (values) => {

  }

  return (
    <>
      <ToolBar title="New Sales Order" />
      <StyledCard mt={2} py={3} px={4} display="flex">
        <Grid item xs={12}>
          <Box px={3}>
            <StyledBoldText variant="subtitle1">Warehouse</StyledBoldText>
          </Box>
          <Box px={3} mt={2}>
            <Form onFinish={onFinish} {...layout}>
              <Row gutter={16}>{salesOrderList}</Row>
            </Form>
          </Box>
          {/* <Box px={3} mt={2}>
            <PrimaryButton text="Add Warehouse" onClick={handleAddWarehouse} />
          </Box> */}
        </Grid>
      </StyledCard>
      <StyledCard mt={2}>
        <TableWithPagination
          dataSource={itemsState}
          onRow={handleTableRow}
          columns={columns}
          hidePagination
        />
        <Box p={4}>
          <PrimaryButton onClick={AddAdjustableRow} text="Add Row" />
        </Box>
      </StyledCard>
    </>
  );
};
