import hostname from "./domain";
import routes from "./routes";
import axios from "axios";
import getURLParams from "./Components/utils/getParams";
import { PanoramaSharp } from "@material-ui/icons";


// export const mainDomain = "https://api.ezoneerp.com/gateway/inventoryserv/api/v1";

export const mainDomain = "https://dev.ezoneapps.com/gateway/inventoryserv/api/v1";



class Services {
  static createItemGroup(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.createItemGroupRoute}`,
      data: payload.data,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static fetchItemGroupsByOrgId({ page, size, orgId }) {
    const params = getURLParams({ page, size });
    return axios({
      method: "GET",
      url: `${hostname}${routes.getItemGroupsByOrgId(orgId)}${params}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static fetchItemGroupNumber() {
    return axios({
      method: "GET",
      url: `${hostname}${routes.getItemGroupNumber}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static fetchAllItemGroups(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.getAllItemGroupRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async updateItemGroups(payload) {
    return axios({
      method: "PUT",
      url: `${hostname}${routes.updateItemGroupRoute(payload.id)}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }
  static async deleteItemGroup(payload) {
    return axios({
      method: "DELETE",
      url: `${hostname}${routes.deleteItemGroup(payload.id)}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static fetchSingleItemGroup(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.getSingleItemGroup(payload.id)}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async addWarehouse(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.addWarehouse}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async getAllWarehouse(payload) {
    const params = getURLParams(payload);
    return axios({
      method: "GET",
      url: `${hostname}${routes.getAllWarehouse}${params}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async fetchSingleWarehouse(payload) {
    console.log("...FETCH");
    return axios({
      method: "GET",
      url: `${hostname}${routes.getSingleWarehouse(payload.id)}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async updateWarehouse(payload) {
    return axios({
      method: "PUT",
      url: `${hostname}${routes.updateWarehouse(payload.id)}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async deleteWarehouse(payload) {
    return axios({
      method: "DELETE",
      url: `${hostname}${routes.deleteWarehouse(payload.id)}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async getAllItems(payload) {
    const params = getURLParams(payload);
    return axios({
      method: "GET",
      url: `${hostname}${routes.itemsRoute}${params}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async getSingleItem(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.itemsRoute}/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async createItem(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.itemsRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async deleteItem(payload) {
    return axios({
      method: "DELETE",
      url: `${hostname}${routes.itemsRoute}/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async updateItem(payload) {
    return axios({
      method: "PUT",
      url: `${hostname}${routes.itemsRouteId}/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async getUserProfile(payload) {
    return axios({
      method: "GET",
      url: `${mainDomain}${routes.userProfileRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async getUsersByOrg(payload) {
    return axios({
      method: "GET",
      url: `${mainDomain}${routes.userByOrgRoute(
        payload.orgId
      )}?start=0&limit=10`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async getCustomers(payload) {
    const params = getURLParams(payload);
    return axios({
      method: "GET",
      url: `${hostname}${routes.customersRoute}${params}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async createCustomers(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.customersRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async editCustomers(payload) {
    return axios({
      method: "PUT",
      url: `${hostname}${routes.customersRoute}/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async createVendors(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.vendorRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async getVendors(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.vendorRoute}/listAllVendors/${payload.page}/${payload.size}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async editVendors(payload) {
    return axios({
      method: "PUT",
      url: `${hostname}${routes.vendorRoute}/update/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async getAllInvoice(payload) {
    const params = getURLParams(payload);
    return axios({
      method: "GET",
      url: `${hostname}${routes.invoiceRoute}${params}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async getInvoice(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.invoiceRoute}/${payload.id}`,
      /* url: `${hostname}/${routes.getSingleInvoice(payload.id)}`, */
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async createInvoice(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.invoiceRoute}/customer_id/${payload.id}/add_invoice`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async getPaymentInfo(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.paymentRoute}/${payload.id}/init_payment`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async makePayment(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.paymentRoute}/${payload.id}/pay`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  static async logout() {
    return axios({
      method: "POST",
      url: `${mainDomain}${routes.logoutRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  //create Receipt
  static async createReceipt(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.createReceiptRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async getAllReceipt(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.getAllReceiptsRoute}/${payload.page}/${payload.size}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async getSingleReceipt(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.getSingleReceiptRoute(payload.id)}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  static async issueItem(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.issueItemRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  // get all TRANSFAR ORDER
  static async getAllTransfarOrder(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.getAllTransfarOrderRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
    });
  }

  //create transfar order
  static async createTransfarOrder(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.addTransfarOrderRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  //add Adjustments
  static async createAdjustment(payload) {
    return axios({
      method: "POST",
      url: `${hostname}${routes.addAdjustmentsRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken}`,
      },
      data: payload.data,
    });
  }

  //Adjustments List
  static async getAllAdjustments(payload) {
    return axios({
      method: "GET",
      url: `${hostname}${routes.adjustmentsListRoute}`,
      headers: {
        Authorization: `Bearer ${localStorage.invToken},`,
      },
    });
  }
}


export default Services;

// /api/v1/add_transfer_orders

