import styled from 'styled-components';
import { Box, Button, Typography } from '@material-ui/core';
import { Table, Form, Modal } from 'antd';
import { getCenter } from '../utils/getCenter';
import { desktop } from '../globalAssets/fontSize';


export const StyledTopBar = styled(Box)`
  background-color: ${(props) => props.theme.foreground};
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
`;

export const StyledLogo = styled.img`
  width: 10%;
  height: auto;
  cursor: pointer;
`;

export const StyledTable = styled(Table)`
  color: ${(props) => props.theme.white};
  & .ant-table-thead > tr > th:nth-child(odd) {
    background-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.white};
  }

  & .ant-table-thead > tr > th:nth-child(even) {
    background-color: ${(props) => props.theme.primaryColor3};
    color: ${(props) => props.theme.white};
  }

  & .ant-table-row {
    background-color: ${(props) => props.theme.foreground};
    color: ${(props) => props.theme.boldText};
    cursor: pointer;

    &:nth-child(even) {
      background-color: ${(props) => props.theme.faintBlue};
    }
  }

  & .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${(props) => props.theme.highlight};
    color: ${(props) => props.theme.boldText};
  }
`;

export const StyledInputContainer = styled(Box)`
  ${getCenter()}
  border: 1px solid ${(props) => props.theme.border};
  width: 100%;
  padding: 4px;
  border-radius: 5px;

  & p {
    color: ${(props) => props.theme.lightText};
  }
`;

export const StyledPrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.white};
  text-transform: capitalize;

  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

export const StyledCancelButton = styled(Button)`
  background-color: ${(props) => props.theme.red};
  color: ${(props) => props.theme.white};
  text-transform: capitalize;

  &:hover {
    background-color: ${(props) => props.theme.red};
  }
`;

export const StyledTransparentButton = styled(Button)`
  color: ${(props) => props.theme.lightText};
  border: 1px solid ${(props) => props.theme.border};
  text-transform: capitalize;
`;

export const StyledCard = styled(Box)`
  background-color: ${(props) => props.theme.foreground};
  width: 100%;
`;

export const StyleLabel = styled.p`
  font-size: ${desktop.normal};
  font-weight: 500;
  color: ${(props) => props.theme.lightText};
`;

export const StyledNormalText = styled(Typography)`
  color: ${(props) => props.theme.lightText};
`;

export const StyledBoldText = styled(Typography)`
  color: ${(props) => props.theme.boldText};
`;

export const StyledModal = styled(Modal)`
  background-color: transparent;
  border-radius: 20px;
  //min-width: 50vw;
  color: ${(props) => props.theme.boldText};
  text-align: center;
  & .ant-modal-content {
    border-radius: 8px;
    margin: 0 auto;
    background-color: ${(props) => props.theme.foreground};
  }
`;
