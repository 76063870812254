import { createSlice } from "@reduxjs/toolkit";
import Services from "../../../services";

const vendorSlice = createSlice({
  name: "vendorSlice",
  initialState: {
    vendorList: [],
    paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
  },

  reducers: {
    addVendorList: (state, { payload }) => {
      state.vendorList = payload;
    },

    addPaginationProps: (state, { payload }) => {
      state.paginationProps = payload;
    },
  },
});

export const { addVendorList, addPaginationProps } =
vendorSlice.actions;

export const getAllVendor = (payload) => (dispatcher) => {
  return Services.getVendors(payload)
    .then((response) => {
      const {currentPage: page, totalPages, totalVendors: totalItems, vendors} = response.data;
      dispatcher(addPaginationProps({page, totalPages, totalItems}));
      dispatcher(addVendorList(vendors));
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.status);
        return Promise.reject(error.response.data);
      }
    });
};

export const createVendor = (payload) => (dispatcher) => {
  return Services.createVendors(payload)
    .then((response) => {
        return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};

export const selectVendor = (state) => state.vendorReducer;

export default vendorSlice.reducer;
