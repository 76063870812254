import { createSlice } from "@reduxjs/toolkit";
import Services from "../../../services";

const adjustmentsSlice = createSlice({
  name: "adjustmentsSlice",
  initialState: {
    adjustmentsList: [],
    singleAdjustments: {},
    paginationProps: { page: 0, totalPages: 0, totalItems: 0 },
  },

        reducers:{
            allAdjustments: (state, {payload}) =>{
                state.adjustmentsList = payload
            },
            singleAdjustments: (state, {payload}) => {
                    state.singleAdjustments = payload;
                },
            addPaginationProps: (state, { payload }) => {
                    state.paginationProps = payload;
                },
        }
});
export const { allAdjustments, singleAdjustments, addPaginationProps } = adjustmentsSlice.actions;

export const fetchAllAdjustments = (payload) => (dispatcher) => {
  return Services.getAllAdjustments(payload).then(
    (response) => {
      console.log(response, "allAdjustment");
      const {
        currentPage: page,
        totalPages,
        totalItems,
        adjustments,
      } = response.data;
      dispatcher(allAdjustments(adjustments));
      dispatcher(addPaginationProps({ page, totalPages, totalItems }));
      return Promise.resolve(adjustments);
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    }
  );
};

export const createAddjustments = (payload) => (dispatcher) => {
  return Services.createAdjustment(payload)
    .then((response) => {
      console.log(response, "create addjustment");
      return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
    });
};





  export const selectAdjustments = (state) => state.adjustmentsReducer;

  export default adjustmentsSlice.reducer;

